import basicSchemas from '@/pages/editor/components/basicComponents/schemas'
import businessSchemas from '@/pages/editor/components/businessComponents/schemas'
import chartComponentInstaller from '@/pages/editor/components/chartComponents/chartComponentInstaller'

const componentsContext = require.context(
  '@/pages/editor/components',
  true,
  /(basicComponents|businessComponents|chartComponents|commonWidgets).*\.vue$/
)

const install = function(Vue, opts = {}) {
  /* istanbul ignore if */
  if (install.installed) return
  componentsContext.keys().forEach(path => {
    const component = componentsContext(path).default
    if (
      Vue.options.components.XComponent &&
      component.name === 'XComponent'
    ) {
      return
    }
    Vue.component(component.name, component)
    chartComponentInstaller(Vue)
  })
}

export default { install, basicSchemas, businessSchemas }
