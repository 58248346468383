<template>
  <el-col class="x-col" :span="span" x-slot-component>
    <div x-slot="default" :style="layoutStyle">
      <x-placeholder
        v-if="placeholder &&
              !placeholder.targetUUID &&
              placeholder.slotParentUUID === componentData.uuid &&
              placeholder.slotName === 'default'" />
      <template v-for="item in componentData._children">
        <x-placeholder
          :key="item.uuid"
          v-if="placeholder &&
                placeholder.targetUUID === item.uuid &&
                placeholder.position.includes('top')" />
        <x-component
          x-slot-component="false"
          :key="item.uuid"
          :data="item"
          :uuid="item.uuid"
          :project="project"
        >
        </x-component>
        <x-placeholder
          :key="item.uuid"
          v-if="placeholder &&
                placeholder.targetUUID === item.uuid &&
                placeholder.position.includes('bottom')" />
      </template>
    </div>
  </el-col>
</template>

<script>
import basicComponentMixin from '../mixins/basic-component.mixin'

export default {
  name: 'XCol',

  mixins: [basicComponentMixin],

  props: {
    span: {
      type: Number,
      required: true
    },
    justifyContent: String,
    alignItems: String
  },

  computed: {
    layoutStyle() {
      const { justifyContent, alignItems, minHeight } = this
      const style = {
        justifyContent,
        alignItems
      }
      if (this._IS_EDIT_) style.minHeight = minHeight + 'px'
      return style
    },

    minHeight() {
      return this.$parent.$parent.$parent.minHeight
    }
  }
}
</script>

<style lang="scss">
.x-col {
  &.el-col {
    > [x-slot] {
      width: 100%;
    }
  }
}

.editor {
  .el-col {
    > [x-slot] {
      border: 1px dashed #dadada;
    }
  }
}
</style>
