<template>
  <div class="subsystem-container">
    <div class="subsystem">
      <div
        class="item"
        :class="{ active: item.active }"
        v-for="item in subsystemList"
        :key="item.subsystem"
        @click="selectSubsystem(item)"
      >{{ item.subsystem }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Subsystem',

  computed: {
    ...mapState(['permissionRoutes', 'renderRoute'])
  },

  data() {
    return {
      subsystemList: []
    }
  },

  created() {
    this.getSubsystemList()
    this.initSubsystemList()
  },

  methods: {
    initSubsystemList() {
      const route = this.renderRoute
      if (route) {
        const subsystem = route.parent.parent.subsystem
        const matched = this.subsystemList.filter(
          v => v.subsystem === subsystem
        )
        if (matched.length) {
          this.selectSubsystem(matched[0])
        }
        return
      }
      // 默认激活第一个子系统
      this.selectSubsystem(this.subsystemList[0])
    },
    getSubsystemList() {
      this.permissionRoutes.forEach(route => {
        const matched = this.subsystemList.filter(
          v => v.subsystem === route.subsystem
        )

        if (!matched.length) {
          if (route.subsystem) {
            this.subsystemList.push({
              active: false,
              subsystem: route.subsystem,
              routes: [route]
            })
          }
        } else {
          const matchList = matched[0]
          matchList.routes.push(route)
        }
      })
    },
    selectSubsystem(item) {
      this.subsystemList.forEach(list => {
        list.active = false
        list.routes.forEach(route => (route.hidden = true))
      })
      item.active = true
      item.routes.forEach(route => (route.hidden = false))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/var.scss';

.subsystem-container {
  line-height: 50px;
}

.subsystem {
  display: flex;
  align-items: center;

  .item {
    cursor: pointer;
    padding: 0 12px;
    transition: background 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &.active {
      color: $primary;
    }
  }
}
</style>
