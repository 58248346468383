<template>
  <component
    class="x-component"
    :class="[
      {'is-edit': _IS_EDIT_, 'is-activited': isCurrentComponent, 'is-opacity': !isVisible},
      !data.notFlex && `is-flex ${(data.props || {}).align || 'left'}`
    ]"
    :is="toPascalName"
    :component-data="data"
    :x-uuid="uuid"
    :uuid="uuid"
    :in-table-slot="inTableSlot"
    :row-data="rowData"
    :chart-demo-data="chartDemoData"
    :project="project"
    :tableRef="tableRef"
    @click.native.stop="edit(uuid, $event)"
    @mousedown.native="handleMousedown"
    v-bind="data.props">
  </component>
</template>

<script>
import { isRegisteredComponent } from '@/pages/editor/utils'
import { toPascal } from '../utils/component-name'
import { mapState, mapGetters } from 'vuex'
import chartDemoData from '@editor/components/chartComponents/schemas/chartDemoData'
import dragMixin from '@editor/modules/componentLibrary/mixins/drag.mixin'

export default {
  name: 'XComponent',

  mixins: [dragMixin],

  props: {
    data: {
      required: true,
      type: Object
    },
    uuid: {
      type: String,
      required: true
    },
    inTableSlot: Boolean,
    rowData: {},
    project: Object,
    tableRef: Object
  },

  data() {
    return {
      hasDelButton: false
    }
  },

  computed: {
    ...mapState(['currentFile', 'currentComponents']),
    ...mapGetters(['componentMapping']),

    toPascalName() {
      if (isRegisteredComponent(this.data.componentName)) {
        return toPascal(this.data.componentName)
      }
      return 'fallback'
    },

    isCurrentComponent() {
      const uuids = this.currentComponents.map(c => c.uuid)
      return uuids.indexOf(this.uuid) !== -1
    },

    isVisible() {
      const visible = this.data.props.visible || {
        isStatic: true,
        visible: true
      }
      const { isStatic } = visible
      return isStatic ? visible.visible : true
    },

    chartDemoData() {
      const name = this.data.componentName.slice(6).toLowerCase()
      return chartDemoData[name] || {}
    }
  },

  methods: {
    handleMousedown(event) {
      if (this._IS_EDIT_) {
        event.stopPropagation()
      }
      const { data } = this

      if (
        !event.target.classList.contains('no-drag') &&
        data.componentName !== 'XCol'
      ) {
        this.onDragstart(event, data)
      }
    },

    edit(uuid, e) {
      if (!this._IS_EDIT_) return
      if (e.shiftKey) {
        const currentComponents = this.currentComponents
        const thisComponent = this.componentMapping[uuid]
        const idx = currentComponents.indexOf(thisComponent)
        let newComponents = [...currentComponents]
        if (idx === -1) {
          newComponents.push(thisComponent)
        } else {
          newComponents.splice(idx, 1)
        }
        this.$store.dispatch('setMultiComponents', newComponents)
      } else {
        this.$store.dispatch('setCurrentComponent', this.componentMapping[uuid])
      }
    }
  }
}
</script>

<style lang="scss">
.is-dragging {
  .is-edit {
    &::after {
      pointer-events: none;
    }
  }
}

@keyframes componentLoading {
  from {
    background: #aaa;
  }
  50% {
    background: #888;
  }
  to {
    background: #aaa;
  }
}


.x-component {
  margin: 5px 0;
  z-index: 1;

  &.component-loading {
    padding: 50px 0;
    animation: componentLoading 2s linear infinite both;
  }

  &.is-flex {
    display: flex;

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }

  > .x-component {
    z-index: 3;
  }

  + .x-component:not(.x-col) {
    margin-top: 10px;
  }

  &.is-edit {
    position: relative;

    &.x-chart::after {
      content: '图表绘制的是预览数据';
      color: rgba(52, 140, 232, 0.3);
      padding-top: 20px;
      padding-left: 20px;
    }

    &::after,
    .fake-mask {
      content: '';
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: -5px;
      background: transparent;
      cursor: move;
    }

    &:hover {
      &::after,
      .fake-mask {
        background: rgba(189, 189, 189, 0.24);
      }
    }

    .delete {
      display: none;
      position: absolute;
      z-index: 25;
      right: -10px;
      top: -5px;
      padding: 5px 0;
      width: 20px;
      text-align: center;
      color: #fff;
      background: #a9a9a9;

      &:hover {
        background: #f53b3b;
      }

      > .iconfont {
        font-size: 13px;
        font-weight: normal;
      }
    }

    &.is-activited {
      > .delete {
        display: block;
      }

      &::after,
      > .fake-mask {
        z-index: 2;
        background-color: rgba(82, 154, 239, 0.14);
        background-image: repeating-linear-gradient(
          -45deg,
          rgba(52, 140, 232, 0.15),
          rgba(52, 140, 232, 0.15) 1px,
          transparent 0,
          transparent 6px
        );
        background-size: 100% 100%;
        box-shadow: 0 0 0 1px rgba(52, 140, 232, 0.32) inset;
      }
    }
  }

  &.is-opacity {
    opacity: 0.3;
  }
}
</style>
