<template>
  <div class="function-config">
    <!-- 配置 -->
    <div class="config">
      <el-form size="mini" label-position="top">
        <config-box title="逻辑方法参数">
          <el-form-item class="config_item" label="全局服务对象">
            <el-checkbox-group :value="enableParams" @input="updateParams">
              <div v-for="item in globalParams" :key="item" class="param">
                <el-checkbox :label="item">{{item}}</el-checkbox>
                <el-button class="param-help" type="text" @click="showDocument(item)">[参数说明]</el-button>
              </div>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            class="config_item"
            label="事件回调参数"
            v-if="callbackParams && callbackParams.length"
          >
            <el-checkbox-group :value="enableParams" @input="updateParams">
              <div v-for="item in callbackParams" :key="item.name" class="param">
                <el-checkbox :label="item.name">{{item.name}}</el-checkbox>
                <div class="param-tip">{{item.description}}</div>
              </div>
            </el-checkbox-group>
          </el-form-item>
        </config-box>
      </el-form>
    </div>

    <!-- 文档 -->
    <el-dialog title="服务文档" top="3vh" append-to-body :visible.sync="dialogs.document" width="800px">
      <component :is="currentDocComponent" class="markdown-body" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogs.document = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as mutationTypes from '@/store/mutation-types'
import ConfigBox from '@/pages/editor/components/config-box'
// import StoreDoc from './markdowns/$store.md'
// import HttpDoc from './markdowns/$http.md'
// import HttpDoc from './markdowns/$env.md'
// import MessageDoc from './markdowns/$message.md'
// import MethodsDoc from './markdowns/$methods.md'
// import ComponentsDoc from './markdowns/$components.md'
// import MsgboxDoc from './markdowns/$msgbox.md'
// import LoadingDoc from './markdowns/$loading.md'
// import ProjectConstantsDoc from './markdowns/$projectConstants.md'
// import ConstantsDoc from './markdowns/$constants.md'
import services from '@/core/services'

export default {
  name: 'FunctionConfig',

  components: {
    ConfigBox
    // StoreDoc,
    // HttpDoc,
    // LoadingDoc,
    // MethodsDoc,
    // ComponentsDoc,
    // MsgboxDoc,
    // MessageDoc,
    // EnvDoc,
    // ProjectConstantsDoc,
    // ConstantsDoc
  },

  props: {},

  data() {
    return {
      globalParams: Object.keys(services),
      dialogs: {
        document: false
      },
      currentDocComponent: null
    }
  },

  computed: {
    ...mapState(['currentFile', 'projectName']),
    enableParams() {
      return this.currentFile.data.params
    },
    callbackParams() {
      return this.currentFile.data.argumentsMapping
    }
  },

  methods: {
    showDocument(name) {
      this.dialogs.document = true
      name = name.slice(1) + 'Doc'
      name = name.charAt(0).toUpperCase() + name.slice(1)
      this.currentDocComponent = this.$options.components[name]
    },
    updateParams(val) {
      this.$store.commit(mutationTypes.UPDATE_FUNCTION_PARAMS, val)
    }
  }
}
</script>

<style lang="scss">
:root {
  --gray: #e4e4e4;
}

.function-config {
  position: relative;
  padding: 10px !important;
  overflow-y: auto;

  .el-form {
    &-item {
      margin-bottom: 8px;
    }
  }
  .param {
    position: relative;
    line-height: 30px;

    &-help {
      position: absolute;
      right: 0;
      top: 50%;
      padding: 0;
      transform: translateY(-50%);
    }

    &-tip {
      color: #666;
      font-size: 12px;
      line-height: 1;
      padding-bottom: 10px;
      transform-origin: 0 0;
      transform: scale(0.9);
    }
  }
}
</style>
