const loadedSource = {}

export default dependencies => {
  let links = []
  let scripts = []
  if (Array.isArray(dependencies)) {
    dependencies.forEach(url => {
      if (/\.js/.test(url)) {
        scripts.push(url)
      } else {
        links.push(url)
      }
    })
  } else {
    if ('scripts' in dependencies) {
      scripts = scripts.concat(dependencies.scripts)
    } else if ('links' in dependencies) {
      links = links.concat(dependencies.links)
    }
  }

  let _resolve = null
  const p = new Promise(resolve => {
    _resolve = resolve
  })
  const internalLoader = () => {
    if (scripts.length) {
      const current = scripts.shift()
      loadScriptPromise(current).then(internalLoader)
    } else {
      _resolve()
    }
  }

  Promise.all(links.map(loadLinkPromises)).then(internalLoader)

  return p
}

const loadScriptPromise = url => new Promise((resolve, reject) => {
  if (url in loadedSource) {
    return resolve()
  }
  const node = document.createElement('script')
  node.src = url
  node.async = true
  node.defer = true
  node.onload = () => {
    loadedSource[url] = true
    resolve()
  }
  node.onerror = reject
  document.body.appendChild(node)
})

const loadLinkPromises = url => new Promise((resolve, reject) => {
  if (url in loadedSource) {
    return resolve()
  }
  const node = document.createElement('link')
  node.href = url
  node.rel = 'stylesheet'
  node.type = 'text/css'
  node.onload = () => {
    loadedSource[url] = true
    resolve()
  }
  node.onerror = reject
  document.head.appendChild(node)
})
