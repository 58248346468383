import { UI_SCHEMAS, EVENT_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { size, max, min, label } = UI_SCHEMAS
const { bindKey, disabled } = DATA_SCHEMAS
const { handleChangeKey, handleBlurKey, handleFocusKey } = EVENT_SCHEMAS

export default {
  componentName: 'XNumber',

  label: 'Number',

  props: {
    rightControls: true,
    size: 'small',
    label: '条目标签'
  },

  notFlex: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        size,
        label,
        max,
        min,
        step: {
          name: '步长',
          type: 'number',
          widget: 'number'
        },
        rightControls: {
          name: '按钮置右',
          widget: 'switch'
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: { bindKey, disabled }
    },

    events: {
      label: '事件绑定',
      content: { handleChangeKey, handleBlurKey, handleFocusKey }
    }
  }
}
