export default (str, state, rowData, inTable, isBoolean) => {
  const data = {...state}
  if (inTable) {
    data.row = rowData
  }
  let result
  try {
    const fnBody = `with(this){ return (${str}) }`
    /* eslint-disable no-new-func */
    const fn = new Function(fnBody)
    result = fn.call(data)
  } catch (err) {
    result = isBoolean ? false : err.toString().replace(/Error: | variable | parse error/g, '')
  }
  return result
}
