import { get } from 'lodash-es'

export default {
  props: {
    bindKey: String,
    handleChangeKey: String,
    project: Object
  },

  data() {
    return {
      error: false
    }
  },

  computed: {
    bindValue() {
      return this._IS_EDIT_
        ? this.defaultModel || ''
        : get(this.$store.state, this.bindKey, this.defaultModel || '')
    }
  },

  methods: {
    updateValue(newVal) {
      this.$store.commit('SET_STATE', {
        project: this.project,
        path: this.bindKey,
        value: newVal
      })
    },

    getValueByPath(path, defaultValue) {
      return get(
        this.project,
        path,
        defaultValue === undefined ? '' : defaultValue
      )
    },

    onChange(val) {
      if (this.handleChangeKey) {
        this.project.globalAPI[this.handleChangeKey](val)
      }

      if (this.validate) {
        this.error = new RegExp(this.validate).test(val)
      }
    }
  }
}
