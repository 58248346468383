<template>
  <component
    v-if="isVisible"
    class="x-component"
    :class="[
      !data.notFlex && `is-flex ${(data.props || {}).align || 'left'}`
    ]"
    :is="toPascalName"
    :component-data="data"
    :uuid="uuid"
    :row-data="rowData"
    :in-table-slot="inTableSlot"
    v-bind="data.props">
  </component>
</template>

<script>
import { toPascal } from '@/pages/editor/utils/component-name'
import { isRegisteredComponent } from '@/pages/editor/utils'
import exprEval from '@editor/utils/expression-eval'

export default {
  name: 'XComponent',

  props: {
    data: {
      required: true,
      type: Object
    },
    uuid: {
      type: String,
      required: true
    },
    inTableSlot: Boolean,
    rowData: {}
  },

  computed: {
    toPascalName() {
      if (isRegisteredComponent(this.data.componentName)) {
        return toPascal(this.data.componentName)
      }
      return 'div'
    },

    isVisible() {
      const visible = this.data.props.visible || {
        isStatic: true,
        visible: true
      }
      const { inTableSlot, rowData } = this
      const { isStatic } = visible
      return isStatic
        ? visible.visible
        : exprEval(
          visible.visibleKey,
          this.$store.state,
          (rowData || {}).data,
          inTableSlot,
          true
        )
    }
  },

  mounted() {
    if (!isRegisteredComponent(this.data.componentName)) {
      this.$el.style.display = 'none'
    }
  }
}
</script>

<style lang="scss">
@keyframes componentLoading {
  from {
    background: #aaa;
  }
  50% {
    background: #888;
  }
  to {
    background: #aaa;
  }
}

.x-component {
  margin: 5px 0;
  z-index: 1;

  &.component-loading {
    padding: 50px 0;
    animation: componentLoading 2s linear infinite both;
  }

  &.is-flex {
    display: flex;

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }

  > .x-component {
    z-index: 3;
  }

  + .x-component:not(.x-col) {
    margin-top: 10px;
  }
}
</style>
