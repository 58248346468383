<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    componentTree: Array
  },
  computed: {
    ...mapState(['projectOrigin', 'placeholder', 'draggingComponent']),
    ...mapGetters(['currentPage', 'currentTreeName']),

    pageClass() {
      return `x-page ${this.draggingComponent ? 'is-dragging' : ''}`
    },

    pageStyle() {
      const { currentPage } = this
      if (!currentPage) return
      const { padding, background } = currentPage.configs || {
        padding: 10,
        background: null,
        watermark: false
      }
      return {
        padding: (padding < 0 ? 0 : padding) + 'px',
        // 当页面摆满组件，想往页面最后再拖拽新的组件时，很容易放到删除区域
        // 加上 padding 支撑起高度
        paddingBottom: '200px',
        backgroundColor: background || 'transparent'
      }
    }
  },

  methods: {
    clickOutside() {
      this.$store.dispatch('setCurrentComponent', null)
    }
  },

  render() {
    const { componentTree, placeholder, currentTreeName } = this
    const showPlaceholder = currentTreeName === 'page'

    const components = this._l(componentTree, (item, i) => {
      const nodes = [
        <x-component
          uuid={item.uuid}
          key={item.uuid}
          data={item}
          project={this.projectOrigin}
          x-slot-component
          style={`z-index: ${componentTree.length - i}`}
        />
      ]
      if (
        placeholder &&
        showPlaceholder &&
        placeholder.targetUUID === item.uuid
      ) {
        if (placeholder.position.includes('top')) {
          nodes.unshift(<x-placeholder />)
        } else {
          nodes.push(<x-placeholder />)
        }
      }
      return nodes
    })

    return (
      <div
        id='ui-page'
        class={this.pageClass}
        style={this.pageStyle}
        x-slot="root"
        x-tree="page"
        onClick={this.clickOutside}
      >
        {placeholder &&
        showPlaceholder &&
        placeholder.slotName === 'root' &&
        !placeholder.targetUUID ? (
          <x-placeholder />
          ) : null
        }
        {components}
      </div>
    )
  }
}
</script>

<style lang="scss">
.x-page {
  position: relative;
  padding: 24px;
  height: 100%;
  border-radius: 2px;
  overflow-x: auto;
  box-sizing: border-box;
}
</style>
