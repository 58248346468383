<template>
  <div class="x-upload x-form-item">
    <div class="x-form-label" v-if="label">{{ label }}</div>
    <el-upload
      class="inner-upload"
      ref="elCompo"
      :list-type="listType"
      :action="_action"
      :name="name"
      :multiple="multiple"
      :autoUpload="autoUpload"
      :disabled="disabled"
      :drag="drag"
      :data="getValueByPath(this.dataKey, {})"
      :file-list="getValueByPath(this.fileListKey, [])"
      :on-error="onError"
      :on-success="onSuccess"
      :on-change="onChange"
      :on-preview="onPreview"
      :on-remove="onRemove"
      :with-credentials="withCredentials">
      <i class="el-icon-upload" v-if="drag"></i>
      <div class="el-upload__text" v-if="drag">将文件拖到此处，或<em>点击上传</em></div>
      <el-button size="small" type="primary" v-if="!drag">点击上传</el-button>
      <div slot="tip" class="el-upload__tip" v-if="tip">{{ tip }}</div>
    </el-upload>
    <div class="x-err-msg" v-if="error">校验有误</div>
  </div>
</template>

<style lang="scss">
  .x-upload {
    align-items: flex-start;

    .x-form-label {
      line-height: 32px;
    }

    .inner-upload {
      flex: 1;
    }
  }
</style>

<script>
import exprEval from '@editor/utils/expression-eval'
import ModelMixin from '../mixins/model-mixin'
import IdMixin from '../mixins/id-mixin'
import DisabledMixin from '../mixins/disabled-mixin'

export default {
  name: 'XUpload',

  mixins: [ModelMixin, IdMixin, DisabledMixin],

  data() {
    return {}
  },

  computed: {
    defaultModel() {
      return this.multiple ? [] : ''
    },

    _action() {
      const { action } = this
      const { isStatic } = action || {}
      return action
        ? isStatic
          ? action.action
          : exprEval(
            action.actionKey,
            this.$store.state,
            (this.rowData || {}).data,
            this.inTableSlot,
            false
          )
        : ''
    }
  },

  methods: {
    onSuccess(response, file, fleList) {
      this.handleSuccessKey &&
      this.project.globalAPI[this.handleSuccessKey](response, file, fleList)
    },

    onError(error, file, fleList) {
      this.handleErrorKey &&
      this.project.globalAPI[this.handleErrorKey](error, file, fleList)
    },

    onChange(file, fleList) {
      this.handleChangeKey &&
      this.project.globalAPI[this.handleChangeKey](file, fleList)
    },

    onPreview(file) {
      this.handlePreviewKey &&
      this.project.globalAPI[this.handlePreviewKey](file)
    },

    onRemove(file, fleList) {
      this.handleRemoveKey &&
      this.project.globalAPI[this.handleRemoveKey](file, fleList)
    }
  },

  props: {
    listType: String,
    action: Object,
    multiple: Boolean,
    autoUpload: Boolean,
    disabled: Boolean,
    drag: Boolean,
    withCredentials: Boolean,
    label: String,
    tip: String,
    dataKey: String,
    name: String,
    fileListKey: String,

    handleErrorKey: String,
    handleSuccessKey: String,
    handlePreviewKey: String,
    handleRemoveKey: String,
    project: Object
  }
}
</script>
