<template>
  <div class="component-tree">
    <el-collapse class="component-tree_collapse">
      <el-collapse-item>
        <template slot="title">
          <icon name="tree" style="margin-right: 5px" />组件树
        </template>
        <div class="component-tree_wrap">
          <el-tree
            node-key="hash"
            ref="tree"
            :indent="12"
            :data="treeData"
            :props="{label: 'componentName', children: 'children'}"
            :render-content="renderContent"
            @current-change="handleCurrentChange">
          </el-tree>
        </div>
      </el-collapse-item>
    </el-collapse>
    <x-contextmenu ref="contextMenu">
      <li @click="rename">重命名</li>
      <li @click="removeNode">删除</li>
    </x-contextmenu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isOnlyOneSlot, isRegisteredComponent } from '@editor/utils'

const slotsJoint = childrenObject => {
  let temp = []
  Object.keys(childrenObject).forEach(slotName => {
    temp = temp.concat(childrenObject[slotName])
  })
  return temp
}

const formatTree = rootData => {
  return rootData.map(component => {
    let nodeData = {}

    const { uuid, componentName, _children } = component
    Object.assign(nodeData, {
      componentName,
      uuid
    })

    if (_children) {
      const onlyOneSlot = isOnlyOneSlot(_children)
      const children = onlyOneSlot ? _children : slotsJoint(_children)
      nodeData.children = formatTree(children)
    }

    return nodeData
  })
}

export default {
  computed: {
    ...mapState(['currentFile']),

    treeData() {
      const { componentTree } = this.currentFile.data
      return formatTree(componentTree)
    }
  },

  data() {
    return {
      contextMenu: {}
    }
  },

  methods: {
    removeNode() {
      this.$store.dispatch('removeComponent', this.contextMenu.data.uuid)
    },

    rename() {},

    handleCurrentChange(component, node) {
      if (node.expanded) {
        this.$nextTick(_ => {
          node.expanded = true
        })
      }
      this.$store.dispatch('setCurrentComponent', component)
      document
        .getElementById('ui-page')
        .querySelector(`[x-uuid="${component.uuid}"]`)
        .scrollIntoView({ block: 'end', behavior: 'smooth' })
    },

    renderContent(h, { node, data, store }) {
      const showContextmenu = event => {
        const nodeElm = event.target
        event.preventDefault()
        this.$refs.contextMenu.open()
        this.contextMenu = { node, data, store, nodeElm }
      }

      const isValid = isRegisteredComponent(data.componentName)

      return (
        <div style="flex: 1" on-contextmenu={showContextmenu}>
          <span>
            {data.componentName}
            {isValid ? '' : <span style="color: #888;">(无效)</span>}
          </span>
        </div>
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.component-tree {
  // background: rgb(37, 37, 38);
  background: #ffffff;
  flex-shrink: 0;

  &_wrap {
    padding: 10px;
  }
}

.el-tree {
  background-color: transparent;
  color: #ccc;
}
</style>
