<script>
import ModelMixin from '../mixins/model-mixin'
import IdMixin from '../mixins/id-mixin'
import DisabledMixin from '../mixins/disabled-mixin'
import ReadonlyMixin from '../mixins/readonly-mixin'

export default {
  name: 'XNumber',

  mixins: [ModelMixin, IdMixin, DisabledMixin, ReadonlyMixin],

  methods: {
    onBlur(event) {
      this.handleBlurKey &&
      this.project.globalAPI[this.handleBlurKey](event)
    },

    onFocus(event) {
      this.handleFocusKey &&
      this.project.globalAPI[this.handleFocusKey](event)
    }
  },

  props: {
    size: String,
    placeholder: String,
    step: Number,
    max: Number,
    min: Number,
    rightControls: Boolean,
    label: String,

    handleBlurKey: String,
    handleFocusKey: String,
    object: Object
  },

  render() {
    return (
      <div class="x-number x-form-item">
        {this.label ? <div class="x-form-label">{this.label}</div> : ''}
        <el-input-number
          ref="elCompo"
          value={this.bindValue}
          onInput={this.updateValue}
          onChange={this.onChange}
          onBlur={this.onBlur}
          size={this.size}
          max={this.max}
          min={this.min}
          controlsPosition={this.rightControls ? 'right' : ''}
          onFocus={this.onFocus}
          disabled={this.isDisabled}
          readonly={this.isReadonly}
        />
        {this.error ? <div class="x-err-msg">校验有误</div> : ''}
      </div>
    )
  }
}
</script>

<style>
.x-number {
  > .el-input-number {
    width: 100%;
  }
}
</style>

