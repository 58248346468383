import allSchemas from './all'

// 新增组件需要在这里进行排序，否则组件不会显示
const componentListWithOrder = [
  'XBaseTable',
  'XBaseEditTable'
]

export default componentListWithOrder.map(
  c => allSchemas[c]
)
