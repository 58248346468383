<template>
  <div class="widget-column control">
    <slot v-if="inited">
      <div class="column" v-for="(column, index) in columns" :key="index">
        <div class="column_control-box">
          <div
            class="column_control"
            :class="{ disabled: index === 0 }"
            @click="e => moveCol(index, -1)"
          >
            <i class="el-icon-caret-top"></i>
          </div>
          <div
            class="column_control"
            :class="{ disabled: index === columns.length - 1 }"
            @click="e => moveCol(index, 1)"
          >
            <i class="el-icon-caret-bottom"></i>
          </div>
        </div>
        <div
          class="column_title"
          :class="[onlyClass]"
          :content="index"
          @click="removeColumn(index)"
        ></div>
        <div class="column_row">
          <div class="column_label" content="标题"></div>
          <div class="column_item">
            <el-input
              placeholder="请输入"
              v-model="column.label"
              @input="changeSetting"
            ></el-input>
          </div>
        </div>
        <div class="column_row">
          <div class="column_label" content="页面 ID"></div>
          <div class="column_item">
            <el-input
              placeholder="请输入"
              v-model="column.pageId"
              @input="changeSetting"
            ></el-input>
          </div>
        </div>
      </div>
    </slot>

    <div class="column_action">
      <el-button type="primary" @click="addColumn()" style="width: 100%;">新增选项卡</el-button>
    </div>
  </div>
</template>

<script>
import { clone, cloneDeep } from 'lodash-es'
import * as mutationTypes from '@/store/mutation-types'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'WidgetTabPane',

  props: {
    value: Array
  },

  created() {
    this.columns = clone(this.value)
    this.inited = true
  },

  watch: {
    value(val) {
      this.columns = clone(val)
    }
  },

  data() {
    return {
      inited: false,
      columns: []
    }
  },

  computed: {
    ...mapGetters(['onlyActiveComponent']),

    onlyClass() {
      return { only: this.columns.length === 1 }
    }
  },

  methods: {
    addColumn() {
      this.columns.push({
        label: `标题${this.columns.length + 1}`,
        pageId: 0
      })

      this.changeSetting()
    },

    moveCol(index, offset) {
      const { columns, onlyActiveComponent } = this
      const length = columns.length
      const targetIndex = index + offset
      if (
        (index === 0 && offset === -1) ||
        (index === length - 1 && offset === 1)
      ) {
        return
      }
      const column = columns.splice(index, 1)[0]
      columns.splice(targetIndex, 0, column)

      this.changeSetting()
    },

    removeColumn(index) {
      if (this.columns.length === 1) return
      this.columns.splice(index, 1)
      this.changeSetting()
    },

    changeSlotState(i, val) {
      this.changeSetting()
    },

    changeSetting() {
      this.$emit('input', this.columns)
    }
  }
}
</script>

<style lang="scss">
/* 样式在 scss/components/widget-column 里引入 */
</style>
