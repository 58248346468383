<template>
  <div class="project-catelog" @contextmenu="showCatalogContextMenu">
    <div
      v-if="!emptyCatalog"
      class="project-setting"
      @click="openProjectConst"
      :class="{ 'is-active': currentFile.type === 'projectConst' }"
    >
      <icon name="constants" />
      <span class="project-setting_label">全局变量配置</span>
    </div>
    <!-- 目录树 -->
    <div class="project-catelog_loading" v-if="folderLoading">
      <i class="el-icon-loading"></i>
    </div>
    <el-tree
      v-show="!folderLoading && !emptyCatalog"
      node-key="nodeKey"
      ref="tree"
      :data="projectTree"
      :props="{ label: 'name', children: 'children', isLeaf: 'leaf' }"
      :indent="12"
      :filter-node-method="fileSearch"
      :render-content="renderContent"
      @current-change="handleCurrentChange"
      @node-expand="handleNodeExpand"
    >
    </el-tree>
    <div
      class="project-catelog_empty"
      @click="showAddFolderPanel"
      v-if="!folderLoading && emptyCatalog && !addFolderVisible"
    >
      <i class="el-icon-circle-plus-outline"></i>
      <div class="text">
        点击新建文件夹
      </div>
    </div>
    <!-- 添加文件夹 -->
    <input
      v-if="addFolderVisible"
      v-show="!addFolderLoading"
      ref="addFolderInput"
      class="project-catelog-input addFolderInput"
      type="text"
      placeholder="输入文件夹名字"
      v-clickoutside="addFolder"
      @keydown.enter="addFolder"
    />
    <div class="project-catelog_loading small" v-if="addFolderLoading">
      <i class="el-icon-loading"></i>
    </div>
    <x-contextmenu ref="contextMenu">
      <li @click="parentNode('page')" v-if="contextMenu.node.level === 1">
        新增页面
      </li>
      <li @click="showInputRename()" v-if="contextMenu.node.level < 3">
        重命名
      </li>
      <!-- <li @click="showAddFolderPanel">新建文件夹</li> -->
      <li @click="deleteNode">删除</li>
    </x-contextmenu>

    <!-- 发布站点成功 -->
    <el-dialog
      title="发布成功"
      :visible.sync="dialogs.releaseResults"
      class="dark"
      width="800px"
    >
      <el-table :data="releaseResults" style="width: 100%">
        <el-table-column prop="fullPath" label="页面路径"></el-table-column>
        <el-table-column label="操作人">
          <template slot-scope="scope" width="180">
            <span class="iconfont icon-email">
              {{ scope.row.user && scope.row.user.email }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="100" label="操作">
          <template slot-scope="scope">
            <a
              class="el-button el-button--small el-button--primary"
              target="_blank"
              :href="scope.row.url"
              >查看页面</a
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogs.releaseResults = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  FOLDER_NODE_TEMPLATE,
  PAGE_NODE_TEMPLATE
} from '@/pages/const/template'
import bus from '../bus'
import { cloneDeep, debounce, result } from 'lodash-es'
import { mapState, mapGetters } from 'vuex'
import * as mutationTypes from '@/store/mutation-types'
import Clickoutside from 'element-ui/lib/utils/clickoutside'
import splitPageContent from './splitPageContent'

export default {
  name: 'ProjectCatelog',
  directives: { Clickoutside },
  props: {
    projectId: Number
  },

  computed: {
    ...mapState([
      'projectTree',
      'openedFiles',
      'currentFile',
      'pageMapping',
      'projectSetting',
      'projectConstants'
    ]),
    ...mapGetters(['currentPage']),

    sideNavTree() {
      return this.projectSetting.components.sideNav
    },

    emptyCatalog() {
      return this.projectTree.length === 0
    }
  },

  data() {
    return {
      environmentMap: {
        alpha: 'alpha',
        alta: 'alta',
        internal: 'production',
        production: 'production'
      },
      releaseResults: [],
      release: {},
      dialogs: {
        releaseSite: false,
        release: false,
        releaseResults: false
      },

      folderLoading: false,
      folders: [], // folders管理，因为$refs.tree.root.childNodes 会有 bug

      contextMenu: {
        node: {},
        data: {}
      },
      project: null,
      addFolderLoading: false,
      addFolderVisible: false,
      currentNode: null,
      currentPageNode: null,
      coreFiles: ['init.func', 'CONSTANTS', '主视图']
    }
  },

  methods: {
    /**
     * @同步导航数据
     */
    async syncNavigator() {
      const projectTree = this.projectTree
      const folders = projectTree.map(folder => {
        const tempFolder = new FOLDER_NODE_TEMPLATE()
        const pages = folder.children.map(page => {
          const tempPage = new PAGE_NODE_TEMPLATE()
          return Object.assign(tempPage, {
            label: page.name,
            pageId: page.pageId
          })
        })

        return Object.assign(tempFolder, {
          label: folder.name,
          pages
        })
      })

      this.$store.dispatch('updateProjectSetting', {
        _updatePath: 'components.sideNav',
        value: folders
      })
    },

    getNodePath(node) {
      const { level, parent: parentNode } = node

      if (level === 1) {
        return '/'
      }
      if (level === 2) {
        return `/${parentNode.data.name}`
      }
    },

    getNodeFullPath(node) {
      return `${node.level === 2 ? this.getNodePath(node) : ''}/${
        node.data.name
      }`
    },

    handleNodeExpand(data, node, vm) {
      const inputNodeType = node.inputNodeType
      if (inputNodeType) {
        this.addInputNodeInsert(
          data,
          node,
          this.$refs.tree.store,
          inputNodeType
        )
      }
    },

    removeInputNode(node, data) {
      node.parent.inputNodeType = false
      this.$refs.tree.store.remove(data)
    },

    /**
     * @添加页面或者方法按钮点击
     * @param node 父节点
     * @param store el-tree store
     * @param type 添加的类型:[ page, func ]
     */
    handleAdding(e, node, store, type) {
      e.stopPropagation()
      this.$set(node, 'inputNodeType', type)
      if (!node.expanded) {
        node.expand()
      }
      this.addInputNodeInsert(node.data, node, store, type)
    },

    /**
     * @**右键菜单**显示目录重命名的输入框
     */
    async showInputRename() {
      const { node } = this.contextMenu
      this.$set(node, 'isEditing', true)
    },

    /**
     * @**右键菜单**显示添加 page 的输入框
     */
    parentNode(type) {
      const { node, data, store } = this.contextMenu
      this.$set(node, 'inputNodeType', type)
      if (!node.expanded) {
        node.expand()
      }
      this.addInputNodeInsert(data, node, store, type)
    },

    /**
     * @插入输入框并 focus
     */
    addInputNodeInsert(data, node, store, fileType) {
      setTimeout(_ => {
        const newChild = {
          type: 'input',
          nodeKey: 'tempInputNode',
          fileType,
          leaf: true
        }

        node.insertChild({ data: newChild }, node.childNodes.length)

        this.$nextTick(_ => {
          const inputNode = document.getElementById('inputNode')
          inputNode.focus()
        })
      }, 300)
    },

    /**
     * @右键删除
     */
    deleteNode() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'dark',
        type: 'warning'
      })
        .then(async () => {
          const {
            contextMenu: { data, node }
          } = this
          const { type } = data

          if (~this.coreFiles.indexOf(data.name)) {
            this.$message.warning('页面核心文件不可删除')
            return
          }

          let filesOpenedToClose

          if (type === 'page' || type === 'folder') {
            let fullPath = this.getNodeFullPath(node)
            let relativePageIds
            if (type === 'page') {
              relativePageIds = [data.pageId]
            } else {
              relativePageIds = node.childNodes.map(item => item.data.pageId)
            }

            filesOpenedToClose = this.openedFiles.filter(file => {
              return relativePageIds.indexOf(file.pageId) > -1
            })

            // await this.$api.deletePage(this.projectName, fullPath)
            // 同步 project setting
            const navTree = cloneDeep(this.sideNavTree)

            if (type === 'folder') {
              // folder delete
              const targetFolderIndex = navTree.findIndex(
                folder => folder.label === data.name
              )
              navTree.splice(targetFolderIndex, 1)
            } else {
              // page delete
              const targetPages = navTree.find(
                item => item.label === node.parent.data.name
              ).pages

              const targetPageIndex = targetPages.findIndex(
                item => item.label === data.name
              )
              targetPages.splice(targetPageIndex, 1)
            }

            this.updateSideNavTreeAndSave(navTree)
          } else if (type === 'func') {
            const targetPageID = data.pageID
            const targetFuncID = data.nodeKey

            filesOpenedToClose = this.openedFiles.find(
              file => file.data.fid === targetFuncID
            )

            await this.$store.dispatch('delFiles', {
              pageId: targetPageID,
              fid: targetFuncID
            })
          }

          // 删除文件后关闭 tab
          this.$store.dispatch('closeFile', filesOpenedToClose)

          this.$store.commit(mutationTypes.REMOVE_PROJECT_TREE_NODE, {
            parent: node.parent.data.children,
            target: data
          })
          node.parent.removeChild(node)
        })
        .catch(console.log)
    },

    showAddFolderPanel() {
      this.addFolderVisible = true
      this.$nextTick(_ => {
        this.$refs.addFolderInput.focus()
      })
    },

    /**
     * @新增节点的输入按钮提交
     */
    async inputNodeSubmit(event, inputNode, inputNodeData) {
      if (!inputNode.parent) return
      const { fileType } = inputNodeData
      const parentNode = inputNode.parent
      const name = event.target.value
      const { project } = this

      if (name === '') {
        this.removeInputNode(inputNode, inputNodeData)
        return
      }

      /*
       * @添加方法
       */
      if (fileType === 'func') {
        const pageNode = inputNode.parent
        let targetPageId = pageNode.data.pageId

        // 生成方法对象
        const funcContent = this.project.generateFunctionObject({
          name,
          argumentsMapping: [],
          params: []
        })
        // 插入 page
        this.$store.dispatch('addFiles', {
          type: 'functions',
          content: funcContent,
          pageId: targetPageId
        })

        // 在文件导航下添加节点
        const newFunc = {
          pageKey: pageNode.key,
          nodeKey: funcContent.fid,
          name: name + '.func',
          pageID: targetPageId,
          type: 'func',
          leaf: true
        }

        this.$store.commit(mutationTypes.ADD_PROJECT_TREE_NODE, {
          parent: parentNode.data.children,
          target: newFunc
        })

        this.removeInputNode(inputNode, inputNodeData)

        // 保存文件
        await this.$store.dispatch('saveProject')
      } else {
        /**
         * @添加页面
         */
        const pagePath = this.getNodePath(inputNode)

        const addInstance = project.addPage(pagePath, name)
        const newId = addInstance.pageId

        const loading = this.$loading({
          text: '请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(37, 37, 38, 0.51)',
          target: '.project-catelog'
        })

        addInstance.request
          .then(async data => {
            data.type = fileType
            data.leaf = false
            data.name = name
            const pagePath = this.getNodeFullPath(parentNode)
            const fullPath = `${pagePath}/${name}`
            const pageContent = addInstance.pageContent
            pageContent.path = `${pagePath}`
            data.pageId = pageContent.id
            data.nodeKey = data.pageId
            data.pageContent = pageContent
            data.children = splitPageContent(
              cloneDeep(pageContent),
              data.nodeKey
            )

            this.$store.commit(mutationTypes.ADD_PROJECT_TREE_NODE, {
              parent: parentNode.data.children,
              target: data
            })
            this.removeInputNode(inputNode, inputNodeData)
            return { data }
          })
          .then(async ({ data }) => {
            const { pageContent } = data
            const tree = cloneDeep(this.sideNavTree)
            const targetFolder = tree.find(
              folder => folder.label === parentNode.data.name
            )

            const tempPage = new PAGE_NODE_TEMPLATE()

            Object.assign(tempPage, {
              label: data.name,
              pageId: newId,
              path: `${pageContent.path}/${pageContent.name}`
            })

            targetFolder.pages.push(tempPage)

            this.updateSideNavTreeAndSave(tree)
          })
          .finally(_ => {
            loading.close()
          })
      }
    },

    checkFolderNameValid(name) {
      const reg = /[./\\?!()[\]{}|]/
      if (reg.test(name)) {
        this.$message.warning('文件名不合法，禁止包含特殊字符')
        return false
      }
      return true
    },

    addFolder(event) {
      let name = result(event, 'target.value', this.$refs.addFolderInput.value)

      if (name !== '') {
        if (!this.checkFolderNameValid(name)) {
          return false
        }
        this.addFolderLoading = true
        this.project.addCatalog(name).then(async data => {
          this.addFolderVisible = false

          const tree = cloneDeep(this.sideNavTree)

          const tempFolder = new FOLDER_NODE_TEMPLATE()
          Object.assign(tempFolder, {
            label: name
          })

          tree.push(tempFolder)

          this.updateSideNavTreeAndSave(tree)

          this.$store.commit(mutationTypes.ADD_PROJECT_TREE_NODE, {
            target: data
          })

          this.addFolderLoading = false
        })
      } else {
        this.addFolderVisible = false
      }
    },

    async handleCurrentChange(data, node) {
      if (node.key === 'tempInputNode') return
      const fileName = data.name

      this.currentNode = cloneDeep(node)

      // 点击视图或者方法
      if (node.level === 3) {
        this.currentPageNode = cloneDeep(node.parent)
        const { nodeKey, type, pageKey, pageID } = data

        this.$store.dispatch('setPreviewFile', {
          name: fileName,
          // folderKey -> pageKey -> nodeKey: hash 为该节点在文件导航的 path，在删除文件夹或页面时关闭打开的文件用
          hash: nodeKey,
          pageKey,
          type,
          pageId: pageID,
          unsaved: false
        })
      }
    },

    openHome() {
      this.$refs.tree.setCurrentKey(null)
      this.$store.dispatch('setPreviewFile', {
        name: '全局导航配置',
        hash: 'projectSetting',
        pageId: 'projectSetting',
        type: 'home',
        unsaved: false,
        data: this.projectSetting
      })
    },

    openProjectConst() {
      this.$refs.tree.setCurrentKey(null)
      this.$store.dispatch('setPreviewFile', {
        name: '全局变量配置',
        hash: 'projectConstants',
        pageId: 'projectConstants',
        type: 'projectConst',
        path: '/.constants',
        unsaved: false,
        data: this.projectConstants
      })
    },

    // 刷新 pageNode 下 files
    refreshPageNode(isCurrentPage, pageID, pageKey, pagePath) {
      const { key: currentKeyToSet } = this.currentNode

      this.currentPageNode.expand()
      const { key: currentPageKey } = this.currentPageNode

      this.$nextTick(_ => {
        const files = splitPageContent(
          isCurrentPage ? this.currentPage : this.pageMapping[pageID],
          isCurrentPage ? currentPageKey : pageKey
        )

        this.$refs.tree.updateKeyChildren(
          isCurrentPage ? currentPageKey : pageKey,
          files
        )

        this.$refs.tree.setCurrentKey(currentKeyToSet)
      })
    },

    showCatalogContextMenu(event) {
      event.preventDefault()
      this.contextMenu = {
        node: {},
        data: {}
      }
      this.$refs.contextMenu.open()
    },

    renderContent(h, { node, data, store }) {
      const showContextmenu = event => {
        const nodeElm = event.target
        event.preventDefault()
        event.stopPropagation()
        this.$refs.contextMenu.open()
        this.contextMenu = { node, data, store, nodeElm }
      }

      const handleDblclick = event => {
        if (node.level === 3) {
          this.$store.commit(mutationTypes.SET_PREVIEW_FILE, null)
        }
      }

      const actionsMap = {
        page: {
          text: '添加方法',
          icon: 'el-icon-circle-plus-outline',
          targetType: 'func'
        },
        folder: {
          text: '添加页面',
          icon: 'el-icon-circle-plus-outline',
          targetType: 'page'
        }
      }

      const { type } = data

      // 新增页面或目录的节点
      const nodeInput = (
        <div>
          <input
            class="project-catelog-input"
            id="inputNode"
            type="text"
            placeholder={`输入${type === 'page' ? '方法' : '文件'}名`}
            on-keydown={event => {
              if (event.keyCode === 13) {
                this.inputNodeSubmit(event, node, data)
              }
            }}
            on-blur={event => {
              this.inputNodeSubmit(event, node, data)
            }}
          />
        </div>
      )

      const nodeActions = ['folder', 'page'].includes(type) ? (
        <div class="file-name_actions">
          <div
            class="file-name_icon tooltip left"
            tooltip={actionsMap[type].text}
            on-click={e =>
              this.handleAdding(e, node, store, actionsMap[type].targetType)
            }
          >
            <i class={actionsMap[type].icon} />
          </div>
        </div>
      ) : (
        ''
      )

      let nodeContent
      let fileIcon

      if (~['ui', 'dialog'].indexOf(type) && node.level === 3) {
        fileIcon = 'design'
      } else if (type === 'func') {
        fileIcon = 'javascript'
      } else if (type === 'const') {
        fileIcon = 'constants'
      }

      if (!node.isEditing) {
        nodeContent = [
          <i
            class={{
              iconfont: true,
              'el-icon-folder-opened color-folder':
                type === 'folder' && node.expanded,
              'el-icon-folder color-folder':
                type === 'folder' && !node.expanded,
              'el-icon-document color-page':
                ~['page'].indexOf(type) && node.level === 2,
              'color-index':
                ~['ui', 'dialog'].indexOf(type) && node.level === 3,
              'color-js': type === 'func',
              'color-js': type === 'const'
            }}
          >
            {fileIcon && <icon name={fileIcon} />}
          </i>,
          node.label === 'index' ? '主视图.ui' : node.label,
          nodeActions
        ]
      } else {
        const rename = event => {
          this.$set(node, 'isEditing', false)
          this.renameNode(event, node, data)
        }
        nodeContent = [
          <input
            class="project-catelog-input"
            id="inputNode"
            type="text"
            placeholder="请输入文件名"
            on-click={event => event.stopPropagation()}
            on-keydown={event => {
              if (event.keyCode === 13) {
                rename(event)
              }
            }}
            on-blur={event => {
              rename(event)
            }}
          />
        ]
      }

      return (
        <div
          class="file-name"
          on-dblclick={handleDblclick}
          on-contextmenu={showContextmenu}
        >
          {type !== 'input' ? (
            <div class="file-name_inner">{nodeContent}</div>
          ) : (
            nodeInput
          )}
        </div>
      )
    },

    async updateSideNavTreeAndSave(navTree) {
      await this.$store.dispatch('updateProjectSetting', {
        _updatePath: 'components.sideNav',
        value: navTree
      })
    },

    fileSearch(searchName, nodeData, node) {
      const value = searchName.trim()
      return value
        ? nodeData.name.includes(value) ||
            result(node, 'parent.data.name', '').includes(value)
        : true
    },

    renameNode: debounce(async function(event, node, data) {
      const oldName = data.name
      const newName = event.target.value

      let oldPath
      let newPath

      if (node.data.type === 'folder') {
        oldPath = `/${oldName}`
        newPath = `/${newName}`
      } else {
        let parentNodeName = node.parent.data.name
        oldPath = `/${parentNodeName}/${oldName}`
        newPath = `/${parentNodeName}/${newName}`
      }

      // await this.$api.moveFile(this.projectName, oldPath, newPath)

      this.$store.commit(mutationTypes.UPDATE_PROJECT_TREE_NODE, {
        target: data,
        data: { name: newName }
      })

      if (node.data.type === 'folder') {
        // 更新导航页导航节点的数据
        let targetFolderIndex = this.sideNavTree.findIndex(
          item => item.label === oldName
        )
        this.$store.dispatch('updateProjectSetting', {
          _updatePath: `components.sideNav[${targetFolderIndex}].label`,
          value: newName
        })
      } else {
        // 更新导航页导航节点的数据
        let targetFolderIndex = this.sideNavTree.findIndex(
          item => item.label === node.parent.data.name
        )
        let targetPageIndex = this.sideNavTree[
          targetFolderIndex
        ].pages.findIndex(item => item.label === oldName)

        this.$store.dispatch('updateProjectSetting', {
          _updatePath: `components.sideNav[${targetFolderIndex}].pages[${targetPageIndex}].label`,
          value: newName
        })
      }

      this.$store.dispatch('saveProject')
    }, 300)
  },

  created() {
    const { projectId } = this
    this.folderLoading = true
    this.$store.dispatch('initEditor', projectId).then(project => {
      this.project = project
      this.folderLoading = false
    })
  },

  mounted() {
    bus.$on('addFolder', _ => {
      this.showAddFolderPanel()
    })

    bus.$on('syncNavigator', async _ => {
      await this.syncNavigator()

      this.$store.dispatch('saveProject')
      bus.$emit('syncDone')
    })

    bus.$on('currentPageFileChange', _ => {
      this.refreshPageNode(true)
    })

    bus.$on('showReleaseSite', result => {
      this.showReleaseSite()
    })
  }
}
</script>

<style src="./catalog.scss"></style>

<style>
.is-commit-latest {
  color: green;
}
</style>
