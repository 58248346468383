// schemas 目录下所有 _*-schema.js 的文件都视为 schema 文件

const schemaMap = {}

const schemaContext = require.context('.', true, /_[\w-]+-schema.js$/)
schemaContext.keys().forEach(path => {
  const schema = schemaContext(path).default

  schemaMap[schema.componentName] = schema
})

export default schemaMap
