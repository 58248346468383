import { UI_SCHEMAS, EVENT_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { isButtonGroup, label } = UI_SCHEMAS
const { bindKey, optionsKey, disabled } = DATA_SCHEMAS
const { handleChangeKey } = EVENT_SCHEMAS

export default {
  componentName: 'XCheckbox',

  label: 'Checkbox',

  props: {
    isButtonGroup: false,
    label: '条目标签'
  },

  configs: {
    ui: {
      label: '视觉',
      content: { isButtonGroup, label }
    },

    data: {
      label: '数据逻辑',
      content: { bindKey, disabled, optionsKey }
    },

    events: {
      label: '事件绑定',
      content: { handleChangeKey }
    }
  }
}
