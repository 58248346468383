<script>
import ModelMixin from '../mixins/model-mixin'
import OptionsMixin from '../mixins/options-mixin'
import DisabledMixin from '../mixins/disabled-mixin'

export default {
  name: 'XRadio',

  mixins: [ModelMixin, OptionsMixin, DisabledMixin],

  props: {
    direction: String,
    label: String,
    isButtonGroup: Boolean
  },

  watch: {
    isButtonGroup(val) {
      this.$children.forEach(e => e.$forceUpdate())
    }
  },

  render() {
    return (
      <div class="x-radio x-form-item">
      {this.label ? <div class="x-form-label">{this.label}</div> : ''}
        <el-radio-group
          value={this.bindValue}
          onInput={this.updateValue}
          onChange={this.onChange}
          disabled={this.isDisabled}
          size="small"
        >
          {this.options.map(
            (e, i) => {
              if (!this.isButtonGroup) {
                return (
                  <el-radio label={e[this.optionsValueKey || 'value']} key={i}>
                    {e[this.optionsLabelKey || 'label']}
                  </el-radio>
                )
              }
              return (
                <el-radio-button
                  label={e[this.optionsValueKey || 'value']}
                  key={i}
                >
                  {e[this.optionsLabelKey || 'label']}
                </el-radio-button>
              )
            }
          )}
        </el-radio-group>
        {this.error ? <div class="x-err-msg">校验有误</div> : ''}
      </div>
    )
  }
}
</script>
