<template>
  <iframe ref="iframe" class="iframe-container" :src="src" frameborder="0" @load="handleLoad"/>
</template>

<script>
import model from '../../compatible/model'
import goLogin from '../../compatible/goLogin'
import { API_HOST } from '@/api/constants'

export default {
  name: 'IframeContainer',

  props: {
    src: {
      type: String,
      default: ''
    }
  },

  methods: {
    handleLoad() {
      // 同源注入变量
      if (`//${window.location.host}` === API_HOST) {
        console.log('同源', API_HOST, window.location.host)
        this.injectModel()
      }
    },
    injectModel() {
      const iframe = this.$refs.iframe
      let iframeWin
      if (iframe) {
        iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow
        // （兼容）注入 model 变量到子页面中，用到控制导航
        iframeWin.__model = model
        // （兼容）注入 goLogin 变量到子页面中，用到登录失效后跳转到登录页
        iframeWin.go_login = goLogin
        // （兼容）反向注入子页面中的 jQuery 和 $ 到父页面
        window.jQuery = iframeWin.jQuery
        window.$ = iframeWin.$
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe-container {
  flex: 1;
  width: 100%;
  height: 100%;
}
</style>
