<template>
  <header class="main-header" :class="{ 'is-fixed': $route.name !== 'home' }">
    <div class="logo" @click="$router.push('/')">
      <span class="logo-text">doris</span>
    </div>
    <ul class="header-nav">
      <router-link exact to="/" class="header-navitem" tag="li">首页</router-link>
      <router-link exact to="/profile" class="header-navitem" tag="li">我的项目</router-link>
      <a target="_blank" href class="header-navitem">帮助中心</a>
    </ul>
    <div
      v-if="shouldShowCreateProjectButton"
      class="page-profile_add"
      @click="promptToCreateProject"
    >
      <i class="el-icon-circle-plus"></i>
      <span class="text">创建项目</span>
    </div>
    <slot></slot>
    <span class="header-user">
      <i class="icon"></i>
      <span>{{user.username}}</span>
    </span>
  </header>
</template>
<script>
import { mapState } from 'vuex'
import bus from '@editor/modules/bus'

export default {
  name: 'MainHeader',

  computed: {
    ...mapState(['user']),
    shouldShowCreateProjectButton() {
      return this.$route.name === 'profile'
    }
  },

  methods: {
    promptToCreateProject() {
      bus.$emit('promptToCreateProject')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/var.scss';

.main-header {
  position: relative;
  padding: 0 40px;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: #f7f7f7;

  &.is-fixed {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .logo {
    height: 60px;
    line-height: 59px;
    float: left;
    display: flex;
    align-items: center;
    margin-right: 35px;
    cursor: pointer;

    &-text {
      font-size: 24px;
      line-height: 1.5;
      padding-left: 10px;
      font-weight: normal;
      font-family: Lobster, cursive;
    }
  }

  .header-user {
    position: absolute;
    right: 30px;
    top: 0;
    line-height: 60px;
    font-size: 14px;
    opacity: 0.7;
  }

  .el-button a {
    font-size: 14px;
    color: #333333;
    text-decoration: none;
  }

  .header-nav {
    height: 100%;
    display: inline-block;
  }
  .header-navitem {
    line-height: 60px;
    display: inline-block;
    width: 90px;
    text-align: center;
    font-size: 14px;
    opacity: 0.8;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    font-weight: normal;

    a {
      display: inline-block;
      width: 100%;
      text-decoration: none;
    }

    &.router-link-active {
      opacity: 1;
      font-weight: 600;
      color: $primary;

      &::after {
        content: ' ';
        position: absolute;
        left: 50%;
        bottom: 7px;
        width: 14px;
        height: 3px;
        border-radius: 1px;
        transform: translateX(-50%);
        background-color: $primary;
      }
    }
  }
}
</style>
