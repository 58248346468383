/*
 * 这个是图表 chart 组件在编辑时显示的 demo 数据
 * 在 runtime 模式下不需要显示 demo 数据
 * 数据量太大，因此单独拆出来，确保在 runtime 打包依赖之外
 */
export default {
  line: {
    defaultData: {
      columns: ['日期', '成本', '利润', '占比', '其他'],
      rows: [
        { '成本': 1523, '日期': '1月1日', '利润': 1523, '占比': 0.12, '其他': 100 },
        { '成本': 1223, '日期': '1月2日', '利润': 1523, '占比': 0.345, '其他': 100 },
        { '成本': 2123, '日期': '1月3日', '利润': 1523, '占比': 0.7, '其他': 100 },
        { '成本': 4123, '日期': '1月4日', '利润': 1523, '占比': 0.31, '其他': 100 },
        { '成本': 3123, '日期': '1月5日', '利润': 1523, '占比': 0.12, '其他': 100 },
        { '成本': 7123, '日期': '1月6日', '利润': 1523, '占比': 0.65, '其他': 100 }
      ]
    },
    defaultSettings: {
      metrics: ['成本', '利润'],
      dimension: ['日期']
    }
  },
  histogram: {
    defaultData: {
      columns: ['日期', '成本', '利润', '占比', '其他'],
      rows: [
        { '成本': 1523, '日期': '1月1日', '利润': 1523, '占比': 0.12, '其他': 100 },
        { '成本': 1223, '日期': '1月2日', '利润': 1523, '占比': 0.345, '其他': 100 },
        { '成本': 2123, '日期': '1月3日', '利润': 1523, '占比': 0.7, '其他': 100 },
        { '成本': 4123, '日期': '1月4日', '利润': 1523, '占比': 0.31, '其他': 100 }
      ]
    },
    defaultSettings: {
      metrics: ['成本', '利润'],
      dimension: ['日期']
    }
  },
  bar: {
    defaultData: {
      columns: ['日期', '成本', '利润'],
      rows: [
        { '日期': '1月1号', '成本': 123, '利润': 3 },
        { '日期': '1月2号', '成本': 1223, '利润': 6 },
        { '日期': '1月3号', '成本': 2123, '利润': 90 },
        { '日期': '1月4号', '成本': 4123, '利润': 12 },
        { '日期': '1月5号', '成本': 3123, '利润': 15 },
        { '日期': '1月6号', '成本': 7123, '利润': 20 }
      ]
    },
    defaultSettings: {
      dimension: ['成本'],
      metrics: ['利润']
    }
  },
  pie: {
    defaultData: {
      columns: ['日期', '成本', '利润'],
      rows: [
        { '日期': '1月1号', '成本': 123, '利润': 3 },
        { '日期': '1月2号', '成本': 1223, '利润': 6 },
        { '日期': '1月3号', '成本': 2123, '利润': 90 },
        { '日期': '1月4号', '成本': 4123, '利润': 12 },
        { '日期': '1月5号', '成本': 3123, '利润': 15 },
        { '日期': '1月6号', '成本': 7123, '利润': 20 }
      ]
    },
    defaultSettings: {
      dimension: '成本',
      metrics: '利润'
    }
  },
  ring: {
    defaultData: {
      columns: ['日期', '成本', '利润'],
      rows: [
        { '日期': '1月1号', '成本': 123, '利润': 3 },
        { '日期': '1月2号', '成本': 1223, '利润': 6 },
        { '日期': '1月3号', '成本': 2123, '利润': 90 },
        { '日期': '1月4号', '成本': 4123, '利润': 12 },
        { '日期': '1月5号', '成本': 3123, '利润': 15 },
        { '日期': '1月6号', '成本': 7123, '利润': 20 }
      ]
    },
    defaultSettings: {
      dimension: '成本',
      metrics: '利润'
    }
  },
  waterfall: {
    defaultData: {
      columns: ['活动', '时间'],
      rows: [
        { '活动': '吃饭', '时间': 4 },
        { '活动': '睡觉', '时间': 10 },
        { '活动': '打豆豆', '时间': 5 }
      ]
    },
    defaultSettings: {
      dimension: '活动',
      metrics: '时间'
    }
  },
  funnel: {
    defaultData: {
      columns: ['状态', '状态1', '数值'],
      rows: [
        { '状态': '展示', '状态1': '展示1', '数值': 900 },
        { '状态': '访问', '状态1': '访问1', '数值': 600 },
        { '状态': '点击', '状态1': '点击1', '数值': 300 },
        { '状态': '订单', '状态1': '订单1', '数值': 100 }
      ]
    },
    defaultSettings: {
      dimension: '状态1',
      metrics: '数值'
    }
  },
  radar: {
    defaultData: {
      columns: ['日期', '销售额-1季度', '销售额-2季度', '占比', '其他'],
      rows: [
        { '日期': '1月1日', '销售额-1季度': 1523, '销售额-2季度': 1523, '占比': 0.12, '其他': 100 },
        { '日期': '1月2日', '销售额-1季度': 1223, '销售额-2季度': 1523, '占比': 0.345, '其他': 100 },
        { '日期': '1月3日', '销售额-1季度': 2123, '销售额-2季度': 1523, '占比': 0.7, '其他': 100 },
        { '日期': '1月4日', '销售额-1季度': 4123, '销售额-2季度': 1523, '占比': 0.31, '其他': 100 },
        { '日期': '1月5日', '销售额-1季度': 3123, '销售额-2季度': 1523, '占比': 0.12, '其他': 100 },
        { '日期': '1月6日', '销售额-1季度': 7123, '销售额-2季度': 1523, '占比': 0.65, '其他': 100 }
      ]
    },
    defaultSettings: {
      dimension: ['日期'],
      metrics: ['销售额-1季度', '销售额-2季度', '占比'],
      dataType: { '占比': 'percent' }
    }
  },
  sankey: {
    defaultData: {
      columns: ['页面', '访问量'],
      rows: [
        { '页面': '首页', '访问量': 100000 },
        { '页面': '列表页a', '访问量': 20000 },
        { '页面': '列表页b', '访问量': 80000 },
        { '页面': '内容页a-1', '访问量': 10000 },
        { '页面': '内容页a-2', '访问量': 10000 },
        { '页面': '内容页b-1', '访问量': 60000 },
        { '页面': '内容页b-2', '访问量': 20000 }
      ]
    },
    defaultSettings: {
      links: [
        { source: '首页', target: '列表页a', value: 0.5 },
        { source: '首页', target: '列表页b', value: 0.5 },
        { source: '列表页a', target: '内容页a-1', value: 0.1 },
        { source: '列表页a', target: '内容页a-2', value: 0.4 },
        { source: '列表页b', target: '内容页b-1', value: 0.2 },
        { source: '列表页b', target: '内容页b-2', value: 0.3 }
      ],
      dataType: ['KMB', 'percent']
    }
  },
  map: {
    defaultData: {
      columns: ['位置', 'GDP'],
      rows: [
        { '位置': '吉林', '税收': 123, '人口': 123, '面积': 92134 },
        { '位置': '北京', '税收': 1223, '人口': 2123, '面积': 29234 },
        { '位置': '上海', '税收': 2123, '人口': 1243, '面积': 94234 },
        { '位置': '浙江', '税收': 4123, '人口': 5123, '面积': 29234 }
      ]
    },
    defaultSettings: {
      position: 'china',
      dimension: '位置',
      metrics: ['人口', '面积'],
      dataType: {
        '面积': 'KMB'
      }
    }
  },
  heatmap: {
    defaultData: {
      columns: ['时间', '地点', '人数'],
      rows: [
        { '时间': '星期一', '地点': '北京', '人数': 1000 },
        { '时间': '星期二', '地点': '上海', '人数': 400 },
        { '时间': '星期三', '地点': '杭州', '人数': 800 },
        { '时间': '星期二', '地点': '深圳', '人数': 200 },
        { '时间': '星期三', '地点': '长春', '人数': 100 },
        { '时间': '星期五', '地点': '南京', '人数': 300 },
        { '时间': '星期四', '地点': '江苏', '人数': 800 },
        { '时间': '星期三', '地点': '北京', '人数': 700 },
        { '时间': '星期三', '地点': '上海', '人数': 300 },
        { '时间': '星期二', '地点': '杭州', '人数': 500 }
      ]
    },
    defaultSettings: {
      xAxisList: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
      yAxisList: ['北京', '上海', '杭州', '深圳', '长春', '南京', '江苏']
    }
  }
}
