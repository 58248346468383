import { CHART_SCHEMAS } from '../../common-schemas'
import { cloneDeep } from 'lodash-es'
import { firstUpperCase } from '@/pages/editor/utils/component-name'
import charts from './charts'

const { ui, data, events } = CHART_SCHEMAS

const generator = chartName => {
  const config = charts.configMap[chartName]
  return {
    componentName: `XChart${firstUpperCase(chartName)}`,

    label: config.labelName,

    notFlex: true,

    props: {
      height: '300',
      tooltipVisible: true,
      legendVisible: true,
      legendPosition: 'top'
    },

    configs: {
      ui: {
        label: '视觉',
        content: {
          ...cloneDeep(ui)
        }
      },

      data: {
        label: '数据逻辑',
        content: {
          ...cloneDeep(data),
          settingBindKey: {
            name: '绑定图表配置',
            info: `使用参考 <a target="_blank" href="https://elemefe.github.io/v-charts/#/${chartName}?id=settings-%e9%85%8d%e7%bd%ae%e9%a1%b9">配置文档</a>`
          }
        }
      },

      events: {
        label: '事件绑定',
        content: {
          ...cloneDeep(events)
        }
      }
    }
  }
}

export default generator
