<template>
<div class="x-text x-form-item">
  <div v-if="label" class="x-form-label">{{this.label}}</div>
  <a
    class="x-text_inner"
    :href="genHref"
    target="_blank"
    :class="{'is-text': !isHref}"
    @click="handleClick"
    :style="style"
  >{{genText}}</a>
</div>
</template>

<script>
import exprEval from '@editor/utils/expression-eval'
import ModelMixin from '../mixins/model-mixin'
import basicComponentMixin from '../mixins/basic-component.mixin'

export default {
  name: 'XText',

  mixins: [ModelMixin, basicComponentMixin],

  props: {
    isHref: Boolean,
    href: Object,
    text: Object,
    label: String,
    fontSize: Number,
    bold: Boolean,
    color: {
      type: String,
      default: 'rgba(51, 51, 51, 1)'
    },

    inTableSlot: Boolean,
    rowData: {
      type: Object,
      default: () => {
        return {
          data: {}
        }
      }
    }
  },

  computed: {
    genText() {
      const { text, rowData, inTableSlot } = this
      const { isStatic } = text
      return isStatic
        ? text.text
        : this._IS_EDIT_
          ? `$${text.textKey}`
          : exprEval(text.textKey, this.$store.state, rowData.data, inTableSlot)
    },

    genHref() {
      const { href, rowData, inTableSlot } = this
      const { isStatic } = href
      return isStatic
        ? href.href
        : this._IS_EDIT_
          ? `$${href.hrefKey}`
          : exprEval(href.hrefKey, this.$store.state, rowData.data, inTableSlot)
    },

    style() {
      return {
        fontSize: this.fontSize + 'px',
        fontWeight: this.bold ? 'bold' : 'normal',
        color: this.color
      }
    }
  },

  methods: {
    handleClick(e) {
      if (!this.isHref) e.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
.x-text {
  display: flex;

  &_inner {
    display: block;
    color: #409eff;
    font-size: 14px;
    text-decoration: underline;

    &:hover {
      color: color(#409eff blackness(20%));
    }

    &.is-text {
      text-decoration: none;
      color: #333;

      &:hover {
        color: #333;
      }
    }
  }
}
</style>
