<template>
  <div class="editor">
    <header class="editor-header">
      <div class="editor-header_actions">
        <!-- 应用导航类操作 -->
        <div class="actions">
          <button-cell icon="el-icon-back" @click="backToProfile" content="返回目录"></button-cell>
          <button-cell icon="new-file" @click="addFolder" content="新建文件夹"></button-cell>
          <button-cell icon="data-source" @click="editDataSource" content="配置数据源"></button-cell>
        </div>
        <!-- 舞台内操作 -->
        <div class="actions">
          <!-- <button-cell
            @click="openPreviewLink"
            target="blank"
            icon="el-icon-camera"
            content="预览当前页"
          ></button-cell> -->
          <div style="line-height: 36px;">{{ projectName }}</div>
        </div>
        <!-- 保存/发布 -->
        <div class="actions">
          <button-cell
            loadingText="保 存"
            :loading="fileSaveLoading"
            @click="saveProject"
            icon="save"
            content="保 存"
          ></button-cell>
          <button-cell @click="openPreviewSiteLink" icon="el-icon-view" content="预览"></button-cell>
          <button-cell @click="showReleasePanel" icon="deploy" content="发布"></button-cell>
          <button-cell @click="openProjectSiteLink" icon="website" content="打开站点"></button-cell>
          <!-- <button-cell
            @click="showReleaseLog"
            :loading="loadingReleaseLogs"
            icon="el-icon-date"
            content="发布记录"
          ></button-cell> -->
        </div>
      </div>
      <!-- system -->
      <div class="actions editor-header-tools">
        <button-cell icon="el-icon-user" :content="user.username"></button-cell>
      </div>
    </header>

    <div class="editor-main">
      <div class="editor-container">
        <!-- 左侧 -->
        <div class="editor-side-left">
          <!-- 页面搜索 -->
          <div class="editor-search">
            <el-input
              value
              @input="searchFile"
              prefix-icon="el-icon-search"
              size="mini"
              placeholder="搜索文件夹或页面"
            />
          </div>
          <!-- 应用导航 -->
          <el-collapse v-model="activeCollapses" class="editor-side-container">
            <el-collapse-item name="nav">
              <template slot="title">
                <icon name="menu" class="iconfont" />应用导航
              </template>
              <ProjectCatalog ref="ProjectCatalog" :project-id="projectId" />
            </el-collapse-item>

            <!-- 组件库 -->
            <template v-if="currentFile.pageId && currentFile.type === 'ui'">
              <el-collapse-item>
                <template slot="title">
                  <icon name="basic-component" class="iconfont" />基础组件
                </template>
                <BasicComponent />
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  <icon name="business-component" class="iconfont" />业务组件
                </template>
                <BusinessComponent />
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  <icon name="chart" class="iconfont" />图表组件
                </template>
                <ChartComponent />
              </el-collapse-item>
            </template>
          </el-collapse>
        </div>
        <!-- 中间 -->
        <EditorStage :user="user" :class="{folded: folded}" />
        <!--  组件属性编辑 右侧  -->
        <div class="editor-side-right editor-element-ui" :class="{folded: folded }">
          <div class="fold-btn" @click="folded = !folded">
            <i class="el-icon-s-unfold"></i>
          </div>
          <FunctionConfig v-if="currentFile.pageId && currentFile.type === 'func'" />
          <el-tabs
            class="config-tabs"
            v-if="currentFile.type === 'ui'"
            :value="currentModiferTabName"
            @input="changeModiferTabName"
          >
            <el-tab-pane v-if="currentComponents.length !== 1" label="页面" name="page">
              <PageConfig />
            </el-tab-pane>
            <el-tab-pane v-if="currentComponents.length === 1" label="组件" name="component">
              <ComponentConfig />
            </el-tab-pane>
            <el-tab-pane label="弹层" name="dialog">
              <DialogConfig />
            </el-tab-pane>
          </el-tabs>
          <ComponentTree
            v-if="currentFile.pageId && currentFile.type === 'ui' && onlyActiveComponent.uuid"
          />
        </div>
      </div>
    </div>
    <!-- 预览/发布 -->
    <el-dialog
      :title="`发布${currentFileTypeName}`"
      :visible.sync="dialogs.release"
      width="500px"
      class="dark"
      @close="releaseComment = ''"
    >
      <el-form label-position="top">
        <el-form-item label="版本描述">
          <el-input v-model="releaseComment" placeholder="请输入版本描述"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideReleasePanel">取 消</el-button>
        <el-button type="primary" @click="releasePage" :loading="dialogs.releaseLoading">
          <span v-if="dialogs.releaseLoading">发布中...</span>
          <span v-else>确定发布到线上</span>
        </el-button>
        <div class="dialog-comment" v-if="false">
          <p>
            你也可以
            <span v-if="currentFile.type !== 'home'">
              <a @click="showReleaseHistory" href="#">查看页面的发布历史</a>，或者
            </span>
            <a @click="releaseMultiPages" href="#">批量发布页面</a>
          </p>
        </div>
      </span>
    </el-dialog>
    <!-- 发布成功 -->
    <el-dialog title="发布成功" :visible.sync="dialogs.releaseResult" class="dark" width="800px">
      <el-table :data="[releaseResult]" style="width: 100%">
        <el-table-column prop="createdAt" label="发布时间">
          <template slot-scope="scope">
            <span>{{scope.row.createdAt | date-format('yyyy-MM-dd HH:mm')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人">
          <template slot-scope="scope" width="180">
            <span class="el-icon-message">{{scope.row.user && scope.row.user.email}}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" label="操作">
          <template slot-scope="scope">
            <a
              class="el-button el-button--small el-button--primary"
              target="_blank"
              :href="scope.row.url"
            >查看页面</a>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogs.releaseResult = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="发布记录" :visible.sync="dialogs.releaseLogs" class="dark" width="800px">
      <el-table :data="this.releaseLogs" style="width:100%;" fit>
        <el-table-column label="类型" width="60">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 'home'">导航</span>
            <span v-else-if="scope.row.type === 'pages'">页面</span>
          </template>
        </el-table-column>
        <el-table-column label="发布页面">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 'home'">
              <div class="release-log-tag">
                <span class="hash">({{ scope.row.settingHash | shorten(6) }})</span>
                <span class="path">导航页</span>
              </div>
            </span>
            <span v-else-if="scope.row.type === 'pages'">
              <div v-for="page in scope.row.pages" :key="page.hash" class="release-log-tag">
                <span class="hash">({{ page.hash | shorten(6) }})</span>
                <span class="path">{{ page.path }}</span>
              </div>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="发布备注" prop="comment"></el-table-column>
        <el-table-column prop="createdAt" label="发布时间">
          <template slot-scope="scope">
            <span>{{scope.row.createdAt | date-format('yyyy-MM-dd HH:mm')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="user.username" label="发布用户" width="100"></el-table-column>
        <el-table-column label="操作" width="60">
          <template slot-scope="scope">
            <el-button type="text" @click="fallbackPage(scope.row)">回滚</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import bus from './modules/bus'
// https://github.com/jaywcjlove/hotkeys
import HotKeys from 'hotkeys-js'
import EditorStage from './modules/stage/index'
import ProjectCatalog from './modules/catalog/index'
import BasicComponent from './modules/componentLibrary/basicComponent/index'
import BusinessComponent from './modules/componentLibrary/businessComponent/index'
import ChartComponent from './modules/componentLibrary/chartComponent/index'
import * as mutationTypes from '@/store/mutation-types'

import ComponentConfig from './modules/componentConfig/index'
import FunctionConfig from './modules/functionConfig/index'
import ComponentTree from './modules/componentTree/index'
import PageConfig from './modules/pageConfig/index'
import DialogConfig from './modules/dialogConfig/index'
import ComponentsConfig from './modules/componentsConfig/index'
import { mapState, mapGetters } from 'vuex'
import { API_HOST } from '@/api/constants'

export default {
  name: 'Editor',

  components: {
    EditorStage,
    ProjectCatalog,
    BasicComponent,
    BusinessComponent,
    ChartComponent,
    ComponentConfig,
    ComponentsConfig,
    FunctionConfig,
    ComponentTree,
    PageConfig,
    DialogConfig
  },

  computed: {
    ...mapState([
      'user',
      'currentFile',
      'projectId',
      'projectName',
      'projectTree',
      'openedFiles',
      'fileSaveLoading',
      'currentComponents',
      'currentDialog',
      'currentModiferTabName'
    ]),

    ...mapGetters([
      'currentPage',
      'undoDisabled',
      'redoDisabled',
      'lastSavedDisabled',
      'onlyActiveComponent'
    ]),

    development() {
      return process.env.NODE_ENV === 'development'
    },

    currentFileTypeName() {
      return this.currentFile.type === 'home' ? '导航' : '页面'
    }
  },

  created() {
    // 注意和线上的转换
    Vue.prototype._IS_EDIT_ = true
    // 设置开发环境
    this.$store.commit(mutationTypes.SET_IS_PRODUCTION, false)
  },

  mounted() {
    window.addEventListener('keydown', e => {
      if (e.metaKey && e.keyCode === 83) {
        e.preventDefault()
        this.$store.dispatch('saveProject')
      }
    })

    HotKeys('Backspace', 'UIEditor', e => {
      e.preventDefault()
      if (!this.currentComponents.length) return
      this.$store.dispatch(
        'removeComponent',
        this.currentComponents.map(t => t.uuid)
      )
    })

    HotKeys('command+a', 'UIEditor', e => {
      e.preventDefault()
      this.$store.dispatch('setMultiComponents', [
        ...this.currentFile.data.componentTree
      ])
    })

    HotKeys('command+z,ctrl+z', 'UIEditor', e => {
      e.preventDefault()
      this.$store.dispatch('undo')
    })

    HotKeys('alt+w,ctrl+w', e => {
      e.preventDefault()
      const { openedFiles, currentFile } = this
      const file = openedFiles.find(f => f.path === currentFile.path)
      this.$store.dispatch('closeFile', file)
    })

    HotKeys('command+shift+z,ctrl+shift+z', 'UIEditor', e => {
      e.preventDefault()
      this.$store.dispatch('redo')
    })
  },

  data() {
    return {
      releaseResult: {},
      projectId: Number(this.$route.params.projectId),
      dorisUIInstalled: false,
      displayDialog: false,
      loadingReleaseLogs: false,
      releaseLogs: [],
      dialogs: {
        releaseResult: false,
        release: false,
        releaseLoading: false,
        releaseLogs: false
      },
      // 默认打开应用导航
      activeCollapses: ['nav'],
      // 侧边栏折叠
      folded: false,
      releaseComment: ''
    }
  },

  methods: {
    showDialog(name) {
      ['releaseResult', 'release', 'releaseLogs'].forEach(item => {
        this.dialogs[item] = item === name
      })
    },

    hideDialog() {
      ['releaseResult', 'release', 'releaseLogs'].forEach(name => {
        this.dialogs[name] = false
      })
    },

    openPreviewLink() {
      if (!this.currentPage) {
        this.$message.warning('请选择要预览的页面')
        return
      }
      let { projectId } = this
      const env = process.env.NODE_ENV

      const { path, name, type } = this.currentPage
      let fullPath
      if (type === 'home') {
        fullPath = path
      } else {
        fullPath = `${path}/${name}`
      }

      if (!fullPath) return

      fullPath = encodeURIComponent(fullPath)
      projectId = encodeURIComponent(projectId)

      const productionUrl = this.$router.resolve({
        path: `/page/${projectId}`,
        query: {
          path: fullPath
        }
      })

      window.open(productionUrl.href, '_blank')
    },

    async openPreviewSiteLink() {
      const homeUrl = this.$router.resolve(`/preview/${this.projectId}`)
      window.open(homeUrl.href, '_blank')
    },

    async openProjectSiteLink() {
      const homeUrl = this.$router.resolve('/')
      window.open(homeUrl.href, '_blank')
    },

    changeModiferTabName(value) {
      this.$store.commit(mutationTypes.SET_CURRENT_TAB, value)
    },

    searchFile(value) {
      this.$refs.ProjectCatalog.$refs.tree.filter(value)
    },

    saveProject() {
      this.$store.dispatch('saveProject')
    },

    async releasePage() {
      this.dialogs.releaseLoading = true
      this.showDialog('release')
      await this.releaseProject()
      this.dialogs.releaseLoading = false
      this.hideDialog()
    },

    async releaseProject() {
      await this.$store.dispatch('saveProject', { showNotify: false })
      await this.$store.dispatch('releaseProject', {
        comment: this.releaseComment
      })
    },

    showReleasePanel() {
      this.showDialog('release')
    },

    hideReleasePanel() {
      this.hideDialog()
      this.releaseComment = ''
    },

    addFolder() {
      // bind in catalog/index.vue
      bus.$emit('addFolder')
    },

    editDataSource() {
      window.open(
        `${API_HOST}/saas/pagedesign.html?pageid=${this.projectId}`,
        '_blank'
      )
    },

    backToProfile() {
      this.$store.dispatch('resetEditor')
      this.$router.push({ name: 'profile' })
    },

    showReleaseHistory() {
      this.hideReleasePanel()
      bus.$emit('showPageReleaseHistory')
    },

    releaseMultiPages() {
      this.hideReleasePanel()
    },

    async showReleaseLog() {
      const { projectId } = this
      this.loadingReleaseLogs = true
      this.releaseLogs = await this.$api.getReleaseLog(projectId)
      this.showDialog('releaseLogs')
      this.loadingReleaseLogs = false
    },

    async fallbackPage(log) {
      if (log.type === 'home') {
        await this.$store.dispatch('releaseHome', {
          comment: 'Fallback Home Page',
          hash: log.settingHash,
          type: 'fallback'
        })
      } else if (log.type === 'pages') {
        await this.$store.dispatch('batchReleasePage', {
          comment: 'Fallback Pages',
          pages: log.pages,
          type: 'fallback'
        })
      }
      this.hideDialog()
    }
  }
}
</script>

<style src="./styles/editor-element-ui.scss"></style>
<style src="./styles/index.scss" scoped></style>
<style src="./styles/common.scss"></style>
