<template>
  <div class="editor-home-stage" :class="projectSetting.theme">
    <div class="doris is-edit" :style="dorisStyle">
      <div class="doris__screen" :style="dorisScreenStyle">
        <div class="doris__content" :class="[zoomIn ? 'out' : 'in']" @click.stop="zoom">
          <div class="doris__page-name">页面标题</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '../bus'
import { mapGetters } from 'vuex'
import { set } from 'lodash-es'

export default {
  props: ['user'],

  computed: {
    ...mapGetters(['projectSetting']),

    dorisStyle() {
      const { clientWidth, zoomIn } = this
      const style = {
        width: clientWidth + 'px'
      }
      if (zoomIn) {
        style.transform = `translate3d(0, 0, 0) scale(${(clientWidth - 440) /
          clientWidth})`
      }

      return style
    },

    dorisScreenStyle() {
      return {
        width: this.clientWidth - 220 + 'px'
      }
    }
  },

  data() {
    return {
      clientWidth: 0,
      zoomIn: false,
      syncing: false
    }
  },

  methods: {
    zoom() {
      this.zoomIn = !this.zoomIn
    },

    syncNavigator() {
      this.syncing = true
      this.$nextTick(_ => {
        // bind in catalog/index.vue
        bus.$emit('syncNavigator')
      })
    }
  },

  mounted() {
    bus.$on('syncDone', _ => {
      this.syncing = false
    })

    this.$el.parentNode.style.overflow = 'hidden'

    this.clientWidth = document.body.clientWidth

    window.addEventListener('resize', e => {
      clearTimeout(this.timer)
      this.timer = setTimeout(_ => {
        this.clientWidth = document.body.clientWidth
      }, 300)
    })
  },

  beforeDestroy() {
    set(this.$el, 'style.overflow', '')
  }
}
</script>

<style lang="scss">
.editor-home-stage {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  background: #171717;
  width: calc(100vw - 440px);

  .sync-button {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    padding: 7px 14px;
    background: #333;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    transition: 0.12s ease-out;

    .el-icon-refresh {
      font-size: 16px;
    }

    .text {
      padding-left: 5px;
    }

    &.loading {
      background: #666;

      .el-icon-refresh {
        animation: rotating 2s linear infinite reverse;
      }
    }

    &:hover {
      background: #444;
    }
  }

  .doris__content {
    &.in {
      cursor: zoom-out;
    }
    &.out {
      cursor: zoom-in;
    }
  }
}
</style>

