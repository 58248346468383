var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"x-table",attrs:{"x-slot-component":"true"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.getValueByPath(_vm.loadingKey)),expression:"getValueByPath(loadingKey)"}],ref:"elCompo",class:{'is-th-center': _vm.thAlignCenter},attrs:{"data":_vm.tableData,"height":_vm.height === 0 ? '' : _vm.height.toString(),"stripe":_vm.stripe,"border":_vm.border},on:{"selection-change":_vm.handleSelectionChange}},[(_vm.selectable)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),_vm._v(" "),_vm._l((_vm.columns),function(e,i){return _c('el-table-column',{key:i,attrs:{"label":e.label,"width":e.width || ''},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!e.isSlot)?_vm._t("default",[_vm._v("\n          "+_vm._s(_vm.get(scope.row, e.prop, '缺省') || '无数据')+"\n        ")]):_c('div',{staticClass:"table-slot",attrs:{"x-slot":"default","x-slot-flow":"row"}},[(
              _vm.placeholder &&
              !_vm.placeholder.targetUUID &&
              _vm.placeholder.slotParentUUID === _vm.componentData.uuid &&
              _vm.placeholder.tableSlotIndex === i + 1 + _vm.offset &&
              _vm.placeholder.slotName === 'default'
            )?_c('x-placeholder'):_vm._e(),_vm._v(" "),_vm._l((_vm.componentData._children.filter(function (e) { return e.tableSlotIndex === i + 1 + _vm.offset; })),function(item){return [(
                  _vm.placeholder &&
                  _vm.placeholder.targetUUID === item.uuid &&
                  _vm.placeholder.tableSlotIndex === i + 1 + _vm.offset &&
                  _vm.placeholder.position.includes('left')
                )?_c('x-placeholder',{key:item.uuid}):_vm._e(),_vm._v(" "),_c('x-component',{key:item.uuid,attrs:{"x-slot-component":"false","data":item,"in-table-slot":"","row-data":{data: scope.row, index: scope.$index},"uuid":item.uuid,"project":_vm.project}}),_vm._v(" "),(
                  _vm.placeholder &&
                  _vm.placeholder.targetUUID === item.uuid &&
                  _vm.placeholder.tableSlotIndex === i + 1 + _vm.offset &&
                  _vm.placeholder.position.includes('right')
                )?_c('x-placeholder',{key:item.uuid}):_vm._e()]})],2)]}}],null,true)})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }