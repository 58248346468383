<template>
  <div class="x-placeholder">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'XPlaceholder'
}
</script>

<style>
  .x-placeholder {
    border: 1px dashed #6EB1F5;
    border-radius: 3px;
    background: #EBF4FC;
    height: 100px;
    width: 100%;
  }
</style>
