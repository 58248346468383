<template>
  <div class="page-config">
    <!-- 页面配置 -->
    <config-box title="页面配置">
      <div class="config_item">
        <div class="config_item-label">页面标题</div>
        <el-input
          size="mini"
          :value="currentPage.configs.title"
          @input="changeConfig($event, 'title')">
        </el-input>
      </div>
      <div class="config_item">
        <div class="config_item-label">页面边距</div>
        <el-input-number
          size="mini"
          :value="currentPage.configs.padding"
          controls-position="right"
          :min="0"
          @input="changeConfig($event, 'padding')">
        </el-input-number>
      </div>
      <div class="config_item">
        <div class="config_item-label">页面背景色</div>
        <div class="config_item-content">
          <el-color-picker
            size="large"
            @active-change="changeConfig($event, 'background')"
            show-alpha
            :value="currentPage.configs.background"
            @input="changeConfig($event, 'background')"
          >
          </el-color-picker>
          <span>{{currentPage.configs.background || '无背景色'}}</span>
        </div>
      </div>
    </config-box>
  </div>
</template>

<script>
import ConfigBox from '@/pages/editor/components/config-box'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['currentPage'])
  },

  components: {
    ConfigBox
  },

  methods: {
    changeConfig(value, prop) {
      const configs = { ...this.currentPage.configs }
      /*
       * Element ColorPicker 的 onChange 回调和 onActiveChange 回调
       *  有个细微的区别。当点击「清空」时，这两个回调都会触发，但是
       *  一个参数是`null` 一个是 `'transparent'`
       *  这里统一设置成 `null`
       */
      if (prop === 'background' && value === 'transparent') {
        value = null
      }
      configs[prop] = value
      this.$store.dispatch('modifyPageConfigs', configs)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-config {
  padding: 10px;

  .iconfont:before {
    font-size: 16px;
    vertical-align: middle;
  }

  &_actions {
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }

}
.dialog-history /deep/ {
  .el-tabs {
    .el-tabs__nav {
      justify-content: center;
    }
    .el-tabs__item {
      width: 150px;
      flex: 0 1 auto;
    }
  }
}
</style>
