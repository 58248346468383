import { UI_SCHEMAS, EVENT_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { size, placeholder, max, min, label, labelWidth } = UI_SCHEMAS
const { bindKey, disabled, readonly } = DATA_SCHEMAS
const { handleChangeKey, handleBlurKey, handleFocusKey } = EVENT_SCHEMAS

export default {
  componentName: 'XInput',

  label: 'Input',

  props: {
    size: 'small',
    placeholder: '请输入',
    label: '条目标签',
    labelWidth: {
      labelWidth: '',
      labelWidthKey: '',
      isStatic: true
    }
  },

  notFlex: true,
  beTableSlot: true,
  validate: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        size,
        label,
        labelWidth,
        placeholder,
        max,
        min,

        prefixIcon: {
          name: '头部图标',
          widget: 'icons'
        },

        suffixIcon: {
          name: '尾部图标',
          widget: 'icons'
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: { bindKey, disabled, readonly }
    },

    events: {
      label: '事件绑定',
      content: { handleChangeKey, handleBlurKey, handleFocusKey }
    }
  }
}
