import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
// 样式
import '@/scss/index.scss'
import 'highlight.js/styles/color-brewer.css'
// 目前主要用到导航的图标，后期可以考虑去除
import 'font-awesome/css/font-awesome.css'

// 全局注册 filter 和 component
import '@/filters'
import '@/components'

import store from '@/store'
import DorisUI from '@/core/doris-ui'
import XComponent from '@editor/components/x-component'
import XPlaceholder from '@editor/components/x-placeholder'
import FallbackComponent from '@editor/components/fallback-component'

// 权限控制
import './permission'

// 组件库
Vue.component(XComponent.name, XComponent)
Vue.component(XPlaceholder.name, XPlaceholder)
Vue.component(FallbackComponent.name, FallbackComponent)
Vue.use(ElementUI, { size: 'medium' })
Vue.use(DorisUI)

// 统一全局组件容器
Vue.prototype.$components = {}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  template: '<App />',
  components: { App }
}).$mount('#app')
