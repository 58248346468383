<template>
  <div class="page-container">
    <main-header />
    <div class="page-profile">
      <div class="dark project-filter">
        <el-input
          v-model="filter"
          clearable
          prefix-icon="el-icon-search"
          placeholder="筛选项目（页面名称/页面 ID）"
        ></el-input>
        <el-button type="primary" class="search-btn" @click="searchProject" 
          >搜索</el-button
        >
      </div>
      <div class="container">
        <ul
          class="projects"
          v-if="projects.length"
          v-infinite-scroll="searchProjects"
        >
          <li
            class="project-wrapper"
            v-for="project in projects"
            :key="project.PAGEID"
            @click="handleProjectClick(project)"
          >
            <div class="project">
              <div class="cover">
                <img class="cover-pic" :src="project.pic" alt />
                <div class="cover-pic default-cover-pic">
                  <icon name="page" />
                </div>
                <div class="mask"></div>
              </div>
              <h3>
                {{ project.NAME }} - {{ project.PAGEID }}
                <el-dropdown
                  class="setting"
                  @click.native.stop
                  placement="top-end"
                  trigger="click"
                >
                  <span class="el-dropdown-link">
                    <icon name="setting" />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native.stop="showProjectSetting(project)"
                      >编辑项目</el-dropdown-item
                    >
                    <el-dropdown-item
                      style="color: #FF4E4E;"
                      @click.native="showDeleteDialog(project.PAGEID)"
                      >删除项目</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </h3>
            </div>
          </li>
        </ul>
        <div v-else-if="projectLoading" class="projects-loading">
          正在加载项目...
        </div>
        <div v-else class="no-projects">
          <span v-if="filter">没有找到与「{{ filter }}」相关的项目</span>
          <span v-else>
            你还没有项目，开始
            <a
              href="#"
              class="el-button--text"
              @click="createDialogVisible = true"
              >创建项目</a
            >
          </span>
        </div>
        <div v-if="finished && !projectLoading" class="projects-loading">
          已经没有更多了~
        </div>
      </div>
      <!-- 删除项目二次确认 -->
      <el-dialog
        class="dark"
        :visible.sync="dialogs.confirmDelete.visible"
        title="删除操作"
        width="400px"
        custom-class="dialog-delete"
      >
        确认删除该项目吗？项目一旦删除，将不可找回。
        <div slot="footer">
          <el-button @click="dialogs.confirmDelete.visible = false"
            >取消</el-button
          >
          <el-button type="primary" :loading="delLoading" @click="deleteProject"
            >删除</el-button
          >
        </div>
      </el-dialog>

      <!-- 新建项目 -->
      <el-dialog
        :visible.sync="createDialogVisible"
        title="新建项目"
        custom-class="dialog-create"
        class="dark"
        width="420px"
        @close="resetCreateForm"
      >
        <el-form
          :rules="createRules"
          size="small"
          ref="createForm"
          :model="createForm"
          label-position="top"
        >
          <el-form-item prop="name">
            <label slot="label">项目名</label>
            <el-input
              autofocus
              @keydown.native.enter.stop="enterToCreate"
              placeholder="请输入项目名"
              v-model="createForm.name"
            ></el-input>
          </el-form-item>
          <el-form-item prop="permission">
            <label slot="label">权限类型</label>
            <el-radio-group v-model="createForm.permission">
              <el-radio :label="0">不控制</el-radio>
              <el-radio :label="1">业务控制</el-radio>
              <el-radio :label="2">系统控制</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="请选择预设" class="project-preset_box">
            <div
              v-for="preset in presets"
              class="project-preset"
              :class="{ active: preset.name === targetPreset.name }"
              :key="preset.name"
              :data-name="preset.name"
              @click="selectPreset(preset)"
            ></div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="createDialogVisible = false"
            >取 消</el-button
          >
          <el-button
            size="small"
            :loading="createLoading"
            type="primary"
            @click="createProject"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <!-- 编辑项目 -->
      <el-dialog
        :visible.sync="editDialogVisible"
        title="修改项目"
        custom-class="dialog-edit"
        class="dark"
        width="420px"
      >
        <el-form
          :rules="editRules"
          size="small"
          ref="editForm"
          :model="editForm"
          label-position="top"
        >
          <el-form-item prop="name">
            <label slot="label">项目名</label>
            <el-input
              autofocus
              @keydown.native.enter.stop="enterToEidt"
              placeholder="请输入项目名"
              v-model="editForm.name"
            ></el-input>
          </el-form-item>
          <el-form-item prop="permission">
            <label slot="label">权限类型</label>
            <el-radio-group v-model="editForm.permission">
              <el-radio :label="0">不控制</el-radio>
              <el-radio :label="1">业务控制</el-radio>
              <el-radio :label="2">系统控制</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="editDialogVisible = false"
            >取 消</el-button
          >
          <el-button
            size="small"
            :loading="editLoading"
            type="primary"
            @click="editProject"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import API from '@/api'
import bus from '@/pages/editor/modules/bus'

export default {
  name: 'Profile',

  data() {
    return {
      // 全部项目列表
      projects: [],
      offset: 0,
      count: 20,
      finished: false,
      filter: '',
      projectLoading: true,
      dialogs: {
        setting: false,
        confirmDelete: {
          peojectId: '',
          visible: false
        }
      },
      newUser: {
        role: 'write',
        email: ''
      },
      transferer: '',

      createDialogVisible: false,
      createLoading: false,
      // 新建项目
      createForm: {
        name: '',
        permission: 1
      },
      createRules: {
        name: [{ required: true, message: '请填写项目名', trigger: 'blur' }]
      },
      editDialogVisible: false,
      editLoading: false,
      editForm: {
        name: '',
        permission: 1
      },
      editRules: {
        name: [{ required: true, message: '请填写项目名', trigger: 'blur' }]
      },
      targetPreset: {
        name: '空白项目'
      },
      presets: [
        {
          name: '空白项目'
        }
      ],
      delLoading: false
    }
  },

  methods: {
    enterToCreate(e) {
      e.preventDefault()
      this.createProject()
    },

    fetchUsers(queryString, callback) {
      return callback(Array(0))
    },

    showProjectSetting(project) {
      this.currentProject = project
      this.editDialogVisible = true
      this.editForm.name = project.NAME
      this.editForm.permission = project.ISAC
    },

    showDeleteDialog(id) {
      this.dialogs.confirmDelete.visible = true
      this.dialogs.confirmDelete.projectId = id
    },

    createProject() {
      this.$refs.createForm.validate(async valid => {
        if (valid) {
          const {
            name: presetName,
            projectName: sampleProjectName
          } = this.targetPreset

          this.createLoading = true
          const projectName = this.createForm.name
          const permissionType = this.createForm.permission

          try {
            if (presetName !== '空白项目') {
              // await this.$api.forkProject(sampleProjectName, projectName)
            } else {
              await API.createProject({
                name: projectName,
                permissionType
              })
            }

            this.createDialogVisible = false
            this.initSearchProjects()
            this.searchProjects()
          } catch (e) {}
          this.createLoading = false
        }
      })
    },

    handleProjectClick(project) {
      const projectUrl = this.$router.resolve({
        name: 'editor',
        params: { projectId: project.PAGEID }
      })

      window.open(projectUrl.href, '_blank')
    },

    deleteProject() {
      const projectId = this.dialogs.confirmDelete.projectId
      this.delLoading = true
      API.deleteProject(projectId)
        .then(() => {
          this.$message.success('成功删除项目')
          this.delLoading = false
          this.dialogs.confirmDelete.visible = false
          this.initSearchProjects()
          this.searchProjects()
        })
        .catch(() => {
          this.delLoading = false
          this.dialogs.confirmDelete.visible = false
        })
    },

    searchProject() {
      this.initSearchProjects()
      this.searchProjects()
    },

    initSearchProjects() {
      this.finished = false
      this.offset = 0
      this.projects = []
    },

    getFilterParams() {
      const params = {}
      if (this.filter.match(/^\d+$/)) {
        params.pageId = parseInt(this.filter, 10)
      } else {
        params.name = this.filter
      }
      return params
    },

    searchProjects() {
      if (this.finished) return
      const filterParams = this.getFilterParams()
      return API.searchProjects({
        offset: this.offset,
        count: this.count,
        ...filterParams
      })
        .then(res => {
          this.projects.push(...res.data)
          this.offset = this.projects.length
          if (res.data.length < this.count) {
            this.finished = true
          } else {
            this.finished = false
          }
          this.projectLoading = false
        })
        .catch(() => {
          this.projectLoading = false
        })
    },

    selectPreset(preset) {
      this.targetPreset = preset
    },

    resetCreateForm() {
      this.createForm = {
        name: '',
        permission: 1
      }
      this.targetPresetLink = {
        name: '空白项目'
      }
      this.createLoading = false
    },

    editProject() {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          this.editLoading = true
          const projectName = this.editForm.name
          const permissionType = this.editForm.permission

          try {
            await API.updateProject(this.currentProject.PAGEID, {
              name: projectName,
              permissionType
            })
            this.editDialogVisible = false
            // 前端生效
            this.currentProject.NAME = projectName
            this.currentProject.permissionType = permissionType
          } catch (e) {}
          this.editLoading = false
        }
      })
    }
  },

  created() {
    this.searchProjects()
    bus.$on('promptToCreateProject', _ => {
      this.createDialogVisible = true
    })
  }
}
</script>

<style lang="scss">
@import '@/scss/var.scss';

.page-container {
  min-height: 100vh;

  /deep/ {
    .el-loading-spinner {
      i,
      .el-loading-text {
        color: $primary;
      }
    }
  }
}

.project-preset {
  position: relative;
  width: 120px;
  height: 100px;
  margin-right: 20px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  border-radius: 3px;
  cursor: pointer;

  &.active {
    box-shadow: 0 0 0 2px $primary;
  }

  &::after {
    content: attr(data-name);
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    font-size: 12px;
    bottom: -5px;
    text-align: center;
    line-height: 2;
    transform: translateY(100%) scale(0.9);
  }

  &_box {
    .el-form-item__content {
      display: flex;
      flex-flow: row wrap;
    }
  }

  &_preview {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.page-profile {
  padding: 80px 50px 0;
  margin: 0 auto;
  width: 90%;

  .project-filter {
    display: flex;
    align-items: center;
    width: 420px;
    margin: auto;

    .search-btn {
      margin-left: 16px;
    }
  }

  .no-projects,
  .projects-loading {
    padding: 50px 0;
    text-align: center;
    color: #666;
  }

  &_actions {
    margin: 0 auto;
    padding: 30px 0 0;
    display: flex;
  }

  &_add {
    position: absolute;
    right: 120px;
    cursor: pointer;
    padding: 8px 20px 8px 15px;
    font-size: 14px;
    color: $primary;
    display: flex;
    border-radius: 2px;
    align-items: center;
    border: 1px solid $primary;

    .text {
      font-weight: bold;
    }

    [class*='icon'] {
      font-size: 18px;
      padding-right: 8px;
    }
  }
}

.actions {
  padding-left: 18px;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  li {
    float: left;
  }
}
.container {
  padding-top: 10px;
}
.projects {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}
.project-wrapper {
  padding: 15px;
  width: 25%;
}
.project {
  border-radius: 3px;
  border: 1px solid #dedede;
  cursor: pointer;

  .cover {
    position: relative;
    height: 160px;
    background-color: #f1f1f1;

    .cover-pic {
      transition: all 0.3s ease-out;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &_inner {
        width: 40px;
      }
    }
    .default-cover-pic {
      height: 100%;
      font-size: 50px;
      text-align: center;
      color: #76838f;

      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
      }
    }
    .mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 20px;
      color: #fff;
      background-color: #ffffff;
      opacity: 0;
      transition: opacity 0.3s ease-out;
      border-radius: 3px 3px 0 0;
    }

    &:hover {
      .cover-pic {
        transform: scale(1.1);
      }
      .mask {
        opacity: 0.2;
      }
    }
  }

  h3 {
    padding: 0 20px;
    height: 36px;
    line-height: 36px;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $primary;
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;
    background-color: rgba(255, 255, 255, 0.03);

    .setting {
      position: absolute;
      width: 40px;
      right: 0;
      top: 0;
      font-size: 20px;
      text-align: center;
      transition: 0.3s linear;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.dialog-permission {
  .transfer {
    padding-bottom: 30px;
    border-bottom: 1px solid #181c1e;

    h3 {
      padding-bottom: 15px;
      color: #848484;
      font-size: 15px;
    }

    & > div {
      display: flex;
      width: 500px;

      .el-autocomplete {
        margin-right: 20px;
        width: 100%;
      }
    }
  }
  .permission {
    padding-top: 30px;

    h3 {
      color: #848484;
    }

    .el-col {
      height: 250px;
      overflow: auto;

      &:first-child {
        border-right: 1px dashed #181c1e;
        padding-right: 20px;
      }
      &:last-child {
        padding-left: 20px;
      }
    }
    .el-autocomplete {
      width: 100%;
    }

    .roles {
      margin: 15px 0;
      .el-radio {
        width: 100px;
        padding-bottom: 10px;
      }

      /deep/.el-radio__label {
        width: 50px;
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px;
      }

      li span {
        font-size: 12px;
        font-weight: lighter;
      }
    }
  }

  .el-table {
    &,
    .el-table__expanded-cell {
      background-color: transparent;
    }

    & tr {
      background-color: transparent;
    }

    & td,
    & th.is-leaf {
      border-bottom: 1px solid #181c1e;
      color: #eee;
    }
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #181c1e;
  }

  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background-color: #181c1e;
  }
}

.dialog-delete {
  .btns {
    padding-top: 20px;
    text-align: right;
  }
}

@media only screen and (max-width: 1100px) {
  .project-wrapper {
    padding: 10px;
    width: 33.33%;
  }
}
@media only screen and (min-width: 1101px) {
  .project-wrapper {
    padding: 10px;
    width: 25%;
  }
}
@media only screen and (min-width: 1400px) {
  .project-wrapper {
    padding: 15px;
    width: 25%;
  }
}

@media only screen and (min-width: 1700px) {
  .project-wrapper {
    padding: 13px;
    width: 20%;
  }
}
</style>
