import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { API_HOST } from '@/api/constants'

// create an axios instance
const service = axios.create({
  // url = base url + request url
  baseURL: `${API_HOST}/saas/action`,
  // send cookies when cross-domain requests
  withCredentials: true,
  // request timeout
  timeout: 150000
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Token'] = ''
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error)
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if success is not true, it is judged as an error.
    const successFail = typeof res === 'object' && 'success' in res && !res.success
    const messageFail = typeof res === 'object' && 'message' in res && Object.keys(res).length === 1
    const needLoginFail = typeof res === 'object' && 'needlogin' in res && res.needlogin
    if (successFail || messageFail) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else if (needLoginFail)  {
      router.replace(`/login?redirect=${router.currentRoute.path}`)
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
