<template>
<div class="x-switch x-form-item">
  <div
    class="x-form-label"
    :style="{ width: this.genLabelWidth }">
    {{this.label}}
  </div>
  <el-switch
    :active-color="activeColor"
    :inactive-color="inactiveColor"
    :active-value="activeValue"
    :inactive-value="inactiveValue"
    :value="bindValue"
    @input="updateValue">
  </el-switch>
</div>
</template>

<script>
import ModelMixin from '../mixins/model-mixin'
import DisabledMixin from '../mixins/disabled-mixin'
import basicComponentMixin from '../mixins/basic-component.mixin'
import formMixin from '../mixins/form.mixin'

export default {
  name: 'XSwitch',

  mixins: [ModelMixin, DisabledMixin, basicComponentMixin, formMixin],

  props: {
    activeColor: String,
    inactiveColor: String,
    activeValue: {},
    inactiveValue: {},
    inTableSlot: Boolean,
    rowData: {
      type: Object,
      default: () => {
        return {
          data: {}
        }
      }
    },

    handleClickKey: String,
    handleClickInTableKey: String,
    project: Object
  },

  methods: {
    onClick(event) {
      if (this.inTableSlot) {
        this.handleClickInTableKey &&
          this.project.globalAPI[this.handleClickInTableKey](this.rowData, event)
      } else {
        this.handleClickKey && this.project.globalAPI[this.handleClickKey](event)
      }
    }
  }
}
</script>

<style lang="scss">
.x-button {
  display: inline-flex;

  .el-button span:empty {
    margin-left: 0;
  }

  + .x-button {
    margin-left: 10px;
  }
}
</style>
