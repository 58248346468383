<template>
<div class="x-button">
  <el-popover placement="top" width="200" trigger="manual" v-model="isShowPoper">
    <p>{{poperContent}}</p>
    <div style="text-align: right; margin-top: 10px;">
      <el-button
        size="mini"
        type="text"
        @click="handleCancelPopover"
      >取消</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="handleClickPopover"
      >确定</el-button>
    </div>
    <el-button
      slot="reference"
      :icon="icon" :round="isRound"
      :type="type" :size="size"
      :loading="isLoading"
      :disabled="isDisabled"
      @click="handleClickButton"
    >{{genText}}</el-button>
  </el-popover>
</div>
</template>

<script>
import exprEval from '@editor/utils/expression-eval'
import ModelMixin from '../mixins/model-mixin'
import DisabledMixin from '../mixins/disabled-mixin'
import basicComponentMixin from '../mixins/basic-component.mixin'

export default {
  name: 'XButton',

  mixins: [ModelMixin, DisabledMixin, basicComponentMixin],

  props: {
    text: Object,
    type: String,
    size: String,
    icon: String,
    isRound: Boolean,

    loadingKey: String,
    inTableSlot: Boolean,
    rowData: {
      type: Object,
      default: () => {
        return {
          data: {}
        }
      }
    },

    handleClickKey: String,
    handleClickInTableKey: String,
    tableRef: {
      type: Object,
      default: () => {
        return {
          data: {}
        }
      }
    },
    project: Object
  },

  computed: {
    genText() {
      const { text, rowData, inTableSlot } = this
      const { isStatic } = text
      return isStatic
        ? text.text
        : this._IS_EDIT_
          ? `$${text.textKey}`
          : exprEval(text.textKey, this.$store.state, rowData.data, inTableSlot)
    },

    isLoading() {
      return this.getValueByPath(this.loadingKey, false)
    }
  },

  data() {
    return {
      poperContent: '',
      isShowPoper: false
    }
  },

  methods: {
    handleCancelPopover() {
      this.isShowPoper = false
    },
    handleClickPopover(event) {
      this.onClick(event, true)
      this.isShowPoper = false
    },
    onClick(event, passPoper = false) {
      if (this.inTableSlot) {
        this.handleClickInTableKey &&
          this.project.globalAPI[this.handleClickInTableKey](this.rowData, event)
      } else {
        this.handleClickKey && this.project.globalAPI[this.handleClickKey](event, {
          $tableRef: this.tableRef,
          $clickButtonPopover: ({ comfirmContent }) => {
            if (comfirmContent && !passPoper) {
              this.isShowPoper = true
              this.poperContent = comfirmContent
              return false
            }
            return true
          }
        })
      }
    },
    handleClickButton(event) {
      this.onClick(event)
    }
  }
}
</script>

<style lang="scss">
.x-button {
  display: inline-flex;

  .el-button span:empty {
    margin-left: 0;
  }

  + .x-button {
    margin-left: 10px;
  }
}
</style>
