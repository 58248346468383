<template>
  <div class="screenfull">
    <i
      class="screenfull-icon"
      :class="isFullscreen ? 'el-icon-full-screen' : 'el-icon-full-screen'"
      @click="click"
    />
  </div>
</template>

<script>
import screenfull from 'screenfull'

export default {
  name: 'Screenfull',

  data() {
    return {
      isFullscreen: false
    }
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.destroy()
  },

  methods: {
    click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: '浏览器不支持全屏',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change)
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.screenfull {
  cursor: pointer;
}
</style>
