import { UI_SCHEMAS, EVENT_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { size, placeholder, label, clearable, filterable } = UI_SCHEMAS
const { disabled, bindKey, optionsKey } = DATA_SCHEMAS
const { handleChangeKey } = EVENT_SCHEMAS

export default {
  componentName: 'XCascader',

  label: 'Cascader',

  props: {
    placeholder: '请选择',
    popperClass: '',
    clearable: true,
    expandTrigger: 'click',
    size: 'small',
    debounce: 300
  },

  beTableSlot: false,

  configs: {
    ui: {
      label: '视觉',
      content: {
        size,
        label,
        placeholder,
        popperClass: {
          name: '自定义浮层类名',
          type: 'string',
          widget: 'input'
        },
        filterable,
        clearable,
        debounce: {
          name: '搜索关键词输入的去抖延迟',
          widget: 'number'
        },
        expandTrigger: {
          name: '次级菜单的展开方式',
          widget: 'radio',
          values: [
            {k: 'click', v: 'click'},
            {k: 'hover', v: 'hover'}
          ]
        },
        showAllLevels: {
          name: '显示选中值的完整路径',
          widget: 'switch',
          type: 'boolean'
        }
      }
    },
    data: {
      label: '数据逻辑',
      content: {
        disabled,
        bindKey,
        optionsKey,
        changeOnSelect: {
          name: '是否允许选择任意一级的选项',
          widget: 'switch',
          type: 'boolean'
        }
      }
    },
    events: {
      label: '事件绑定',
      content: {
        handleChangeKey,
        handleActiveItemChangeKey: {
          name: 'onActiveItemChange',
          argumentsMapping: [
            {
              name: 'selectedResult',
              description: '各父级选项组成的数组'
            }
          ]
        },
        handleBeforeFilterKey: {
          name: 'beforeFilter',
          argumentsMapping: [
            {
              name: 'input',
              description: '筛选之前的钩子，参数为输入的值'
            }
          ]
        }
      }
    }
  }
}
