import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, removeToken } from '@/utils/auth'
import router from './router'
import store from './store'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect']

router.beforeEach(async (to, from, next) => {
  const isOpend = !!store.state.visitedViews.filter(view => view.path === to.path).length
  if (!isOpend) {
    NProgress.start()
  }

  document.title = to.meta && to.meta.title ? to.meta.title : 'doris'

  const isLogined = () => !!store.state.user.userid

  if (getToken()) {
    // 登录页自动跳转
    if (to.path === '/login') {
      next('/home')
      NProgress.done()
    } else {
      if (isLogined()) {
        next()
      } else {
        try {
          await store.dispatch('getUserInfo')
          // should login
          if (!isLogined()) {
            throw new Error('get user info error')
          }
          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('generateRoutes')
          // dynamically add accessible routes
          router.addRoutes(accessRoutes)

          // 异步初始化
          store.dispatch('asyncInitProject')

          next({ ...to, replace: true })
        } catch (error) {
          removeToken()
          Message.error(error || '出错了')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
