var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-col',{staticClass:"x-col",attrs:{"span":_vm.span,"x-slot-component":""}},[_c('div',{style:(_vm.layoutStyle),attrs:{"x-slot":"default"}},[(_vm.placeholder &&
            !_vm.placeholder.targetUUID &&
            _vm.placeholder.slotParentUUID === _vm.componentData.uuid &&
            _vm.placeholder.slotName === 'default')?_c('x-placeholder'):_vm._e(),_vm._v(" "),_vm._l((_vm.componentData._children),function(item){return [(_vm.placeholder &&
              _vm.placeholder.targetUUID === item.uuid &&
              _vm.placeholder.position.includes('top'))?_c('x-placeholder',{key:item.uuid}):_vm._e(),_vm._v(" "),_c('x-component',{key:item.uuid,attrs:{"x-slot-component":"false","data":item,"uuid":item.uuid,"project":_vm.project}}),_vm._v(" "),(_vm.placeholder &&
              _vm.placeholder.targetUUID === item.uuid &&
              _vm.placeholder.position.includes('bottom'))?_c('x-placeholder',{key:item.uuid}):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }