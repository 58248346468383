import axios from 'axios'

// create an axios instance
const service = axios.create({
  // send cookies when cross-domain requests
  withCredentials: true,
  // request timeout
  timeout: 150000
})

export default service
