import { get } from 'lodash-es'

export default {
  props: {
    optionsKey: String,
    optionsValueKey: String,
    optionsLabelKey: String
  },

  data() {
    return {
      placeholderOptions: [
        { value: '', label: '备选项1' },
        { value: '1', label: '备选项2' }
      ]
    }
  },

  computed: {
    options() {
      const { _IS_EDIT_, optionsKey, placeholderOptions } = this
      return _IS_EDIT_
        ? placeholderOptions
        : get(this.$store.state, optionsKey, [])
    }
  }
}
