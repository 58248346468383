<template>
  <svg :viewBox="svgMaps[name].viewBox" class="svg">
    <use :href="`#${svgMaps[name].id}`"></use>
  </svg>
</template>

<script>
import svgMaps from './svg'

export default {
  name: 'Icon',

  props: {
    name: {
      type: String
    }
  },

  data() {
    return {
      svgMaps
    }
  }
}
</script>

<style lang="scss" scoped>
.svg {
  width: 1em;
  fill: currentColor;
}
</style>
