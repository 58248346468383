<template>
  <div id="render-container-wrapper" class="render-container-wrapper">
    <breadcrumb v-show="iframeHistory.length" />
    <div v-if="isDorisPage" v-show="!iframeHistory.length" class="render-container">
      <render-page :project-id="projectId" />
    </div>
    <iframe-container v-else v-show="!iframeHistory.length" :src="iframeSrc" />
    <template v-for="({ url, pageId, query }, index) in iframeHistory">
      <iframe-container
        v-if="url"
        :key="index"
        v-show="index === iframeHistory.length - 1"
        :src="getAbsoluteUrl(url)"
      />
      <render-page
        v-else-if="pageId"
        :key="index"
        class="render-container"
        :query="query"
        :project-id="pageId"
      />
    </template>
  </div>
</template>

<script>
import { IframeContainer, RenderPage, Breadcrumb } from './components'
import { API_HOST } from '@/api/constants'
import './compatible'

export default {
  name: 'Render',

  props: {
    view: {
      type: Object,
      required: true
    }
  },

  components: {
    Breadcrumb,
    IframeContainer,
    RenderPage
  },

  computed: {
    isDorisPage() {
      // 页面类型为 3 则是新版页面
      return this.view.page_type === 3
    },
    menuId() {
      return this.view.id
    },
    projectId() {
      return this.view.page_id
    },
    iframeHistory() {
      return this.view.history || []
    },
    iframeSrc() {
      if (this.isDorisPage) return
      if (this.view.type === 1) {
        return `${API_HOST}/saas/action/webpage/getpage?pageid=${this.projectId}`
      }
      const url = this.view.url
      return this.getAbsoluteUrl(url)
    }
  },

  methods: {
    getAbsoluteUrl(url) {
      // 如果已经有 host 就直接使用
      if (url.match(/\/\//)) {
        return url
      } else if (url.match(/^\//)) {
        return `${API_HOST}${url}`
      }
      return `${API_HOST}/${url}`
    }
  }
}
</script>

<style lang="scss" scoped>
.render-container-wrapper {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  background-color: #f0f2f5;
}

.render-container {
  position: relative;
  flex: 1;
  height: 100%;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: #ffffff;
}
</style>
