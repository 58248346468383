<template>
<div class="x-description-list">
  <h4 class="title" v-if="genTitle">{{genTitle}}</h4>
  <el-row :gutter="gutter" v-loading="getValueByPath(loadingKey)">
    <el-col
      class="item"
      v-for="(item, index) in bindValue"
      :key="index"
      :span="24 / columns">
      <span class="term">{{item[nameKey]}}</span><span class="detail">{{item[contentKey]}}</span>
    </el-col>
  </el-row>
</div>
</template>

<script>
import exprEval from '@editor/utils/expression-eval'
import ModelMixin from '../mixins/model-mixin'
import DisabledMixin from '../mixins/disabled-mixin'
import basicComponentMixin from '../mixins/basic-component.mixin'

export default {
  name: 'XDescriptionList',

  mixins: [ModelMixin, DisabledMixin, basicComponentMixin],

  props: {
    columns: {
      type: Number,
      default: 3
    },
    gutter: {
      type: Number,
      default: 20
    },
    nameKey: {
      type: String,
      default: 'name'
    },
    contentKey: {
      type: String,
      default: 'content'
    },
    title: Object,
    loadingKey: String
  },

  computed: {
    genTitle() {
      const { title } = this
      const { isStatic } = title

      return isStatic
        ? title.title
        : this._IS_EDIT_
          ? `$${title.titleKey}`
          : exprEval(title.titleKey, this.$store.state)
    },
    isLoading() {
      return this.getValueByPath(this.loadingKey, false)
    },
    defaultModel() {
      return [
        {
          name: '项目一',
          content: '项目描述'
        },
        {
          name: '项目二',
          content: '项目描述'
        },
        {
          name: '项目三',
          content: '项目描述'
        },
        {
          name: '项目四',
          content: '项目描述'
        },
        {
          name: '项目五',
          content: '项目描述'
        },
        {
          name: '项目六',
          content: '项目描述'
        }
      ]
    }
  },

  methods: {
  }
}
</script>

<style lang="scss">
  .x-description-list {
    .item {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .term,
    .detail {
      line-height: 22px;
      padding-bottom: 16px;
      font-size: 14px;
      display: inline-block;
    }
    .term {
      color: rgba(0,0,0,.85);
      margin-right: 8px;

      &:after {
        content: ":";
        margin: 0 8px 0 2px;
        position: relative;
        top: -.5px;
      }
    }
    .detail {
      color: rgba(0,0,0,.65);
    }

    & + .x-description-list {
      margin-top: 20px;
      padding-top: 30px;
      border-top: 1px solid #e8e8e8;
    }
  }
</style>
