<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { get } from 'lodash-es'
import API from '@/api'
import { deepClone } from '@/utils'
import basicComponentMixin from '../mixins/basic-component.mixin'
import ModelMixin from '../mixins/model-mixin'
import IdMixin from '../mixins/id-mixin'
import BaseTableFilter from './base-table-filter'

const alignMap = {
  '0': 'left',
  '1': 'left',
  '2': 'center',
  '3': 'right'
}

export default {
  name: 'XBaseTable',

  mixins: [ModelMixin, IdMixin, basicComponentMixin],

  props: {
    filter: Boolean,
    height: {
      type: Number,
      default: 0
    },
    showIndex: Boolean,
    stripe: Boolean,
    thAlignCenter: Boolean,
    border: Boolean,

    dataSource: Number,
    project: Object
  },

  components: {
    BaseTableFilter
  },

  data() {
    return {
      query: {
        count: 20,
        offset: 0
      },
      // 总条数
      total: undefined,
      searchLoading: false,
      columns: [],
      tableData: [],
      multipleSelection: [],
      clickButtonPopover: {
        content: '',
        scope: null
      }
    }
  },

  computed: {
    ...mapState([
      'projectDictionary'
    ]),
    projectId() {
      return this.project.projectId
    },
    projectDataSources() {
      return this.project.dataSources
    },
    currentPage() {
      return Math.floor(this.query.offset / this.query.count) + 1
    },
    functionButtonVisible() {
      return this._IS_EDIT_ || (
        this.componentData._children &&
        this.componentData._children.buttons &&
        this.componentData._children.buttons.length > 0
      )
    },
  },

  mounted() {
    this.initBaseTable()
  },

  methods: {
    // 先处理字典数据
    transformDic(data, dic) {
      if ((!data && data !== 0) || !dic) return data
      const matched = this.projectDictionary[dic].data.filter(v => v.VALUE === data + '')
      return matched.length ? matched[0].TEXT : data
    },
    // 映射不同的数据显示类型
    transformType(data, type) {
      if (!data && data !== 0) return data

      const dataTypeMap = {
        Date(value) {
          const matched = value.match(/\d+-\d+-\d+/)
          return matched ? matched[0] : value
        },
        Time(value) {
          const matched = value.match(/\d+:\d+:\d+/)
          return matched ? matched[0] : value
        },
        DateTime(value) {
          return `${dataTypeMap.Date(value)} ${dataTypeMap.Time(value)}`
        }
      }

      if (dataTypeMap[type]) {
        return dataTypeMap[type](data)
      }

      return data
    },

    // 处理显示值函数
    transformDisplay(displayValue, data, column, scope) {
      return this.handleFunctionEval(displayValue, data, column, scope)
    },

    // 处理默认值
    transformDefaultValue(data, defaultValue) {
      if (data === '' && defaultValue) {
        // 首先尝试运行代码，不行直接返回值
        try {
          // eslint-disable-next-line
          return new Function(`return ${defaultValue}`)()
        } catch (e) {
          return defaultValue
        }
      }
      return data
    },

    getAlign(column) {
      // 数字默认靠右对齐
      if (['Integer', 'Long', 'Short', 'Float', 'Double', 'Decimal'].indexOf(column.TYPE) > -1 && !column.ALIGN) {
        return alignMap['3']
      }
      return alignMap[column.ALIGN]
    },

    getValue(scope, column, dv = '') {
      const { row } = scope
      const path = column.NAME
      const type = column.TYPE
      const dic = column.LOOKUP_DATA
      const transformedDicValue = this.transformDic(get(row, path, dv), dic)
      const transformedTypeValue = this.transformType(transformedDicValue, type)
      const transformedDefaultValue = this.transformDefaultValue(
        transformedTypeValue,
        column.DEFAULT_VALUE
      )
      const transformedDisplayValue = this.transformDisplay(
        column.DISPLAYVALUE,
        transformedDefaultValue,
        column,
        scope
      )
      return transformedDisplayValue
    },

    initBaseTable() {
      if (this.dataSource) {
        const originprojectDataSources = deepClone(this.projectDataSources)
        const choosedSource = originprojectDataSources.filter(
          dataSource => dataSource.TABLEID === this.dataSource
        )
        if (!choosedSource) {
          return
        }

        const dataSource = choosedSource[0]
        // 过滤 EDITOR 没有值的列（代表不显示）
        const columns = dataSource.columns.filter(column => column.EDITOR)
        this.columns = []
        this.multipleSelection = []

        // 处理多级表头
        columns.forEach(column => {
          let isFind = false
          if (column.GTITLE) {
            this.columns.forEach(v => {
              if (v.GTITLE === column.GTITLE) {
                isFind = true
                if (v.children) {
                  v.children.push(column)
                } else {
                  v.children = [v, column]
                }
              }
            })
          }

          if (!isFind) {
            this.columns.push(column)
          }
        })

        // 将数组转为字符串
        const query = {}
        Object.keys(this.query).forEach(key => {
          const sourceValue = this.query[key]
          query[key] = Array.isArray(sourceValue) ? sourceValue.join(',') : sourceValue
        })

        return API.getTable({
          name: dataSource.NAME,
          projectId: this.projectId,
          query
        }).then(res => {
          this.tableData = res.data
          // 追加汇总行
          if (this.tableData.length > 0) {
            this.tableData.push(...res.footerRows)
          }
          // 计算总条数
          if (res.total) {
            this.total = res.total
          } else if (res.data.length < this.query.count) {
            this.total = this.query.offset + res.data.length
          }
        })
      }
    },

    reloadTable() {
      this.initBaseTable()
    },

    handleSearch(value) {
      this.query = value
      this.searchLoading = true
      this.initBaseTable().then(() => {
        this.searchLoading = false
      })
    },

    handleSizeChange(val) {
      this.query.count = val
      // 调整尺寸需要从第一页开始重新获取
      this.query.offset = 0
      this.handleSearch(this.query)
    },

    handleCurrentChange(pageIndex) {
      this.query.offset = (pageIndex - 1) * this.query.count
      this.handleSearch(this.query)
    },

    // 后端排序
    handleSortChange({ prop, order }) {
      if (!order) {
        delete this.query.orderby
        return
      }
      if (order === 'ascending') {
        this.query.orderby = `${prop} asc`
      } else if (order === 'descending') {
        this.query.orderby = `${prop} desc`
      }

      // 排序需要从第一页开始重新获取
      this.query.offset = 0
      this.handleSearch(this.query)
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    clearMultipleSelection() {
      this.$refs.elCompo.clearSelection()
      this.multipleSelection = []
    },

    handleFunctionEval(evalBody, value, column, scope) {
      const row = scope.row
      if (!evalBody) {
        return value
      }
      const fnBody = `with(this){ return ${evalBody} }`
      /* eslint-disable no-new-func */
      const fn = new Function(fnBody)
      const proxyStore = {
        ...this.$store,
        dispatch: (name, args = {}) => {
          return this.$store.dispatch(name, {
            ...args,
            $project: this.project,
            $tableRef: this.$refs.elCompo,
            $clickButtonPopover: ({ comfirmContent }) => {
              if (comfirmContent && this.isShowPopover(scope)) {
                this.clickButtonPopover.content = comfirmContent
                return false
              }
              return true
            }
          })
        }
      }
      let result
      try {
        result = fn.call(proxyStore)
      } catch (e) {
        result = evalBody
      }
      // 如果是函数，传参调用
      if (typeof result === 'function') {
        result = result.call(proxyStore, {
          value,
          column,
          row,
          params: this.query,
          pageId: this.project.projectId,
          project: this.project
        })
      }
      return result
    },

    isShowPopover(scope) {
      if (!this.clickButtonPopover.scope || !this.clickButtonPopover.scope.column) return false
      return (scope.column.property === this.clickButtonPopover.scope.column.property) &&
        (scope.$index === this.clickButtonPopover.scope.$index)
    }
  },

  watch: {
    dataSource() {
      this.initBaseTable()
    }
  },

  render() {
    const renderFilter = ({ visible }) => {
      return <base-table-filter
        visible={visible}
        query={this.query}
        loading={this.searchLoading}
        tableRef={this.$refs.elCompo}
        project={this.project}
        on-search={this.handleSearch}
      />
    }

    const renderFunctionButtonChildrenPlaceholder = ({ name, position1, position2 }) => {
      const RenderButtons = this.componentData._children &&
        this.componentData._children[name] &&
        this.componentData._children[name].map(item => {
          return (
            <div class="x-base-table_buttons_slot">
              {
                this.placeholder &&
                this.placeholder.targetUUID === item.uuid &&
                this.placeholder.position.includes(position1) &&
                <x-placeholder key={item.uuid}></x-placeholder>
              }
              <x-component
                x-slot-component
                key={item.uuid}
                data={item}
                uuid={item.uuid}
                project={this.project}
                tableRef={this.$refs.elCompo}
              >
              </x-component>
              {
                this.placeholder &&
                this.placeholder.targetUUID === item.uuid &&
                this.placeholder.position.includes(position2) &&
                <x-placeholder key={item.uuid}></x-placeholder>
              }
            </div>
          )
        })
      return RenderButtons
    }

    const renderFunctionButtonSlot = ({ name, flow }) => {
      return (
        <div class="x-base-table_buttons x-base-table_slot" x-slot={name} x-slot-flow={flow}>
          {
            this.placeholder &&
            !this.placeholder.targetUUID &&
            this.placeholder.slotParentUUID === this.componentData.uuid &&
            this.placeholder.slotName === name &&
            <x-placeholder></x-placeholder>
          }
          {  renderFunctionButtonChildrenPlaceholder({ name, position1: 'left', position2: 'right' }) }
        </div>
      )
    }

    const renderFunctionButton = () => {
      return (
        <div class="function-button-wrap">
          <div class="function-select-wrapper">
            <div class="function-select-info">
              <i class="select-icon el-icon-info" /> 已选择
              <span
                class="select-number"
              >{this.multipleSelection.length}</span>项
            </div>
            <el-button class="function-select-clear" type="text" on-click={this.clearMultipleSelection}>清除</el-button>
          </div>
          { renderFunctionButtonSlot({ name: 'buttons', flow: 'row' }) }
        </div>
      )
    }

    const renderPagination = () => {
      return <el-pagination
        current-page={this.currentPage}
        page-sizes={[10, 20, 50, 100]}
        page-size={this.query.count}
        total={this.total}
        layout="total, sizes, prev, pager, next, jumper"
        on-size-change={this.handleSizeChange}
        on-current-change={this.handleCurrentChange}
      ></el-pagination>
    }

    const renderIndexColumn = () => {
      return <el-table-column
          type="index"
          width="50"
          label="序列"
          fixed={true}
          index={this.query.offset + 1}
        ></el-table-column>
    }

    const renderSelectionColumn = () => {
      return <el-table-column type="selection" width="40" align="center"></el-table-column>
    }

    const renderEditableCell = (scope, column) => {
      const rowValue = this.getValue(scope, column)

      // 事件处理
      const handleClick = () => {
        this.handleFunctionEval(
          column.ONCLICK,
          rowValue,
          column,
          scope
        )
      }

      const handleOpenPopover = () => {
        this.clickButtonPopover.scope = scope
        handleClick()
      }

      const handleCancelPopover = () => {
        this.clickButtonPopover.scope = null
      }

      const handleClickPopover = () => {
        handleCancelPopover()
        handleClick()
      }

      // 处理 popover 弹框
      const needPopover = () => {
        // 含有 comfirmContent 或者 deleteAction 字段就会启动二次确认弹框
        if (column.ONCLICK && column.ONCLICK.match(/(comfimContent)|(deleteAction)/)) {
          return true
        }
        return false
      }

      const editorCellMap = {
        span: () => {
          return <span>{ rowValue }</span>
        },
        link: () => {
          const isNeedPopover = needPopover(column)
          // 支持语法: {{disable: 删除}}
          const matchedValue = typeof rowValue === 'string' && rowValue.match(/^{{(.+)}}$/)
          let displayValue = rowValue
          let disabledButton = false
          if (matchedValue) {
            const propsKey = matchedValue[1].split(':')[0].trim()
            if (propsKey === 'disabled') {
              disabledButton = true
            }
            displayValue = matchedValue[1].split(':')[1].trim()
          }
          if (!isNeedPopover) {
            return <el-button
                slot="reference"
                type="text"
                disabled={disabledButton}
                on-click={handleClick}
              >{ displayValue }</el-button>
          }
          return  <el-popover placement="top" width="200" trigger="manual" value={this.isShowPopover(scope)}>
            <p>{  this.clickButtonPopover.content }</p>
            <div style="text-align: right; margin-top: 10px;">
              <el-button
                size="mini"
                type="text"
                on-click={handleCancelPopover}
              >取消</el-button>
              <el-button
                type="primary"
                size="mini"
                on-click={handleClickPopover}
              >确定</el-button>
            </div>
            <el-button
              slot="reference"
              type="text"
              disabled={disabledButton}
              on-click={handleOpenPopover}
            >{ displayValue }</el-button>
            </el-popover>
        },
        image: () => {
          return <el-image
              src={rowValue}
              fit="fit"
            />
        },
        default: () => {
          return rowValue
        }
      }

      if (editorCellMap[column.EDITOR]) {
        return editorCellMap[column.EDITOR]()
      }
      return editorCellMap.default()
    }

    const renderCell = (scope, column) => {
      const rowValue = this.getValue(scope, column)

      // 可编辑
      if (column.EDITABLE) {
        return renderEditableCell(scope, column)
      }
      return rowValue
    }

    const renderChildColumn = (column, index, slotScope) => {
      return <el-table-column
        key={`child-${index}`}
        label={column.TITLE}
        prop={column.NAME}
        min-width={column.WIDTH}
        align={this.getAlign(column)}
        fixed={!!column.ISFROZEN}
        sortable={!!column.ORDERABLE}
        {...slotScope}
      >
      </el-table-column>
    }

    const renderTableColumn = (column, index) => {
      const slotScope = {
        scopedSlots: {
          default(scope) {
            return renderCell(scope, column)
          },
        },
      }

      return <el-table-column
        key={index}
        label={(column.GTITLE && column.children) ? column.GTITLE : column.TITLE}
        prop={column.NAME}
        min-width={column.WIDTH}
        align={this.getAlign(column)}
        fixed={!!column.ISFROZEN}
        sortable={!!column.ORDERABLE}
        {...slotScope}
      >
        { column.children
          && column.children.map(
            (childColumn, index) => renderChildColumn(childColumn, index, slotScope)
          )
        }
      </el-table-column>
    }

    const renderTable = () => {
      return <el-table
        ref="elCompo"
        data={this.tableData}
        height={this.height === 0 ? null : this.height.toString()}
        max-height={1500}
        stripe={this.stripe}
        border={this.border}
        header-cell-style={{background: '#f0f5f7'}}
        highlight-current-row={true}
        class={{'is-th-center': this.thAlignCenter}}
        on-selection-change={this.handleSelectionChange}
      >
        {
          this.columns.length > 0 &&
          this.componentData._children &&
          this.componentData._children.buttons &&
          this.componentData._children.buttons.length > 0 &&
          renderSelectionColumn()
        }
        {
          this.columns.length > 0 &&
          this.showIndex &&
          renderIndexColumn()
        }
        { this.columns.map((column, index) => renderTableColumn(column, index)) }
      </el-table>
    }

    return (
      <div class="x-base-table-wrapper">
        { renderFilter({ visible: this.filter }) }
        {
          this.functionButtonVisible &&
          renderFunctionButton()
        }
        <div class="x-base-table" x-slot-component="true">
          { renderTable() }
        </div>
        { renderPagination() }
      </div>
    )
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/var.scss';

.x-base-table {
  padding-bottom: 20px;

  .is-th-center {
    .el-table__header {
      th {
        text-align: center;
      }
    }
  }

  &_buttons {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &_slot {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    /* 拖动位置 */
    &::after {
      content: '';
      position: absolute;
      top: -20px;
      bottom: -100px;
      left: 0;
      right: 0;
    }
  }
}

.function-button-wrap {
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  .function-select-wrapper {
    display: flex;
    align-items: center;
  }

  .function-select-clear {
    margin: 0 24px 0 16px;
  }

  .function-select-info {
    display: flex;
    align-items: center;
    color: #303133;
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid $primary;
    background-color: #e7f8ff;

    .select-icon {
      font-size: 16px;
      color: $primary;
      margin-right: 8px;
    }

    .select-number {
      color: $primary;
      width: 24px;
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
.editor {
  .x-base-table {
    &_slot {
      border: 1px dashed #ddd;
      min-height: 40px;
    }

    &_buttons {
      flex: 1;
      border: 1px dashed #ddd;

      .x-placeholder {
        height: 100%;
        min-height: 35px;
        min-width: 60px;
        border: none;
      }
    }
  }
}
</style>
