import api from '@/api'
import {
  PAGE_TEMPLATE,
  FUNC_TEMPLATE,
  SETTING_TEMPLATE,
  PROJECT_CONSTANTS_TEMPLATE,
  PROJECT_URL_MAP_TEMPLATE
} from '@/pages/const/template'

export default class Project {
  constructor(id) {
    const DEFAULT_OPTIONS = {
      projectId: id,
      projectName: '',
      permissionType: 1,
      tree: [],
      dataSources: [],
      params: [],
      setting: new SETTING_TEMPLATE(),
      constants: new PROJECT_CONSTANTS_TEMPLATE(),
      urlMap: new PROJECT_URL_MAP_TEMPLATE()
    }
    Object.assign(this, DEFAULT_OPTIONS)
  }

  addCatalog(name) {
    const data = {
      name,
      nodeKey: Date.now(),
      type: 'folder',
      children: []
    }

    return Promise.resolve(data)
  }

  // 添加页面
  addPage(path, pagenName) {
    const pagePath = `${path}/${pagenName}`
    const template = new PAGE_TEMPLATE({ name: pagenName, path: pagePath })

    return {
      request: Promise.resolve({}),
      pageContent: template,
      pageId: template.id
    }
  }

  generateFunctionObject(options) {
    return new FUNC_TEMPLATE(options)
  }
}
