<template>
  <div :class="sidebar.opened ? 'open-sidebar' : 'hide-sidebar'" class="app-wrapper">
    <sidebar class="sidebar-container" />
    <div :class="{ hasTagsView: needTagsView }" class="main-container">
      <div :class="{ 'fixed-app-header': fixedHeader }">
        <navbar />
        <div class="sub-main-container">
          <tags-view v-if="needTagsView" class="tags-view-container" />
          <extra-action class="extra-action-container" />
        </div>
      </div>
      <app-main />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import * as mutationTypes from '@/store/mutation-types'
import { getRouteByMenuId } from '@/utils/menuToRoutes'
import {
  AppMain,
  Navbar,
  Sidebar,
  TagsView,
  ExtraAction
} from './components'

export default {
  name: 'Home',

  components: {
    AppMain,
    Navbar,
    Sidebar,
    TagsView,
    ExtraAction
  },

  computed: {
    ...mapState(['sidebar', 'needTagsView', 'fixedHeader'])
  },

  created() {
    // 设置生产环境
    this.$store.commit(mutationTypes.SET_IS_PRODUCTION, true)
    this.initRoute()
  },

  methods: {
    handleClickOutside() {
      this.$store.dispatch('closeSideBar')
    },
    initRoute() {
      const { id: menuId } = this.$route.params
      this.route = getRouteByMenuId(Number(menuId))
      this.$store.commit(mutationTypes.SET_RENDER_ROUTE, this.route)
    }
  },

  watch: {
    $route() {
      this.initRoute()
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/var.scss';
// when menu collapsed
.el-menu--vertical {
  & > .el-menu {
    .menu-icon {
      margin-right: 16px;
    }
  }

  .nest-menu .el-submenu > .el-submenu__title,
  .el-menu-item {
    height: 44px;
    line-height: 44px;

    &:hover {
      // you can use $subMenuHover
      background-color: $menuHover !important;
    }
    i {
      color: currentColor;
    }
  }

  // the scroll bar appears when the subMenu is too long
  > .el-menu--popup {
    max-height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar-track-piece {
      background: #d3dce6;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #99a9bf;
      border-radius: 20px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/scss/var.scss';

.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: $sideBarWidth;
  position: relative;
}

.fixed-app-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hide-sidebar .fixed-app-header {
  width: calc(100% - 54px);
}

.sidebar-container {
  transition: width 0.28s;
  width: $sideBarWidth;
  background-color: $menuBg;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;

  &.has-logo {
    /deep/ .el-scrollbar {
      height: calc(100% - 50px);
    }
  }

  /deep/ {
    .scrollbar-wrapper {
      overflow-x: hidden;
    }

    .el-scrollbar__bar.is-vertical {
      right: 0px;
    }

    .el-scrollbar {
      height: 100%;
    }

    .is-horizontal {
      display: none;
    }

    a {
      display: inline-block;
      width: 100%;
      overflow: hidden;
    }

    .menu-icon {
      display: inline-block;
      width: 16px;
      text-align: center;
      margin-right: 16px;
    }

    .el-menu {
      border: none;
      height: 100%;
      width: 100%;
    }

    // menu hover
    .submenu-title-noDropdown,
    .el-submenu__title {
      height: 44px;
      line-height: 44px;

      &:hover {
        background-color: $menuHover !important;
      }

      i {
        display: block;
        color: currentColor;
      }
    }

    & .el-menu-item {
      height: 44px;
      line-height: 44px;
    }

    & .nest-menu .el-submenu > .el-submenu__title,
    & .el-submenu .el-menu-item {
      height: 44px;
      line-height: 44px;
      min-width: $sideBarWidth;
      background-color: $subMenuBg !important;

      &:hover {
        background-color: $subMenuHover !important;
      }
    }

    .el-menu--collapse .el-menu .el-submenu {
      min-width: $sideBarWidth;
    }
  }
}

.hide-sidebar {
  .sidebar-container {
    width: 54px;
  }

  .main-container {
    margin-left: 54px;
  }

  /deep/ {
    .submenu-title-noDropdown {
      padding: 0;
      position: relative;

      .el-tooltip {
        padding: 0 !important;

        .menu-icon {
          margin-left: 18px;
        }
      }
    }

    .el-submenu {
      overflow: hidden;

      & > .el-submenu__title {
        height: 44px;
        padding: 0 !important;

        .menu-icon {
          margin-left: 18px;
        }

        .el-submenu__icon-arrow {
          display: none;
        }
      }
    }

    .el-menu--collapse {
      .el-submenu {
        & > .el-submenu__title {
          height: 44px;
          & > span {
            height: 0;
            width: 0;
            overflow: hidden;
            visibility: hidden;
            display: inline-block;
          }
        }
      }
    }
  }
}

.sub-main-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 40px;
  border-bottom: 1px solid #d8dce5;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);

  .tags-view-container {
    flex: 1;
    overflow: hidden;
  }

  .extra-action-container {
    flex-shrink: 0;
    margin: 0 26px 0 24px;
  }
}
</style>
