<template>
  <div class="dialog-config editor-element-ui">
    <div class="dialog-add" @click="addDialog">+ 新建弹层</div>
    <div
      class="dialog-item"
      v-for="item in currentPage.dialogs"
      :key="item.uuid"
      :class="{
        activated: item === currentDialog
      }"
    >
      <h3 class="dialog-item-head" @click="setCurrentDialog(item)">
        <i class="el-icon-arrow-up"></i>
        <i class="el-icon-delete" @click.stop="deleteDialog(item)"></i>
        {{item.props.title}}
      </h3>
      <div class="dialog-item-body" v-if="item === currentDialog">
        <div class="dialog-config">
          <div class="dialog-config-label">标题</div>
          <el-input size="mini" :value="dalogProps.title" @input="updateConfig($event, 'title')"></el-input>
        </div>
        <div class="dialog-config">
          <div class="dialog-config-label">宽度</div>
          <el-input
            size="mini"
            placeholder="例如：50% 或 400px"
            :value="dalogProps.width"
            @input="updateConfig($event, 'width')"
          ></el-input>
        </div>
        <div class="dialog-config">
          <div class="dialog-config-label">页面 ID</div>
          <el-input
            size="mini"
            placeholder="例如：387"
            :value="dalogProps.pageId"
            @input="updateConfig($event, 'pageId')"
          ></el-input>
        </div>
        <div class="dialog-config">
          <div class="dialog-config-label">绑定值</div>
          <el-input
            size="mini"
            placeholder="输入关联变量名"
            :value="dalogProps.visibleKey"
            @input="updateConfig($event, 'visibleKey')"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigBox from '@/pages/editor/components/config-box'
import { mapState, mapGetters } from 'vuex'
import * as types from '@/store/mutation-types'

export default {
  computed: {
    ...mapState(['currentFile', 'currentDialog']),

    ...mapGetters(['currentPage']),

    dalogProps() {
      return this.currentDialog.props
    }
  },

  components: {
    ConfigBox
  },

  data() {
    return {
      dialogs: []
    }
  },

  methods: {
    deleteDialog(item) {
      this.$store.dispatch('deleteDialog', item)
    },
    addDialog() {
      this.$store.dispatch('addDialog')
    },
    updateConfig(value, path, type = 'string') {
      this.$store.commit(types.UPDATE_DIALOG, {
        path: `props.${path}`,
        value
      })
      this.$store.dispatch('setFileUnsaved')
    },
    setCurrentDialog(item) {
      if (item === this.currentDialog) item = null
      this.$store.commit(types.SET_CURRENT_DIALOG, item)
    }
  },

  created() {}
}
</script>

<style lang="scss">
@import '@/scss/var.scss';

.dialog-config {
  padding: 10px !important;

  .iconfont:before {
    font-size: 16px;
    vertical-align: middle;
  }

  &_actions {
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }

  .dialog-config {
    &-label {
      padding-bottom: 2px;
      padding-left: 7px;
      line-height: 20px;
      color: #bbb;
      font-size: 12px;
      position: relative;
      overflow: hidden;
      line-height: 28px;

      &.info {
        cursor: help;
      }

      .iconfont {
        color: #666;
      }

      > .right {
        float: right;

        .el-button {
          padding-left: 10px;
          padding-right: 10px;
          margin: 0;
          font-size: 12px;
        }
      }

      .event {
        position: absolute;
        right: 0;
        top: 0;

        .el-button {
          padding: 0;
        }
      }
    }
  }

  .dialog-add {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    line-height: 28px;
    font-size: 12px;
    color: #000000;
    text-align: center;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .dialog-item {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin-bottom: 15px;

    &-head {
      background: #fff;
      font-size: 12px;
      color: #000000;
      padding: 0 12px;
      line-height: 36px;
      cursor: pointer;

      .el-icon-arrow-up {
        display: inline-block;
        line-height: inherit;
        transform: rotate(180deg);
        transition: transform 0.15s;
        margin-right: 5px;
      }
      .el-icon-delete {
        float: right;
        line-height: inherit;
        cursor: pointer;
        display: none;

        &:hover {
          color: $primary;
        }
      }

      &:hover {
        background: #f0f0f0;
        .el-icon-delete {
          display: inline-block;
        }
      }
    }
    &-body {
      height: 0;
      overflow: hidden;
      background-color: #fff;
    }
    &.activated {
      .dialog-item-head {
        background: #fff;
        color: #000000;
      }
    }
    &.activated {
      .dialog-item-body {
        height: auto;
      }

      .el-icon-arrow-up {
        transform: rotate(0);
      }
    }
  }
}
</style>
