/**
 * 解析后端 table 数据格式为正常的数据格式
 */
export default function parseTable(fetchTableData) {
  const { colNames, rows, footerRows = [], total } = fetchTableData

  const data = rows.map(row => {
    const tmp = {}
    row.forEach((v, index) => {
      tmp[colNames[index]] = v
    })
    return tmp
  })

  return {
    total,
    data,
    footerRows
  }
}
