/**
 * 加载此目录下所有 js 文件并全局注册为 component
 */

import Vue from 'vue'

const componentsContext = require.context('@/components', true, /\.vue$/)

componentsContext.keys().forEach(path => {
  const component = componentsContext(path).default
  Vue.component(component.name, component)
})
