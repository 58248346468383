<script>
export default {
  name: 'MenuItem',

  functional: true,

  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },

  render(h, context) {
    const { icon, title } = context.props
    const vnodes = []
    if (icon) {
      if (icon.includes('fa-') || icon.includes('el-')) {
        // 兼容 font-awesome 和 element-ui 图标显示
        vnodes.push(
          <div class="menu-icon">
            <i class={icon} />
          </div>
        )
      } else {
        vnodes.push(
          <div class="menu-icon">
            <svg-icon icon-class={icon} />
          </div>
        )
      }
    } else {
      // 二级菜单对齐一级菜单
      vnodes.push(<span style={{ paddingLeft: '10px' }} />)
    }

    if (title) {
      vnodes.push(<span slot="title">{title}</span>)
    }
    return vnodes
  }
}
</script>
