import { UI_SCHEMAS, EVENT_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { size, placeholder, displayFormat, label } = UI_SCHEMAS
const { bindKey, disabled, readonly, valueFormat } = DATA_SCHEMAS
const { handleChangeKey, handleBlurKey, handleFocusKey } = EVENT_SCHEMAS

export default {
  componentName: 'XDatePicker',

  label: 'Date',

  props: {
    type: 'date',
    placeholder: '选择日期',
    size: 'small',
    label: '条目标签'
  },

  validate: true,
  beTableSlot: true,
  notFlex: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        size,
        label,
        placeholder,
        displayFormat,

        type: {
          name: 'Picker 类型',
          widget: 'select',
          values: [
            { k: '年份', v: 'year' },
            { k: '月份', v: 'month' },
            { k: '日期', v: 'date' },
            { k: '星期', v: 'week' },
            { k: '日期范围', v: 'daterange' }
          ]
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: {
        bindKey,
        disabled,
        readonly,
        valueFormat,

        disabledDateFuncKey: {
          name: '禁用的日期',
          info: '指定函数返回布尔值，函数参数为当前日期'
        },

        shortcutsKey: {
          name: '快捷选项',
          info: '例如[{text: "标题文本", onClick: "选中后的回调函数"}]'
        }
      }
    },

    events: {
      label: '事件绑定',
      content: {
        handleChangeKey,
        handleBlurKey,
        handleFocusKey,

        handlePickKey: {
          name: 'onPick',
          description: '选中日期后会执行的回调，Function({ maxDate, minDate })',
          argumentsMapping: [
            {
              name: 'value',
              description: '选中的日期值，object 类型，{ maxDate, minDate })'
            }
          ]
        }
      }
    }
  }
}
