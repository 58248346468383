import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const debug = process.env.NODE_ENV !== 'production'
let localBrands
try {
  localBrands = JSON.parse(window.localStorage.getItem('brands'))
} catch (err) {
  localBrands = null
}

export default new Vuex.Store({
  state: {
    isProduction: false,
    user: {},
    // 品牌
    brands: localBrands || [],
    projectOrigin: {},
    // 项目 ID
    projectId: '',
    // 项目名称
    projectName: '',
    // 项目权限类型
    permissionType: '',
    // 当前可视组件
    currentComponents: [],
    // 是否准备移除组件
    readyToRemoveComponent: false,
    // 整个站点集合数据
    pageMapping: {},
    // 项目左侧导航树
    projectTree: [],
    projectDataSources: [],
    // 项目数据源配置参数
    projectParams: [],
    // 项目字典全数据
    projectDictionary: {},
    // 项目全局变量配置
    projectConstants: null,
    projectSetting: null,
    projectUrlMap: null,
    // 当前可视弹框
    currentDialog: null,
    // 当前可视文件
    currentFile: Object.create(null),
    // 文件存储状态
    fileSaveLoading: false,
    currentModiferTabName: '',

    componentTreeHistory: [],
    lastSavedComponentTree: Object.create(null),
    currentHistoryIndex: 0,

    previewingFile: null,
    openedFiles: [],
    draggingItem: null,
    placeholder: null,
    dragItemHelper: null,

    draggingComponent: null,
    draggingPosition: {},
    editorLoading: true,

    // runtime
    sidebar: {
      opened: Cookies.get('sidebarStatus')
        ? !!+Cookies.get('sidebarStatus')
        : true
    },
    needTagsView: true,
    fixedHeader: true,
    visitedViews: [],
    cachedViews: [],

    renderRoute: null,
    permissionRoutes: [],
    permissionAddRoutes: [],

    errorLogs: [],
  },
  mutations,
  actions,
  getters,
  strict: debug
})
