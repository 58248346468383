import Router from 'vue-router'
import {
  Home,
  Login,
  AuthRedirect,
  Profile,
  Editor,
  Preview,
  SimplePage,
  RenderLayout,
  Page404
} from '@/pages'

export const asyncRoutes = [
  {
    path: '*',
    redirect: '/404'
  }
]

/**
 * hidden              导航菜单隐藏
 * searchFilter        导航菜单搜索过滤
 * meta:
 *   affix             导航菜单固定
 *   breadcrumb        显示面包屑
 */
export const constantRoutes = [
  {
    path: '/',
    component: RenderLayout,
    redirect: '/home',
    searchFilter: true,
    children: [
      {
        path: 'home',
        name: 'home',
        meta: {
          title: '主页',
          icon: 'fa fa-home',
          affix: true
        },
        component: Home
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    searchFilter: true,
    meta: {
      title: '登录'
    },
    component: Login
  },
  {
    path: '/auth-redirect',
    name: 'authRedirect',
    hidden: true,
    searchFilter: true,
    meta: {
      title: '自动跳转'
    },
    component: AuthRedirect
  },
  {
    path: '/profile',
    name: 'profile',
    hidden: true,
    searchFilter: true,
    meta: {
      title: '我的项目'
    },
    component: Profile
  },
  {
    path: '/editor/:projectId',
    name: 'editor',
    hidden: true,
    searchFilter: true,
    meta: {
      title: '编辑项目'
    },
    component: Editor
  },
  {
    path: '/page',
    component: RenderLayout,
    hidden: true,
    searchFilter: true,
    children: [
      {
        path: ':id',
        name: 'page',
        meta: {
          title: '智简'
        },
        component: null
      }
    ]
  },
  {
    path: '/preview',
    component: Preview,
    hidden: true,
    searchFilter: true,
    children: [
      {
        path: ':id',
        name: 'preview',
        meta: {
          title: '智简'
        },
        component: null
      }
    ]
  },
  {
    path: '/simple-page',
    component: SimplePage,
    hidden: true,
    searchFilter: true,
    children: [
      {
        path: ':id',
        name: 'simplePage',
        meta: {
          title: '智简'
        },
        component: null
      }
    ]
  },
  {
    path: '/404',
    component: Page404,
    hidden: true,
    searchFilter: true,
    meta: {
      title: '404'
    }
  }
]

export default new Router({
  mode: 'hash',
  routes: constantRoutes
})
