<template>
<div class="x-pagination">
  <el-pagination @current-change="onPageChange" :page-size="pageSize" :current-page="currentPage" layout="prev, pager, next" :total="total"/>
</div>
</template>

<script>
import ModelMixin from '../mixins/model-mixin'

export default {
  name: 'XPagination',

  mixins: [ModelMixin],

  props: {
    pageSizeKey: String,
    totalKey: String,
    currentPageKey: String,

    handlePageChangeKey: String,
    project: Object
  },

  computed: {
    total() {
      return this._IS_EDIT_ ? 100 : this.getValueByPath(this.totalKey)
    },

    pageSize() {
      return this._IS_EDIT_ ? 10 : this.getValueByPath(this.pageSizeKey)
    },

    currentPage() {
      return this._IS_EDIT_ ? 1 : this.getValueByPath(this.currentPageKey)
    }
  },

  methods: {
    onPageChange(pageIndex) {
      if (this._IS_EDIT_) return
      this.handlePageChangeKey &&
        this.project.globalAPI[this.handlePageChangeKey](pageIndex)
    }
  }
}
</script>

<style lang="scss">
.x-pagination {
  display: flex;
}
</style>
