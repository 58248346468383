/**
 * menu to routes
 * type: 1 -> page 3 -> external link
 */
import { removeFromArray } from './index'

const allRoutes = []

function removeEmptyNody(node) {
  // 有url的，就算有效节点，否则视为目录
  let num = node.url ? 1 : 0
  if (node.children instanceof Array) {
    for (let i = node.children.length - 1; i >= 0; i -= 1) {
      const node2 = node.children[i]
      const num2 = removeEmptyNody(node2)
      if (num2) {
        num += num2
      } else {
        removeFromArray(node.children, node2)
      }
    }
  }
  return num
}

// all parent route need redirect: 'noRedirect'
// append path to route
function appendValueToRoutes(routes) {
  routes.forEach(route => {
    if (route.children) {
      route.path = `/tmp/${Math.random()}`
      route.redirect = 'noRedirect'
      appendValueToRoutes(route.children)
    } else {
      route.path = `/page/${route.id}`
    }
  })
}

function appendQuery(query) {
  let str = ''
  for (let key in query) {
    str += `${key}=${query[key]}`
  }
  return str
}
export function getRouteByMenuId(menuId, pageId, query = null) {
  let result
  const run = (routes, menuId) => {
    for (let i = 0; i < routes.length; i += 1) {
      const route = routes[i]
      if (pageId && query && route.id === pageId) {
        console.log('测试特殊处理1689 正式1690')
        route.url = route.url + '?' + appendQuery(query)
      }
      if (route.id === menuId) {
        result = route
        break
      }
      if (route.children) {
        run(route.children, menuId)
      }
    }
  }

  run(allRoutes, menuId)

  return result
}

export default function(menu) {
  allRoutes.length = 0
  const { categorys, menus } = menu
  const catsCache = {}
  const menusCache = {}

  for (let i = 0, len = categorys.length; i < len; i += 1) {
    const category = categorys[i]
    catsCache[category.CATID] = {
      subsystem: category.GTITLE,
      meta: {
        title: category.TITLE,
        icon: category.ICON
      },
      children: []
    }
  }
  for (let i = 0, len = menus.length; i < len; i += 1) {
    const menu = menus[i]
    let url = menu.URL
    /* eslint-disable no-eval,no-script-url */
    if (url && url.startsWith('javascript:')) {
      try {
        url = window.eval(url.substring(11))
      } catch (e) {
        url = null
        console.log(e)
      }
    }
    /* eslint-enable */

    let helpUrl = menu.HELP_URL
    /* eslint-disable no-eval,no-script-url */
    if (helpUrl && helpUrl.startsWith('javascript:')) {
      try {
        helpUrl = eval(helpUrl.substring(11))
      } catch (e) {
        helpUrl = null
        console.log(e)
      }
    }
    /* eslint-enable */

    const tmp = {
      id: menu.MENUID,
      state: 'open',
      url: url,
      // 记录当前页面访问历史
      history: [],
      help_url: helpUrl,
      isspa: menu.ISSPA,
      page_type: menu.PAGETYPE,
      page_id: menu.PAGEID,
      catid: menu.CATID,
      is_blank: menu.IS_BLANK,
      type: menu.MENUTYPE,
      meta: {
        title: menu.TITLE,
        icon: menu.ICON
      }
    }
    menusCache[menu.MENUID] = tmp
    if (menu.PID) {
      const parent = menusCache[menu.PID]
      if (parent && parent.type === 0) {
        if (parent.state === 'open') {
          parent.state = 'closed'
          parent.children = []
        }
        tmp.parent = parent
        parent.children.push(tmp)
      }
    } else {
      const cat = catsCache[menu.CATID]
      tmp.parent = cat
      cat.children.push(tmp)
    }
  }

  for (let i = 0, len = categorys.length; i < len; i += 1) {
    const catid = categorys[i].CATID
    const cat = catsCache[catid]
    const num = removeEmptyNody(cat)
    if (num > 0) {
      // 默认选择隐藏导航
      cat.hidden = true
      allRoutes.push(cat)
    }
  }

  appendValueToRoutes(allRoutes)

  return allRoutes
}
