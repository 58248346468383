import { UI_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { label } = UI_SCHEMAS
const { bindKey, disabled } = DATA_SCHEMAS

export default {
  componentName: 'XUpload',

  label: 'Upload',

  props: {
    label: '条目标签',
    listType: 'text',
    autoUpload: true,
    withCredentials: true,
    name: 'file'
  },

  notFlex: true,
  validate: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        label,
        listType: {
          name: '文件列表类型',
          widget: 'select',
          placeholder: '选择文件列表类型',
          info: '文件列表的展示方式。对于非图片上传，请选择「文字」',
          values: [
            { k: '文字', v: 'text' },
            { k: '图片', v: 'picture' }
          ]
        },
        drag: {
          name: '是否允许拖拽上传',
          type: 'boolean',
          widget: 'switch'
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: {
        bindKey,
        disabled,
        action: {
          name: '上传接口地址',
          type: 'object',
          dynamicOption: {
            dynamic: { keyName: 'actionKey' },
            static: { keyName: 'action' },
            isStatic: true
          },
          widget: 'dynamicOrStatic'
        },
        multiple: {
          name: '是否允许选择多个文件',
          type: 'boolean',
          widget: 'switch'
        },
        autoUpload: {
          name: '是否在选取文件后立即进行上传',
          type: 'boolean',
          widget: 'switch'
        },
        withCredentials: {
          name: '是否在请求中附带 cookie',
          type: 'boolean',
          widget: 'switch'
        },
        dataKey: {
          name: '上传时附带的额外参数',
          info: '需要在上传时附加的其他参数，其值应为一个对象'
        },
        name: {
          name: '上传的文件字段名',
          placeholder: '输入文件字段名',
          info: '上传请求的 FormData 中该文件的 name 值'
        },
        fileListKey: {
          name: '已上传成功的文件列表',
          info: '用于编辑已经提交的表单时，展示用户上次上传的文件'
        }
      }
    },

    events: {
      label: '事件绑定',
      content: {
        handleChangeKey: {
          name: 'onChange',
          argumentsMapping: [{
            description: '当前上传文件',
            name: 'file'
          }, {
            description: '已上传文件列表',
            name: 'fileList'
          }]
        },
        handleErrorKey: {
          name: 'onError',
          argumentsMapping: [{
            description: '错误响应',
            name: 'error'
          }, {
            description: '当前上传文件',
            name: 'file'
          }, {
            description: '已上传文件列表',
            name: 'fileList'
          }]
        },
        handleSuccessKey: {
          name: 'onSuccess',
          argumentsMapping: [{
            description: '成功响应',
            name: 'response'
          }, {
            description: '当前上传文件',
            name: 'file'
          }, {
            description: '已上传文件列表',
            name: 'fileList'
          }]
        },
        handlePreviewKey: {
          name: 'onPreview',
          argumentsMapping: [{
            description: '被点击的文件',
            name: 'file'
          }]
        },
        handleRemoveKey: {
          name: 'onRemove',
          argumentsMapping: [{
            description: '被移除的文件',
            name: 'file'
          }, {
            description: '已上传文件列表',
            name: 'fileList'
          }]
        }
      }
    }
  }
}
