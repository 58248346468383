import request from '@/api/request'
import Project from '@/pages/editor/modules/catalog/project'
import parseTable from '@/utils/parseTable'
import { upperCaseKey } from '@/utils'
import { getProjectModel, getUpdateFullProjectModel } from './model'
import { Message } from 'element-ui'
import store from '../store'

export default {
  login(data) {
    return request({
      url: '/user/login',
      method: 'post',
      data
    })
  },
  logout() {
    return request({
      url: '/user/logout',
      method: 'post'
    })
  },
  loginByCode(data) {
    return request({
      url: '/user/loginwithcode',
      method: 'post',
      data
    })
  },
  getUserInfo() {
    return request({
      url: '/user/islogin',
      method: 'get'
    })
  },
  changePassword(oldPassword, newPassword) {
    return request({
      url: '/user/changepassword',
      method: 'post',
      data: {
        old_password: oldPassword,
        password: newPassword
      }
    })
  },
  chooseBrand(userId) {
    return request({
      url: `/user/chooseuser?userid=${userId}`,
      method: 'post',
    })
  },
  createProject({name, permissionType = 1}) {
    return request({
      url: '/webtable/save',
      method: 'post',
      data: {
        option: 2,
        pageid: 5,
        data: getProjectModel({ name, permissionType })
      }
    })
  },
  async updateProject(projectId, {name, permissionType = 1}) {
    let projectData
    await this.getOriginProject(projectId).then(res => {
      projectData = res.data[0].INIT
    })
    return request({
      url: '/webtable/save',
      method: 'post',
      data: {
        option: 2,
        pageid: 5,
        data: getProjectModel({ name, permissionType, projectId, projectData })
      }
    })
  },
  forkProject(srcProjectName, destProjectName) {
    return Promise.resolve({})
  },
  deleteProject(projectId) {
    return request({
      url: '/webpage/execpageaction',
      method: 'post',
      data: {
        pageid: projectId,
        _pageid: 11,
        _actid: 2
      }
    })
  },
  searchProjects({offset = 0, count = 20, name = null, pageId = null}) {
    const isProduction = store.state.isProduction
    return request({
      url: '/webtable/query',
      method: 'post',
      data: {
        option: 0,
        pageid: 11,
        name: 'page',
        _version: isProduction ? undefined : '-1',
        variables: {
          pageid: pageId,
          name,
          // 3 是新版查询页面
          page_type: 3,
          orderby: null,
          offset,
          count
        }
      }
    }).then(res => parseTable(res.data))
  },
  getOriginProject(projectId) {
    return this.getTable({ name: 'page', projectId: 5, query: { pageid: projectId } })
  },
  getProject(projectId) {
    const isProduction = store.state.isProduction
    return request({
      url: isProduction ? `/webpage/getpagedata?pageid=${projectId}` : `/webpage/export?pageids=${projectId}`,
      method: 'get'
    }).then(data => {
      if (isProduction) {
        // 未发布页面
        if (!data) {
          const message = '没有发布的页面，等待页面审核通过'
          Message.warning(message)
          throw new Error(message)
        }
      }
      const dataSources = isProduction ? upperCaseKey(data.tables) : data.tables
      const dataTableColumns = isProduction ? upperCaseKey(data.tables.length > 0 ? data.tables[0].columns : []) : data.table_columns
      const page = isProduction ? upperCaseKey(data.page) : data.pages[0]
      const projectDataString = page.INIT
      const paramsString = isProduction ? JSON.stringify(page.PGPARAMS) : page.PARAMS
      const project = new Project(projectId)
      let projectData = {}
      let params = []

      dataSources.forEach(dataSource => {
        if (!dataSource.columns) {
          dataSource.columns = []
        }
        dataTableColumns.forEach(column => {
          if (column.TABLEID === dataSource.TABLEID) {
            dataSource.columns.push(column)
          }
        })
      })

      if (projectDataString) {
        try {
          projectData = JSON.parse(projectDataString)
        } catch (e) {}
      }

      if (paramsString) {
        try {
          params = JSON.parse(paramsString)
        } catch (e) {}
      }

      Object.assign(project, projectData, {
        params,
        dataSources
      })

      project.projectName = page.NAME
      project.permissionType = page.PAGETYPE

      return project
    })
  },
  updateFullProject(project) {
    return request({
      url: '/webtable/save',
      method: 'post',
      data: getUpdateFullProjectModel(project)
    })
  },
  releaseProject(projectId, comment) {
    return request({
      url: '/webpage/execpageaction',
      method: 'post',
      data: {
        _pageid: 186,
        _actid: 1,
        pageid: projectId,
        remark1: comment,
      }
    })
  },
  releasePageDataScript(projectName, scriptContent) {
    return Promise.resolve({})
  },
  putScriptReleaseLog(releaseInfo) {
    return Promise.resolve({})
  },
  getReleaseLog(projectName, type) {
    return Promise.resolve({})
  },
  getMenu() {
    return request({
      url: '/menu/getall',
      method: 'get'
    })
  },
  getTable({ name, projectId, query = {}, isParse = true }) {
    const isProduction = store.state.isProduction
    const { count = 20, offset = 0, ...reset } = query
    return request({
      url: '/webtable/query',
      method: 'post',
      data: {
        name,
        option: 0,
        pageid: projectId,
        _version: isProduction ? undefined : '-1',
        variables: {
          count,
          offset,
          ...reset
        }
      }
    }).then(res => isParse ? parseTable(res.data) : res.data)
  },
  // 获取全量字典配置数据
  getDictionary() {
    return request({
      url: '/dictionary/getnew?tp=null',
      method: 'get'
    })
  },
  // 获取动态字典的值
  getDynamicDictionary(dicCode, searchValue = null) {
    return request({
      url: '/dictionary/query',
      method: 'post',
      data: {
        diccode: dicCode,
        variables: {
          value: searchValue
        }
      }
    }).then(res => parseTable(res.data))
  },
  // 执行页面操作
  execAction({ pageId, actionId, params }) {
    const isProduction = store.state.isProduction
    return request({
      url: '/webpage/execpageaction',
      method: 'post',
      data: {
        _actid: actionId,
        _pageid: pageId,
        _version: isProduction ? undefined : '-1',
        ...params
      }
    })
  },
}
