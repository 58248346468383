<template>
  <el-dialog
    :title="isNewUser ? '新用户修改密码' : '修改密码'"
    :visible.sync="visible"
    :show-close="!isNewUser"
    :close-on-click-modal="!isNewUser"
    append-to-body
    @close="closeDialog"
  >
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="rule-form"
    >
      <el-form-item label="旧密码" prop="oldPass" v-if="!this.$store.state.user.needpsd">
        <el-input
          type="password"
          v-model="ruleForm.oldPass"
          show-password
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="pass">
        <el-input
          type="password"
          v-model="ruleForm.pass"
          show-password
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          show-password
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm()">重置</el-button>
      <el-button v-if="!isNewUser" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="submitForm()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import API from '@/api'

export default {
  name: 'ChangePassDialog',

  props: {
    visible: {
      type: Boolean
    },
    isNewUser: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 6) {
        callback(new Error('至少输入6位'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }

    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次密码不匹配，请重新输入'))
      } else {
        callback()
      }
    }

    return {
      ruleForm: {
        oldPass: '',
        pass: '',
        checkPass: ''
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validateCheckPass, trigger: 'blur' }]
      }
    }
  },

  methods: {
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          API.changePassword(this.ruleForm.oldPass, this.ruleForm.pass).then(() => {
            this.closeDialog()
            this.$message.success('成功修改密码')
          })
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.resetFields()
    },
    closeDialog() {
      this.$refs.ruleForm.resetFields()
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
