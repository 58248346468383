export default {
  componentName: 'XCard',

  label: 'Card',

  props: {
    title: {
      text: '卡片标题',
      textKey: '',
      isStatic: true
    }
  },

  _children: {
    body: [],
    actions: []
  },

  notFlex: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        title: {
          name: '标题',
          type: 'object',
          dynamicOption: {
            dynamic: { keyName: 'textKey' },
            static: { keyName: 'text', placeholder: '输入标题文字' },
            isStatic: true
          },
          widget: 'dynamicOrStatic'
        }
      }
    }
  }
}
