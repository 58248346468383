<template>
  <div class="components-config">
    <div class="config">
      <el-form size="mini" label-position="top">
        <config-box title="组合控制">
          <el-form-item class="config_item">
            <el-button @click="testGroup">组合</el-button>
          </el-form-item>
        </config-box>
      </el-form>
    </div>
  </div>
</template>


<script>
import ConfigBox from '@/pages/editor/components/config-box'
import { mapState } from 'vuex'

export default {
  components: {
    ConfigBox
  },
  computed: {
    ...mapState(['currentComponents'])
  },
  data() {
    return {}
  },
  methods: {
    testGroup() {
      this.groupComponents()
    },

    // 把组件放到同一行
    groupComponents() {
      console.log(this.currentComponents)
      // const groupDisable = [
      //   'XRow',
      //   'XCol'
      // ]
      // this.currentComponents.forEach(component => {
      // })
    }
  }
}
</script>
