<template>
  <div class="render-page">
    <div v-if="projectLoading" class="loading">
      <i class="el-icon-loading" />
    </div>
    <core v-else :project="project" />
  </div>
</template>

<script>
import Vue from 'vue'
import bus from '@/pages/render-layout/bus'
import Core from '@/core'
import API from '@/api'

export default {
  name: 'RenderPage',

  props: {
    query: {
      type: Object
    },
    projectId: {
      type: Number,
      required: true
    }
  },

  components: {
    Core
  },

  data() {
    return {
      projectLoading: false,
      project: null
    }
  },

  created() {
    // 注意和线上的转换
    Vue.prototype._IS_EDIT_ = false
    this.getProjectData()
    bus.$on('reload', this.reload)
  },

  methods: {
    reload() {
      this.getProjectData()
    },
    getProjectData() {
      this.projectLoading = true
      this.$store.dispatch('initEditor', this.projectId).then(project => {
        this.project = project
        this.projectLoading = false
        if (this.query) {
          Vue.set(this.project, 'query', this.query)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
