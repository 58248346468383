<template>
  <div class="component-config">
    <!-- 配置 -->
    <div class="config">
      <el-form :model="currentConfig" size="mini" label-position="top">
        <template v-for="(category, key) in currentSchema">
          <config-box :title="category.label" :key="key" v-if="shouldConfigBox(key)">
            <template v-for="(item, formKey) in category.content">
              <el-form-item
                v-if="
                  !(item.hiddenInTable && onlyActiveComponent.tableSlotIndex)
                "
                class="config_item"
                :class="{ 'is-event': key.includes('vents') }"
                :key="item.name"
              >
                <!-- label -->
                <el-tooltip effect="dark" v-if="item.info" placement="left">
                  <span slot="content" v-html="item.info"></span>
                  <div class="config_item-label info" :class="{ inline: key.includes('vents') }">
                    {{ item.name }}
                    <i class="el-icon-star-on"></i>
                  </div>
                </el-tooltip>

                <div
                  v-else
                  class="config_item-label"
                  :class="{ inline: key.includes('vents') }"
                >{{ item.name }}</div>

                <!-- widget -->
                <keep-alive>
                  <component
                    :is="
                      WidgetMap[
                        item.widget ||
                          (key.includes('vents') ? 'event' : 'input')
                      ].component
                    "
                    v-model="currentConfig[formKey]"
                    @input="changeConfig"
                    :placeholder="
                      item.placeholder ||
                        (key === 'ui' ? '请输入配置' : '输入关联变量名')
                    "
                    :dynamicOption="item.dynamicOption"
                    controls-position="right"
                    :min="item.min || 0"
                    :max="item.max"
                    :size="item.size ? item.size : ''"
                    @active-change="color => changeBGC(color, formKey)"
                    show-alpha
                    popper-class="dark"
                    :item="item"
                    :form-key="formKey"
                    @addEventHandler="addEventHandler"
                    @removeEventHandler="removeEventHandler"
                  >
                    <slot v-if="item.values && item.widget !== 'radio'">
                      <component
                        v-for="opt in getValues(item, item.values)"
                        :value="opt.v"
                        :label="opt.k"
                        :key="opt.v"
                        :is="WidgetMap[item.widget || 'input'].children"
                      ></component>
                    </slot>
                    <slot v-if="item.values && item.widget === 'radio'">
                      <component
                        v-for="opt in getValues(item, item.values)"
                        :label="opt.v"
                        :key="opt.v"
                        :is="WidgetMap[item.widget || 'input'].children"
                      >{{ opt.k }}</component>
                    </slot>
                  </component>
                </keep-alive>
              </el-form-item>
            </template>
          </config-box>
        </template>
      </el-form>
    </div>
    <!-- 新建方法-->
    <el-dialog
      class="dark"
      :visible.sync="dialogs.func"
      title="绑定方法"
      append-to-body
      width="500px"
      custom-class="dialog-delete"
    >
      <el-form ref="funcForm" :model="funcForm" :rules="funcRule">
        <el-form-item label="方法名" prop="name">
          <el-autocomplete
            ref="addFuncInput"
            popper-class="dark"
            @keydown.native.enter.stop="enterToAddFuc"
            :fetch-suggestions="funcSearch"
            valueKey="name"
            v-model="funcForm.name"
          ></el-autocomplete>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogs.func = false">取消</el-button>
        <el-button type="primary" @click="addFunc">创建</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { result, cloneDeep, clone } from 'lodash-es'
import { mapState, mapGetters } from 'vuex'
import * as mutationTypes from '@/store/mutation-types'
import BasicSchemas from '@editor/components/basicComponents/schemas/all'
import BusinessSchemas from '@editor/components/businessComponents/schemas/all'
import ChartSchemas from '@editor/components/chartComponents/schemas/all'
import XComponentConfig from '@editor/components/basicComponents/schemas/x-component-config'
import WidgetMap from '@editor/components/commonWidgets/map'
import Project from '@/pages/editor/modules/catalog/project'
import bus from '@/pages/editor/modules/bus'
import ConfigBox from '@/pages/editor/components/config-box'
import { isRegisteredComponent } from '@/pages/editor/utils'

const AllSchemas = {
  ...BasicSchemas,
  ...BusinessSchemas,
  ...ChartSchemas
}

export default {
  name: 'ComponentConfig',

  components: {
    ConfigBox
  },

  data() {
    return {
      inited: false,

      WidgetMap,
      XComponentConfig,

      visibleOption: {
        dynamic: { keyName: 'visibleKey' },
        static: { keyName: 'visible', widget: 'switch', default: true },
        isStatic: true
      },

      collapseState: {
        ui: false,
        data: false,
        events: false
      },

      currentConfig: {},

      typeMap: {
        string: '',
        number: 0,
        boolean: false,
        array: [],
        object: null
      },

      dialogs: {
        func: false
      },
      funcForm: {
        name: '',
        eventSchema: undefined
      },
      funcRule: {
        name: [
          { required: true, message: '请输入方法名', trigger: 'blur' },
          {
            validator: (r, value, cb) => {
              if (!/^\w+[^.]$/.test(value)) {
                return cb(new Error('方法名必须由数字或字母组成'))
              } else {
                cb()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },

  computed: {
    ...mapState(['projectName']),
    ...mapGetters(['currentPageFuncs', 'onlyActiveComponent']),

    currentComponentName() {
      return (this.onlyActiveComponent || {}).componentName || 'XDefault'
    },

    currentSchema() {
      if (!isRegisteredComponent(this.currentComponentName)) {
        return {}
      }
      const rootSchema = AllSchemas[this.currentComponentName]

      const rootConfig = rootSchema.configs

      const { visible, align } = this.XComponentConfig.ui.content
      const { id, validate } = this.XComponentConfig.data.content

      if (this.currentComponentName !== 'XDefault') {
        rootConfig.ui.content.visible = cloneDeep(visible)

        if (!rootSchema.notFlex) {
          rootConfig.ui.content.align = cloneDeep(align)
        }

        if (result(rootSchema, 'validate', false)) {
          rootConfig.data.content.validate = cloneDeep(validate)
        }

        if (result(rootSchema, 'id', false)) {
          rootConfig.data.content.id = cloneDeep(id)
        }
      }

      return rootConfig
    }
  },

  watch: {
    onlyActiveComponent: {
      handler: function(val) {
        return this.getCurrentConfig(val)
      },
      deep: true
    }
  },

  created() {
    this.project = new Project(this.projectName)
  },

  methods: {
    getValues(item, values) {
      if (item._values) {
        return item._values
      }

      item._values = null

      if (typeof values === 'function') {
        const resolvedValues = values()

        if (typeof resolvedValues === 'object' && resolvedValues.then) {
          resolvedValues.then(data => {
            item._values = data
          })
        }

        return resolvedValues
      }

      return values
    },

    shouldConfigBox(key) {
      return (
        !['events', 'tableEvents'].includes(key) ||
        (key === 'events' && !this.onlyActiveComponent.tableSlotIndex) ||
        (key === 'tableEvents' && this.onlyActiveComponent.tableSlotIndex)
      )
    },

    enterToAddFuc(e) {
      e.preventDefault()
      this.addFunc()
    },

    changeBGC(color, key) {
      this.currentConfig[key] = color
      this.changeConfig()
    },

    collapseIt(which) {
      this.collapseState[which] = !this.collapseState[which]
    },

    changeConfig(val) {
      if (this.inited) {
        this.$store.dispatch('setFileUnsaved')

        this.$store.commit(
          mutationTypes.MODIFY_COMPONENT_PROPS,
          cloneDeep(this.currentConfig)
        )
        window.configChanged = true
      }
    },

    removeEventHandler(formKey) {
      this.currentConfig[formKey] = ''
      this.changeConfig()
      this.$store.dispatch('saveProject')
      bus.$emit('currentPageFileChange')
    },

    funcSearch(name, cb) {
      cb(this.currentPageFuncs.filter(f => f.name.includes(name)))
    },

    addFunc() {
      this.$refs.funcForm.validate(async valid => {
        if (valid) {
          const { name: funcName, eventSchema, formKey } = this.funcForm

          const existed = this.currentPageFuncs.some(f => f.name === funcName)
          if (!existed) {
            const { argumentsMapping } = eventSchema
            const options = {
              name: funcName,
              argumentsMapping,
              params: argumentsMapping.map(item => item.name)
            }
            const funcContent = this.project.generateFunctionObject(options)

            this.$store.dispatch('addFiles', {
              type: 'functions',
              content: funcContent
            })
          }

          this.currentConfig[formKey] = funcName
          this.changeConfig()
          this.$store.dispatch('saveProject')
          bus.$emit('currentPageFileChange')
          this.dialogs.func = false
        }
      })
    },

    addEventHandler(eventSchema, formKey) {
      this.funcForm.name = ''
      this.funcForm.eventSchema = eventSchema
      this.funcForm.formKey = formKey
      this.dialogs.func = true
    },

    getCurrentConfig(component) {
      const hasSelected = component && Object.keys(component).length
      // 判断当前是否选中组件
      if (hasSelected) {
        this.inited = false
        let temp = {}

        Object.keys(this.currentSchema).forEach(type => {
          const item = this.currentSchema[type].content
          Object.keys(item).forEach(key => {
            temp[key] = clone(this.typeMap[item[key].type || 'string'])
          })
        })

        this.currentConfig = Object.assign(temp, { align: 'left' })

        this.collapseState = {
          ui: false,
          data: false,
          events: false
        }

        Object.assign(this.currentConfig, cloneDeep(component.props || {}))

        this.$nextTick(_ => {
          this.inited = true
        })
      } else {
        this.currentConfig = {}
      }
    }
  },

  mounted() {
    this.getCurrentConfig(this.onlyActiveComponent)
  }
}
</script>

<style lang="scss" scoped>
:root {
  --gray: #e4e4e4;
}

.component-config {
  position: relative;
  padding: 10px;
  padding-top: 0px;
  padding-right: 15px;
  flex-grow: 1;
  overflow-y: auto;
}

.tree {
  &_title {
    color: white;
    padding: 5px 10px;
    margin: 0 -15px 0 -10px;
    font-size: 13px;
    background-color: var(--gray);
  }

  &_panel {
    background: #111;
    height: 200px;
    margin: 0 -10px;
  }
}
</style>
