<template>
  <div class="widget-column">
    <div class="column_row mapping">
      <div class="column_label mapping" content="绑定字段"></div>
      <div class="column_item">
        <el-input v-model="prop" @input="changeSetting" />
      </div>
    </div>
    <slot v-if="inited">
      <div class="column" v-for="(map, i) in mapping" :key="i">
        <div class="column_title mapping" @click="removeMapping(i)"></div>
        <div class="column_row">
          <div class="column_label" content="对应值"></div>
          <div class="column_item">
            <el-input
              placeholder="请输入"
              v-model="map.value"
              @input="changeSetting"
            />
          </div>
        </div>
        <div class="column_row">
          <div class="column_label" content="对应文字"></div>
          <div class="column_item">
            <el-input v-model="map.text" @input="changeSetting" />
          </div>
        </div>
        <div class="column_row">
          <div class="column_label" content="对应类型"></div>
          <div class="column_item">
            <el-select
              placeholder="请选择类型"
              popper-class="dark"
              v-model="map.type"
              @input="changeSetting"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </slot>

    <div class="column_action">
      <div
        class="add-column small-text full slot"
        small-content="+ add mapping"
        @click="addMapping"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetMapping',

  props: {
    value: Object
  },

  watch: {
    value(val) {
      this.init()
    }
  },

  mounted() {
    this.init()
  },

  data() {
    return {
      inited: false,
      mapping: [],
      prop: '',

      typeOptions: [
        {
          value: 'success',
          label: '成功'
        },
        {
          value: '',
          label: '默认'
        },
        {
          value: 'info',
          label: '信息'
        },
        {
          value: 'warning',
          label: '警告'
        },
        {
          value: 'danger',
          label: '危险'
        }
      ]
    }
  },

  methods: {
    init() {
      this.prop = this.value.prop
      this.mapping = this.value.mapping
      this.inited = true
    },

    addMapping() {
      this.mapping.push({
        text: '新状态',
        prop: this.prop,
        type: 'success'
      })

      this.$nextTick($ => {
        document.querySelector('.component-config').scrollTop += 131
      })
    },

    removeMapping(index) {
      this.mapping.splice(index, 1)
      this.changeSetting()
    },

    changeSetting() {
      const { prop, mapping } = this
      this.$emit('input', { prop, mapping })
    }
  }
}
</script>

<style lang="scss">
/* 样式在 scss/components/widget-column 里引入 */
</style>
