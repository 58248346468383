/*
 * 用于页面数据兼容
 * 由于组件 Schema 变更，可能导致服务器保存的用户数据结构
 * 与最新 Schema 不兼容，这样渲染会造成页面错误
 * 因此最好在获取到服务器数据之后对其进行预处理，
 * 使之兼容最新的 Schame 数据结构，
 * 然后再把数据保存到 State 中
 */

// 遍历组件树中的全部组件，并执行某个操作（由 handler 提供）
const componentWalker = (input, handler) => {
  const internalWalker = tree => {
    tree.length && tree.forEach(node => {
      handler(node)
      if ('_children' in node) {
        if (node._children.length) {
          internalWalker(node._children)
        } else if ('actions' in node._children) {
          internalWalker(node._children.actions)
          internalWalker(node._children.body)
        }
      }
    })
  }
  internalWalker(input.componentTree)
}

/**
 * 2018/04/17
 * 页面标题改为在配置界面配置，而不是发布时配置
 */
const pageAdjust = input => {
  if ('title' in input) {
    if (!('title' in input.configs)) {
      input.configs.title = input.title
    }
    delete input.title
  }
  delete input.commitHash
  if (input.urls) {
    input.url = input.urls.production
    delete input.urls
  }
  return input
}

const preprocessors = [
  pageAdjust
]

const compatible = input => {
  let output = input
  preprocessors.forEach(fn => {
    output = fn(output)
  })
  return output
}

export default compatible
