import exprEval from '@editor/utils/expression-eval'

export default {
  props: {
    readonly: Object
  },

  computed: {
    isReadonly() {
      const { readonly } = this
      const { isStatic } = readonly || {}
      if (readonly) {
        if (isStatic) {
          return readonly.readonly
        }
        return exprEval(
          readonly.readonlyKey,
          this.$store.state,
          (this.rowData || {}).data,
          this.inTableSlot,
          true
        )
      }
      return false
    }
  }
}
