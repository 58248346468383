import { UI_SCHEMAS } from '../../common-schemas'

const { text } = UI_SCHEMAS

export default {
  componentName: 'XAlert',

  label: 'Alert',

  props: {
    type: 'info',
    closeable: true,
    showIcon: true,
    text: {
      text: '消息提示的文案',
      textKey: '',
      isStatic: true
    }
  },

  configs: {
    ui: {
      label: '视觉',
      notFlex: true,
      content: {
        text,

        showIcon: {
          name: '是否显示图标',
          type: 'boolean',
          widget: 'switch'
        },
        type: {
          name: '类型',
          widget: 'select',
          values: [
            { k: '信息', v: 'info' },
            { k: '警告', v: 'warning' },
            { k: '错误', v: 'error' },
            { k: '成功', v: 'success' }
          ]
        }
      }
    },

    events: {
      label: '事件绑定',
      content: {
        handleCloseKey: {
          name: 'onClose',
          argumentsMapping: []
        }
      }
    }
  }
}
