export default {
  componentName: 'XPagination',

  label: 'Pagination',

  props: {},

  configs: {
    ui: {
      label: '视觉',
      content: {}
    },

    data: {
      label: '数据逻辑',
      content: {
        totalKey: {
          name: '数据总数'
        },

        pageSizeKey: {
          name: '每页条目个数'
        },

        currentPageKey: {
          name: '当前页数'
        }
      }
    },

    events: {
      label: '事件绑定',
      content: {
        handlePageChangeKey: {
          name: '页数点击',
          description: '页数变更时触发的事件',
          argumentsMapping: [
            {
              name: 'pageIndex',
              description: '页数索引值'
            }
          ]
        }
      }
    }
  }
}
