<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="$route.path" />
      </keep-alive>
    </transition>
    <template v-for="view in renderViews">
      <Render
        v-show="$route.path === view.path"
        :class="{ active: $route.path === view.path }"
        :key="view.path"
        :view="view"
      />
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Render from '@/pages/render'

export default {
  name: 'AppMain',

  components: {
    Render
  },

  computed: {
    ...mapState(['cachedViews', 'visitedViews']),
    renderViews() {
      console.log('render', this.visitedViews)
      return this.visitedViews.filter(view => view.name !== 'home')
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  display: flex;
  flex-flow: column nowrap;
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fixed-app-header + .app-main {
  padding-top: 90px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 40 */
    min-height: 100vh;
  }

  .fixed-app-header + .app-main {
    padding-top: 90px;
  }
}
</style>
