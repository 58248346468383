<template>
<div class="x-button">
  <el-alert
    :title="genText"
    :type="type"
    :show-icon="showIcon"
    @close="onClose"
  />
</div>
</template>

<script>
import ModelMixin from '../mixins/model-mixin'

export default {
  name: 'XAlert',

  mixins: [ModelMixin],

  props: {
    text: Object,
    type: String,
    showIcon: Boolean,
    closable: Boolean,

    handleCloseKey: String
  },

  computed: {
    genText() {
      const { text } = this
      const { isStatic } = text
      return isStatic
        ? text.text
        : this._IS_EDIT_
          ? '$' + text.textKey
          : this.getValueByPath(text.textKey)
    }
  },

  methods: {
    onClose() {
      window.service[this.handleCloseKey]()
    }
  }
}
</script>
