<template>
  <render-page :projectId="pageId"></render-page>
</template>

<script>
import RenderPage from '@/pages/render/components/render-page'
import * as mutationTypes from '@/store/mutation-types'

export default {
  name: 'Preview',

  components: {
    RenderPage
  },

  data() {
    return {
      pageId: null
    }
  },

  created() {
    // 设置开发环境
    this.$store.commit(mutationTypes.SET_IS_PRODUCTION, false)
    this.initPageIdFromRoute()
  },

  methods: {
    initPageIdFromRoute() {
      const { id } = this.$route.params
      if (id) {
        this.pageId = parseInt(id, 10)
      }
    }
  }
}
</script>

<style>

</style>
