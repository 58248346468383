export default {
  list: ['line', 'histogram', 'bar', 'pie', 'ring', 'waterfall', 'funnel', 'radar', 'sankey', 'map', 'heatmap'],
  configMap: {
    line: {
      labelName: 'Line 折线图'
    },
    histogram: {
      labelName: 'Histogram 柱状图'
    },
    bar: {
      labelName: 'Bar 条形图'
    },
    pie: {
      labelName: 'Pie 饼图'
    },
    ring: {
      labelName: 'Ring 环图'
    },
    waterfall: {
      labelName: 'Waterfall 瀑布图'
    },
    funnel: {
      labelName: 'Funnel 漏斗图'
    },
    radar: {
      labelName: 'Radar 雷达图'
    },
    sankey: {
      labelName: 'Sankey 桑基图'
    },
    map: {
      labelName: 'Map 百度地图'
    },
    heatmap: {
      labelName: 'Heatmap 热力图'
    }
  }
}
