<template>
  <div class="core-page">
    <core-page
      v-if="pageData"
      class="content"
      :components="pageData.componentTree"
      :configs="pageData.configs"
      :dialogs="pageData.dialogs"
      :project="project"
    />
    <project-design :project="project" />
  </div>
</template>

<script>
import CorePage from './core-page'
import installGlobalAPI from './global-api'
import projectDesign from './components/project-design'

export default {
  name: 'core',

  components: {
    CorePage,
    projectDesign
  },

  props: {
    project: {
      type: Object,
      required: true
    }
  },

  methods: {
    initPageData() {
      const { constants, functions: methods } = this.pageData
      installGlobalAPI({
        project: this.project,
        store: this.$store,
        methods,
        constants,
        components: this.$components,
        projectConstants: this.project.constants,
        environment: 'production'
      })
      this.project.globalAPI.init()
    },
    getPageDataFromProject() {
      const pages = []
      const { tree: projectTree } = this.project
      projectTree.forEach(node => {
        if (node.children) {
          node.children.forEach(pageNode => {
            if (pageNode.type === 'page') {
              pages.push(pageNode.pageContent)
            }
          })
        }
      })

      this.pages = pages
      const { path } = this.$route.query
      if (path) {
        const matched = this.pages.filter(
          page => page.path === decodeURIComponent(path)
        )
        if (matched.length) {
          this.pageData = matched[0]
        }
      } else {
        this.pageData = this.pages[0]
      }
    }
  },

  created() {
    this.getPageDataFromProject()
    this.initPageData()
  },

  data() {
    return {
      pages: [],
      pageData: {},
      storeProxy: null
    }
  }
}
</script>

