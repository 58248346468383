import { UI_SCHEMAS, EVENT_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { size, placeholder, clearable, filterable, label } = UI_SCHEMAS
const { bindKey, disabled, optionsKey } = DATA_SCHEMAS
const { handleChangeKey, handleBlurKey, handleFocusKey } = EVENT_SCHEMAS

export default {
  componentName: 'XSelect',

  label: 'Select',

  props: {
    placeholder: '请选择',
    size: 'small',
    label: '条目标签'
  },

  notFlex: true,
  validate: true,
  beTableSlot: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        size,
        label,
        placeholder,
        clearable,
        filterable,
        remote: {
          name: '远程搜索',
          type: 'boolean',
          widget: 'switch'
        }

        // multiple: {
        //   name: '是否多选',
        //   type: 'boolean',
        //   widget: 'switch'
        // }
      }
    },

    data: {
      label: '数据逻辑',
      content: { bindKey, disabled, optionsKey }
    },

    events: {
      label: '事件绑定',
      content: {
        handleChangeKey,
        handleBlurKey,
        handleFocusKey,
        remoteMethodKey: {
          name: '远程搜索方法',
          description: '远程搜索方法',
          argumentsMapping: [
            {
              name: 'query',
              description: '搜索字段'
            }
          ]
        }
      }
    }
  }
}
