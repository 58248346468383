export default {
  componentName: 'XCol',

  label: '布局列',

  hidden: true,

  props: {
    span: 12,
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },

  // 具有插槽的组件必须有 _children 属性，单插槽维数组，多插槽为对象
  _children: [],

  configs: {
    ui: {
      label: '视觉',
      notFlex: true,
      content: {
        span: {
          name: '栅格数',
          type: 'number',
          widget: 'number',
          max: 24
        },

        justifyContent: {
          name: '垂直分布',
          widget: 'select',
          values: [
            { k: '顶部对齐', v: 'flex-start' },
            { k: '底部对齐', v: 'flex-end' },
            { k: '居中对齐', v: 'center' },
            { k: '均匀分布', v: 'space-around' },
            { k: '垂直散列', v: 'space-between' }
          ]
        }
      }
    }
  }
}
