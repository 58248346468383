<script>
import ModelMixin from '../mixins/model-mixin'
import IdMixin from '../mixins/id-mixin'
import DisabledMixin from '../mixins/disabled-mixin'
import ReadonlyMixin from '../mixins/readonly-mixin'

export default {
  name: 'XTimePicker',

  mixins: [ModelMixin, IdMixin, DisabledMixin, ReadonlyMixin],

  methods: {
    onBlur(event) {
      this.handleBlurKey &&
      this.project.globalAPI[this.handleBlurKey](event)
    },

    onFocus(event) {
      this.handleFocusKey &&
      this.project.globalAPI[this.handleFocusKey](event)
    }
  },

  props: {
    size: String,
    placeholder: String,
    displayFormat: String,
    isRange: Boolean,
    label: String,

    defaultValueKey: String,
    valueFormat: String,
    timeRangeKey: String,

    handleBlurKey: String,
    handleFocusKey: String,
    project: Object
  },

  computed: {
    pickerOptions() {
      return {
        selectableRange: this.getValueByPath(this.timeRangeKey),
        format: this.displayFormat
      }
    }
  },

  render() {
    return (
      <div class="x-time-picker x-form-item">
        {this.label ? <div class="x-form-label">{this.label}</div> : ''}
        <el-time-picker
          ref="elCompo"
          size={this.size}
          placeholder={this.placeholder}
          startPlaceholder={this.placeholder}
          endPlaceholder={this.placeholder}
          isRange={this.isRange}
          valueFormat={this.valueFormat}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          pickerOptions={this.pickerOptions}
          value={this.bindValue}
          onInput={this.updateValue}
          disabled={this.isDisabled}
          readonly={this._IS_EDIT_ || this.isReadonly}
          defaultValue={this.getValueByPath(this.defaultValueKey)}
          clearable
        />
        {this.error ? <div class="x-err-msg">校验有误</div> : ''}
      </div>
    )
  }
}
</script>

<style>
.x-time-picker {
  > .el-input {
    width: 100%;
  }
}
</style>
