import generator from './chartComponentGenerator'
import charts from './schemas/charts'

const installer = Vue => {
  charts.list.forEach(name => {
    const component = generator(name)
    Vue.component(component.name, component)
  })
}

export default installer
