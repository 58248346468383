<template>
  <div
    class="code-stage"
    ref="editor"
    @resize.native="resize"
    v-loading="editorLoading"
  >
    <monacoEditor
      ref="monacoEditor"
      :code="code"
      :options="options"
      :changeThrottle="60"
      @mounted="onMounted"
      @codeChange="onCodeChange"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import monacoEditor from 'vue-monaco-editor'
import * as mutationTypes from '@/store/mutation-types'

export default {
  name: 'CodeStage',

  components: {
    monacoEditor
  },

  data() {
    return {
      loading: true,
      code: '// Loading methods...',
      editor: null,
      timer: null,
      options: {
        lineHeight: 26,
        fontFamily: 'monaco',
        fontSize: 15,
        autoIndent: true,
        automaticLayout: true,
        minimap: {
          enabled: false
        }
      }
    }
  },

  computed: {
    ...mapState(['currentFile', 'editorLoading', 'codeFileLoading'])
  },

  watch: {
    'currentFile.data.params'(val) {
      this.editor.setValue(this.currentFile.data.content || '')
    },
    currentFile(file) {
      this.editor.setValue(file.data.content || '')
    }
  },

  created() {
    this.code = this.currentFile.data.content
  },

  methods: {
    onCodeChange(editor) {
      this.code = editor.getValue()
      this.$store.commit(mutationTypes.SET_CURRENT_CODE, this.code)
      this.$store.dispatch('setFileUnsaved')
    },

    handleResize() {
      clearTimeout(this.timer)
      this.timer = setTimeout(_ => {
        this.editor.layout({})
        this.editor.layout()
      }, 500)
    },

    onMounted(editor) {
      this.editor = editor
      this.$store.commit(mutationTypes.SET_EDITOR_STATE, false)

      this.editor.addAction({
        id: 'shortcut',

        label: 'MyLabel',

        keybindings: [
          window.monaco.KeyMod.CtrlCmd | window.monaco.KeyCode.KEY_S
        ],

        run: () => {
          this.$store.dispatch('saveProject')
        }
      })

      window.addEventListener('resize', this.handleResize)
    }
  }
}
</script>

<style lang="scss">
.code-stage {
  height: 100%;
  background-color: #252526;

  > div {
    overflow-y: hidden;
  }

  .mtk1 {
    color: #6fc1ff;
  }

  .mtk5 {
    color: #19f9d8;
  }

  .mtk6 {
    color: #ffb86c;
  }

  .mtk8 {
    color: #ff75b5;
  }
}
</style>
