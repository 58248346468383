import { UI_SCHEMAS, EVENT_SCHEMAS } from '../../common-schemas'

const { size, text } = UI_SCHEMAS
const { handleClickKey, handleClickInTableKey } = EVENT_SCHEMAS

export default {
  componentName: 'XTag',

  label: 'Tag',

  props: {
    size: 'medium',
    type: '',
    text: {
      text: '标签',
      textKey: '',
      isStatic: true
    },
    dataMapping: {
      prop: '',
      mapping: []
    }
  },

  notFlex: true,
  beTableSlot: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        size,
        text,

        type: {
          name: '类型',
          widget: 'select',
          values: [
            { k: '默认', v: '' },
            { k: '信息', v: 'info' },
            { k: '成功', v: 'success' },
            { k: '警告', v: 'warning' },
            { k: '危险', v: 'danger' }
          ]
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: {
        dataMapping: {
          name: '状态映射',
          info: '仅在表格内生效，生效时将覆盖视觉内的设置',
          widget: 'mapping',
          type: 'object'
        }
      }
    },

    events: {
      label: '事件绑定',
      content: {
        handleClickKey
      }
    },

    tableEvents: {
      label: '事件绑定（表格内）',
      content: {
        handleClickInTableKey
      }
    }
  }
}
