var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"x-card",attrs:{"x-slot-component":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.headerVisible),expression:"headerVisible"}],staticClass:"x-card_header"},[_c('h5',{staticClass:"x-card_title"},[_vm._v(_vm._s(_vm.genTitle))]),_vm._v(" "),_c('div',{staticClass:"x-card_actions x-card_slot",attrs:{"x-slot":"actions","x-slot-flow":"row"}},[(_vm.placeholder &&
              !_vm.placeholder.targetUUID &&
              _vm.placeholder.slotParentUUID === _vm.componentData.uuid &&
              _vm.placeholder.slotName === 'actions')?_c('x-placeholder'):_vm._e(),_vm._v(" "),_vm._l((_vm.componentData._children.actions),function(item){return [(_vm.placeholder &&
                _vm.placeholder.targetUUID === item.uuid &&
                _vm.placeholder.position.includes('left'))?_c('x-placeholder',{key:item.uuid}):_vm._e(),_vm._v(" "),_c('x-component',{key:item.uuid,attrs:{"x-slot-component":"","data":item,"uuid":item.uuid,"project":_vm.project}}),_vm._v(" "),(_vm.placeholder &&
                _vm.placeholder.targetUUID === item.uuid &&
                _vm.placeholder.position.includes('right'))?_c('x-placeholder',{key:item.uuid}):_vm._e()]})],2)]),_vm._v(" "),_c('div',{staticClass:"x-card_body"},[_c('div',{staticClass:"x-card_slot",attrs:{"x-slot":"body"}},[(_vm.placeholder &&
              !_vm.placeholder.targetUUID &&
              _vm.placeholder.slotParentUUID === _vm.componentData.uuid &&
              _vm.placeholder.slotName === 'body')?_c('x-placeholder'):_vm._e(),_vm._v(" "),_vm._l((_vm.componentData._children.body),function(item){return [(_vm.placeholder &&
                _vm.placeholder.targetUUID === item.uuid &&
                _vm.placeholder.position.includes('top'))?_c('x-placeholder',{key:item.uuid}):_vm._e(),_vm._v(" "),_c('x-component',{key:item.uuid,attrs:{"x-slot-component":"","data":item,"uuid":item.uuid,"project":_vm.project}}),_vm._v(" "),(_vm.placeholder &&
                _vm.placeholder.targetUUID === item.uuid &&
                _vm.placeholder.position.includes('bottom'))?_c('x-placeholder',{key:item.uuid}):_vm._e()]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }