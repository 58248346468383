import { cloneDeep } from 'lodash-es'

// 切割 page 分成 ui, funcs, constants
export default (pageContent, pageKey) => {
  const { id: pageID, functions } = cloneDeep(pageContent)
  // 主视图文件
  const ui = [
    {
      pageKey,
      nodeKey: pageID + '-ui',
      pageID,
      name: '主视图',
      type: 'ui',
      leaf: true
    }
  ]
  // 方法文件
  const funcs = functions.map(func => {
    return {
      pageKey,
      nodeKey: func.fid,
      name: func.name + '.func',
      pageID: pageID,
      type: 'func',
      leaf: true
    }
  })
  // 常量文件
  const constant = {
    pageKey,
    nodeKey: pageID + '-constant',
    name: 'CONSTANTS',
    pageID: pageID,
    type: 'const',
    leaf: true
  }

  return ui.concat(funcs, constant)
}
