<template>
  <div class="widget-column control">
    <slot v-if="inited">
      <div class="column" v-for="(column, index) in columns" :key="index">
        <div class="column_control-box">
          <div
            class="column_control"
            :class="{ disabled: index === 0 }"
            @click="e => moveCol(index, -1)"
          >
            <i class="el-icon-caret-top"></i>
          </div>
          <div
            class="column_control"
            :class="{ disabled: index === columns.length - 1 }"
            @click="e => moveCol(index, 1)"
          >
            <i class="el-icon-caret-bottom"></i>
          </div>
        </div>
        <div
          class="column_title"
          :class="[onlyClass]"
          :content="index"
          @click="removeColumn(index)"
        ></div>
        <div class="column_row no-gap">
          <div
            class="column_item column_switch"
            :class="{ active: column.isSlot }"
            @click="changeSlotState(index, !column.isSlot)"
          ></div>
        </div>
        <div class="column_row">
          <div class="column_label" content="表头文字"></div>
          <div class="column_item">
            <el-input
              placeholder="请输入"
              v-model="column.label"
              @input="changeSetting"
            ></el-input>
          </div>
        </div>
        <div class="column_row">
          <div class="column_label" content="列宽度"></div>
          <div class="column_item">
            <el-input-number
              :min="0"
              v-model="column.width"
              controls-position="right"
              @input="changeSetting"
            ></el-input-number>
          </div>
        </div>
        <div class="column_row">
          <div
            class="column_label"
            :content="column.isSlot ? '' : 'prop'"
          ></div>
          <div class="column_item">
            <el-input
              placeholder="请输入"
              :disabled="column.isSlot"
              v-model="column.prop"
              @input="changeSetting"
            ></el-input>
          </div>
        </div>
      </div>
    </slot>

    <div class="column_action">
      <div
        class="add-column small-text"
        small-content="+ add column"
        @click="addColumn(false)"
      ></div>

      <div
        class="add-column small-text slot"
        small-content="+ add slot"
        @click="addColumn(true)"
      ></div>
    </div>
  </div>
</template>

<script>
import { clone, cloneDeep } from 'lodash-es'
import * as mutationTypes from '@/store/mutation-types'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'WidgetColumn',

  props: {
    value: Array
  },

  created() {
    this.columns = clone(this.value)
    this.inited = true
  },

  watch: {
    value(val) {
      this.columns = clone(val)
    }
  },

  data() {
    return {
      inited: false,
      columns: []
    }
  },

  computed: {
    ...mapGetters(['onlyActiveComponent']),

    onlyClass() {
      return { only: this.columns.length === 1 }
    }
  },

  methods: {
    addColumn(isSlot) {
      this.columns.push({
        label: `新表头-${this.columns.length + 1}`,
        prop: 'prop_new',
        isSlot
      })

      this.$nextTick(_ => {
        document.querySelector('.component-config').scrollTop += 131
      })
    },

    moveCol(index, offset) {
      const { columns, onlyActiveComponent } = this
      const length = columns.length
      const targetIndex = index + offset
      if (
        (index === 0 && offset === -1) ||
        (index === length - 1 && offset === 1)
      ) {
        return
      }
      const column = columns.splice(index, 1)[0]
      columns.splice(targetIndex, 0, column)

      const _children = cloneDeep(onlyActiveComponent._children)

      _children.forEach(comp => {
        if (comp.tableSlotIndex === index + 1) {
          comp.tableSlotIndex = targetIndex + 1
        } else if (comp.tableSlotIndex === targetIndex + 1) {
          comp.tableSlotIndex = index + 1
        }
      })

      this.$store.commit(mutationTypes.MODIFY_COMPONENT_CHILHREN, _children)

      this.changeSetting()
    },

    removeColumn(index) {
      if (this.columns.length === 1) return
      this.columns.splice(index, 1)
      this.changeSetting()
    },

    changeSlotState(i, val) {
      this.columns[i].isSlot = val
      this.changeSetting()
    },

    changeSetting() {
      this.$emit('input', this.columns)
    }
  }
}
</script>

<style lang="scss">
/* 样式在 scss/components/widget-column 里引入 */
</style>
