import { UI_SCHEMAS, EVENT_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { placeholder, max, min, label } = UI_SCHEMAS
const { bindKey, disabled, readonly } = DATA_SCHEMAS
const { handleChangeKey, handleBlurKey, handleFocusKey } = EVENT_SCHEMAS

export default {
  componentName: 'XTextarea',

  label: 'Textarea',

  props: {
    size: 'small',
    placeholder: '请输入',
    label: '条目标签'
  },

  validate: true,
  notFlex: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        placeholder,
        label,
        max,
        min,

        rows: {
          name: '行数',
          type: 'number',
          widget: 'number'
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: { bindKey, disabled, readonly }
    },

    events: {
      label: '事件绑定',
      content: { handleChangeKey, handleBlurKey, handleFocusKey }
    }
  }
}
