<template>
  <div class="editor-ui-stage">
    <Page :component-tree="currentFile.data.componentTree" />

    <div class="draggingComponent" v-if="draggingComponent" :style="draggingPosition">
      <span>{{draggingComponent.label}}</span>
    </div>

    <div class="trash" :class="{'is-active': readyToRemoveComponent, 'is-show': draggingComponent}">
      <i class="iconfont el-icon-delete" />
      <p class="trash_text">拖拽到此处删除</p>
    </div>
  </div>
</template>

<script>
import Page from './page'
import HotKeys from 'hotkeys-js'
import { mapState } from 'vuex'

export default {
  components: {
    Page
  },

  computed: {
    ...mapState([
      'readyToRemoveComponent',
      'draggingComponent',
      'draggingPosition',
      'currentFile'
    ])
  },

  mounted() {
    HotKeys.setScope('UIEditor')
  }
}
</script>

<style lang="scss" scoped>
.editor-ui-stage {
  position: relative;
  background-color: #f0f2f5;
  height: 100%;

  .draggingComponent {
    height: 26px;
    line-height: 26px;
    background: #333;
    color: #bbb;
    padding-left: 24px;
    padding-right: 15px;
    margin-bottom: 0;
    position: fixed;
    width: 170px;
    background-color: rgb(103, 103, 104);
    cursor: move;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.08);
    font-size: 12px;
    z-index: 999999999;
    &::before {
      content: '';
      position: absolute;
      left: 11px;
      top: 10px;
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #ddd;
    }
  }

  .trash {
    position: fixed;
    bottom: 0;
    left: 200px;
    right: 240px;
    z-index: 999;
    display: flex;
    padding: 10px;
    transform: translateY(100%);
    transition: 0.12s ease-out;
    flex-flow: row;
    justify-content: center;
    color: #fff;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    background: hsla(0, 100%, 50%, 0.56);

    &.is-active {
      background: hsla(0, 100%, 50%, 0.76);
    }

    &.is-show {
      transform: none;
    }

    .iconfont {
      display: block;
      font-size: 22px;
      padding-right: 5px;
    }

    &_text {
      padding-top: 5px;
    }
  }
}
</style>
