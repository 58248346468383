/**
 * 控制 x-component 表现的一些配置，例如显示隐藏
 */
import { UI_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { visible, align } = UI_SCHEMAS
const { id } = DATA_SCHEMAS

export default {
  ui: {
    content: {
      visible,
      align
    }
  },
  data: {
    content: {
      id,
      validate: {
        name: '校验',
        widget: 'select',
        placeholder: '选择校验规则',
        values: [
          { k: '非空', v: '/\\S+/g' },
          {
            k: '手机号',
            v:
              '/^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/'
          },
          {
            k: '邮箱',
            v: '/\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*/'
          },
          { k: '英文和数字', v: '/^[a-z0-9]+$/i' }
        ]
      }
    }
  }
}
