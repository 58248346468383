/**
 * 主要用来兼容老版本的全局变量，以前导航上会挂载一些函数需要运行
 */

import { API_HOST } from '@/api/constants'
import goLogin from './goLogin'
import model from './model'

window.filePath = `${API_HOST}/saas/action/fileapi/`
window.baseUrl = `${API_HOST}/saas/`
window.wxUrl = `${API_HOST}/w/`
window.crmUrl = `${API_HOST}/crm/`
window.marketingUrl = `${API_HOST}/mrkt/`
window.ucUrl = `${API_HOST}/saas/`
window.saveUrl = 'action/webtable/save'
window.addNewUrl = 'action/webtable/addnew'
window.model = model
window.go_login = goLogin
