export default {
  componentName: 'Xdefault',

  // 伪组件标志
  fake: true,

  label: '默认',

  hidden: true,

  configs: {
    ui: {
      label: '配置'
    }
  }
}
