import { DATA_SCHEMAS } from '../../common-schemas'

const { bindKey } = DATA_SCHEMAS

export default {
  componentName: 'XDescriptionList',

  label: 'DescriptionList',

  props: {
    columns: 3,
    gutter: 20,
    nameKey: 'name',
    contentKey: 'content',
    title: {
      title: '信息标题',
      titleKey: '',
      isStatic: true
    }
  },

  notFlex: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        title: {
          name: '标题',
          type: 'object',
          dynamicOption: {
            dynamic: { keyName: 'titleKey' },
            static: { keyName: 'title', placeholder: '请输入标题' },
            isStatic: true
          },
          widget: 'dynamicOrStatic'
        },
        columns: {
          name: '列数',
          type: 'number',
          widget: 'number'
        },
        gutter: {
          name: '列距',
          type: 'number',
          widget: 'number'
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: {
        bindKey,
        loadingKey: {
          name: 'Loading 标志'
        }
      }
    }
  }
}
