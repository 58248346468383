<template>
<div class="x-tag">
  <el-tag :type="resultType" :size="size" @click="onClick">{{resultText}}</el-tag>
</div>
</template>

<script>
import exprEval from '@editor/utils/expression-eval'
import ModelMixin from '../mixins/model-mixin'
import basicComponentMixin from '../mixins/basic-component.mixin'

export default {
  name: 'XTag',

  mixins: [ModelMixin, basicComponentMixin],

  props: {
    text: Object,
    type: String,
    size: String,
    dataMapping: Object,

    handleClickKey: String,
    handleClickInTableKey: String,

    inTableSlot: Boolean,
    rowData: {
      type: Object,
      default: {
        data: {}
      }
    },
    project: Object
  },

  data() {
    return {}
  },

  computed: {
    isEdit() {
      return this._IS_EDIT_
    },

    enableMapping() {
      return this.inTableSlot && this.dataMapping.mapping.length
    },

    correspondingValue() {
      const resource = this.rowData.data || {}
      // 对应值
      let value = resource[this.dataMapping.prop]
      value = value === undefined ? '' : value
      return this.inTableSlot ? value : null
    },

    resultText() {
      const { dataMapping, correspondingValue, enableMapping, isEdit } = this
      const originText = this.generatedText()
      return enableMapping
        ? isEdit
          ? `<${dataMapping.prop}>`
          : (dataMapping.mapping.find(e => e.value === correspondingValue.toString()) || {}).text ||
            `未映射值: ${correspondingValue}`
        : originText
    },

    resultType() {
      const { dataMapping, correspondingValue, type, enableMapping, isEdit } = this

      return enableMapping
        ? isEdit
          ? 'info'
          : (dataMapping.mapping.find(e => e.value === correspondingValue.toString()) || {}).type ||
            'danger'
        : type
    }
  },

  methods: {
    generatedText() {
      const { isEdit, text, rowData, inTableSlot } = this
      const { isStatic } = text
      return isStatic
        ? text.text
        : isEdit
          ? `$${text.textKey}`
          : exprEval(text.textKey, this.$store.state, rowData.data, inTableSlot)
    },

    onClick(event) {
      if (this.inTableSlot) {
        this.handleClickInTableKey &&
          this.project.globalAPI[this.handleClickInTableKey](this.rowData, event)
      } else {
        this.handleClickKey && this.project.globalAPI[this.handleClickKey](event)
      }
    }
  }
}
</script>

<style lang="scss">
.x-tag {
}
</style>
