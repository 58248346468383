<template>
  <div class="dialog-stage">
    <XDialog
      v-bind="currentDialog.props"
      :component-data="currentDialog"
      @close-dialog="close"
      :project="projectOrigin"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as types from '@/store/mutation-types'

export default {
  name: 'DialogStage',
  computed: {
    ...mapState(['currentDialog', 'projectOrigin'])
  },
  methods: {
    close() {
      this.$store.commit(types.SET_CURRENT_DIALOG, null)
    }
  }
}
</script>

<style>
  .dialog-stage {
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-content: center;
  }
</style>
