import axios from 'axios'
import { message, msgbox, loading } from './utils'

axios.defaults.withCredentials = true

export default {
  $store: true,
  $http: axios,
  $methods: true,
  $project: true,
  $components: true,
  $message: message,
  $msgbox: msgbox,
  $loading: loading,
  $constants: true,
  $projectConstants: true,
  $tableRef: true,
  $clickButtonPopover: true
}
