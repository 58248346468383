import { mapState } from 'vuex'
import XPlaceholder from '@/pages/editor/components/x-placeholder'

export default {
  components: {
    XPlaceholder
  },
  computed: {
    ...mapState(['currentFile', 'placeholder'])
  },
  props: {
    componentData: {
      type: Object,
      required: true
    },
    uuid: String,
    project: Object
  }
}
