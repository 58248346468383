<script>
import ModelMixin from '../mixins/model-mixin'
import IdMixin from '../mixins/id-mixin'
import DisabledMixin from '../mixins/disabled-mixin'
import ReadonlyMixin from '../mixins/readonly-mixin'

export default {
  name: 'XTextarea',

  mixins: [ModelMixin, IdMixin, DisabledMixin, ReadonlyMixin],

  methods: {
    onBlur(event) {
      this.handleBlurKey &&
      this.project.globalAPI[this.handleBlurKey](event)
    },

    onFocus(event) {
      this.handleFocusKey &&
      this.project.globalAPI[this.handleFocusKey](event)
    }
  },

  props: {
    placeholder: String,
    max: Number,
    min: Number,
    rows: Number,
    label: String,

    disabledKey: Boolean,
    readonlyKey: Boolean,

    handleBlurKey: String,
    handleFocusKey: String,
    project: Object
  },

  render() {
    return (
      <div class="x-textarea x-form-item">
        {this.label ? <div class="x-form-label">{this.label}</div> : ''}
        <el-input
          ref="elCompo"
          type="textarea"
          value={this.bindValue}
          onInput={this.updateValue}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          rows={this.rows}
          max={this.max}
          min={this.min}
          placeholder={this.placeholder}
          disabled={this.isDisabled}
          readonly={this._IS_EDIT_ || this.isReadonly}
        />
        {this.error ? <div class="x-err-msg">校验有误</div> : ''}
      </div>
    )
  }
}
</script>
