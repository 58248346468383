<template>
<div class="x-image">
  <div v-if="!loaded" class="x-image_placeholder" :style="placeholderStyle">
    <i v-if="_IS_EDIT_" class="el-icon-picture"></i>
    <i v-else-if="!_IS_EDIT_ && !error" class="el-icon-loading"></i>
    <slot v-if="error">
      <i class="el-icon-circle-close-outline"></i>
      <p class="x-image_error">加载图片失败</p>
    </slot>
  </div>
  <img v-if="!_IS_EDIT_" v-show="loaded" :src="genSrc" :style="imageStyle" class="x-image_inner" />
</div>
</template>

<script>
import exprEval from '@editor/utils/expression-eval'
import ModelMixin from '../mixins/model-mixin'
import basicComponentMixin from '../mixins/basic-component.mixin'

const isAuto = str => {
  return str === 'auto' || str === ''
}

export default {
  name: 'XImage',

  mixins: [ModelMixin, basicComponentMixin],

  props: {
    src: Object,
    width: String,
    height: String,

    inTableSlot: Boolean,
    rowData: {
      type: Object,
      default: () => {
        return {
          data: {}
        }
      }
    }
  },

  mounted() {
    if (!this._IS_EDIT_) {
      const img = this.$el.querySelector('.x-image_inner')
      img.addEventListener(
        'load',
        _ => {
          this.loaded = true
        },
        { once: true }
      )

      img.addEventListener(
        'error',
        _ => {
          this.error = true
        },
        { once: true }
      )
    }
  },

  data() {
    return {
      loaded: false,
      error: false
    }
  },

  computed: {
    genSrc() {
      const { src, rowData, inTableSlot } = this
      const { isStatic } = src
      return isStatic ? src.src : exprEval(src.srcKey, this.$store.state, rowData.data, inTableSlot)
    },

    placeholderStyle() {
      const { width, height } = this
      return {
        width: isAuto(width) ? '200px' : this.width,
        height: isAuto(height) ? '80px' : this.height
      }
    },

    imageStyle() {
      return {
        width: this.width,
        height: this.height
      }
    }
  }
}
</script>

<style lang="scss">
.x-image {
  display: flex;
  overflow: hidden;

  &_placeholder {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background: #eee;
    border-radius: 3px;

    i {
      color: #777;
    }
  }

  &_inner {
    max-width: 100%;
  }

  &_error {
    font-size: 12px;
    color: #555;
    padding-top: 10px;
  }
}
</style>
