export default {
  componentName: 'XTable',

  label: 'Table',

  _children: [],

  props: {
    columns: [
      {
        label: '表头1',
        prop: 'prop1',
        isSlot: false
      },
      {
        label: '表头2',
        prop: 'prop2',
        isSlot: false
      },
      {
        label: '表头3',
        prop: 'prop3',
        isSlot: true
      }
    ]
  },

  notFlex: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        height: {
          name: '高度',
          type: 'number',
          info: '当设置为 0 时，高度自适应',
          widget: 'number'
        },

        stripe: {
          name: '是否斑马纹',
          type: 'boolean',
          widget: 'switch'
        },

        border: {
          name: '是否带有纵向边框',
          type: 'boolean',
          widget: 'switch'
        },

        selectable: {
          name: '可多选的',
          type: 'boolean',
          widget: 'switch'
        },

        thAlignCenter: {
          name: '表头文字居中',
          type: 'boolean',
          widget: 'switch'
        },

        columns: {
          name: '列设置',
          type: 'array',
          widget: 'tableColumn'
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: {
        dataKey: {
          name: '数据源'
        },

        loadingKey: {
          name: 'Loading 标志'
        },

        selectionKey: {
          name: '存储当前的选择项'
        }
      }
    },

    events: {
      label: '事件绑定',
      content: {
        handleSelectKey: {
          name: 'select-change',
          info: '当选择项发生变化时会触发该事件',
          argumentsMapping: [
            {
              name: 'selection',
              description: '表格选中的行'
            }
          ]
        }
      }
    }
  }
}
