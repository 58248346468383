import Vue from 'vue'

export const isOnlyOneSlot = childrenData => {
  return Object.prototype.toString.call(childrenData).includes('Array')
}

// 判断组件树中是否包含图表，是否包含高德地图
export const analysePage = page => {
  let useChart = false
  let bothFound = false
  const checkTree = tree => {
    if (tree.length) {
      tree.some(node => {
        const comName = node.componentName
        if (comName.indexOf('XChart') !== -1) {
          useChart = true
        }
        if (useChart) {
          bothFound = true
          return true
        } else {
          const children = node._children
          if (children && children.length) {
            return checkTree(children)
          } else if (children && children.actions) {
            return checkTree(children.actions) || checkTree(children.body)
          }
        }
        return false
      })
    }
    return bothFound
  }
  checkTree(page.componentTree)
  return {useChart}
}

// 查询组件是否注册
export const isRegisteredComponent = component => {
  return component in Vue.options.components
}

export const isRealNumber = input => /^\d*\.?\d*$/.test(input)
