import { UI_SCHEMAS, EVENT_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { size, text } = UI_SCHEMAS
const { disabled } = DATA_SCHEMAS
const { handleClickKey, handleClickInTableKey } = EVENT_SCHEMAS

export default {
  componentName: 'XButton',

  label: 'Button',

  props: {
    size: 'small',
    type: '',
    text: {
      text: '按钮',
      textKey: '',
      isStatic: true
    }
  },

  notFlex: true,
  beTableSlot: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        size,
        text,

        isRound: {
          name: '是否圆角',
          type: 'boolean',
          widget: 'switch'
        },
        icon: {
          name: 'icon',
          widget: 'icons'
        },
        type: {
          name: '类型',
          widget: 'select',
          values: [
            { k: '默认', v: '' },
            { k: '醒目', v: 'primary' },
            { k: '成功', v: 'success' },
            { k: '警告', v: 'warning' },
            { k: '危险', v: 'danger' },
            { k: '信息', v: 'info' },
            { k: '纯文本', v: 'text' }
          ]
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: {
        disabled,

        loadingKey: {
          name: 'Loading 标志'
        }
      }
    },

    events: {
      label: '事件绑定',
      content: {
        handleClickKey
      }
    },

    tableEvents: {
      label: '事件绑定（表格内）',
      content: {
        handleClickInTableKey
      }
    }
  }
}
