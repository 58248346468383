<template>
  <div class="editor-components">
    <div class="editor-components_gap">基础组件</div>

    <ul class="editor-components_box">
      <template v-for="item in componentData">
        <li
          v-if="!item.hidden"
          :key="item.label"
          class="editor-components_unit"
          @mousedown="onDragstart($event, item)"
        >{{ item.label }}</li>
      </template>
    </ul>

    <div class="editor-components_gap">预设</div>

    <ul class="editor-components_box">
      <template v-for="preset in presetsData">
        <li
          :key="preset.name"
          v-if="!preset.hidden"
          class="editor-components_unit is-full"
          @mousedown="onDragstart($event, preset, true)"
        >{{ preset.label }}</li>
      </template>
    </ul>
  </div>
</template>

<script>
import { omit } from 'lodash-es'
import dragMixin from '../mixins/drag.mixin'
import components from '@/pages/editor/components/basicComponents/schemas'
import presetsData from '@/pages/editor/components/basicComponents/presets/presets'

export default {
  name: 'BasicComponent',

  mixins: [dragMixin],

  data() {
    return {
      presetsData
    }
  },

  methods: {
    handleMousedown(event, component, isPreset) {
      component = JSON.parse(JSON.stringify(component))
      this.onDragstart(event, component, isPreset)
    }
  },

  computed: {
    componentData() {
      return components.map(component => {
        return omit(component, ['configs'])
      })
    }
  }
}
</script>

<style lang="scss">
/* 样式在 scss/components/components 里引入 */
</style>
