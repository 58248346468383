<template>
  <div class="x-page" :style="pageStyle">
    <x-component
      v-for="item in components"
      :key="item.uuid"
      :uuid="item.uuid"
      :data="item"
      :project="project"
    >
    </x-component>
    <x-dialog
      v-for="dialog in dialogs"
      v-bind="dialog.props"
      :component-data="dialog"
      :key="dialog.uuid"
      :project="project"
    >
    </x-dialog>
  </div>
</template>

<script>
export default {
  name: 'corePage',

  props: {
    components: Array,
    configs: Object,
    dialogs: Array,
    project: Object
  },

  computed: {
    pageStyle() {
      const style = {}
      const { padding, background } = this.configs || { padding: 10 }
      style.padding = (padding < 0 ? 0 : padding) + 'px'
      if (background) {
        style.background = background
      }
      return style
    }
  }
}
</script>

<style lang="scss" scoped>
.x-page {
  height: 100%;
  overflow: auto;

  /deep/ {
    .tooltip {
      position: relative;

      &::after {
        display: block;
        position: absolute;
        left: -10px;
        transform: translateX(-100%) scale(0.7);
        background: #e4e4e4;
        padding: 3px 5px;
        font-size: 12px;
        white-space: nowrap;
      }

      &::before {
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &:hover {
        &::before {
          content: '';
        }

        &::after {
          content: attr(tooltip);
        }
      }
    }

    .x-form {
      &-item {
        display: flex;
        align-items: center;
      }

      &-label {
        min-width: 70px;
        padding-left: 10px;
        box-sizing: border-box;
        word-break: break-all;
        padding-right: 10px;
        font-size: 14px;
        color: #666;
        text-align: right;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    .flex-shink-0 {
      flex-shrink: 0;
    }

    .x-err-msg {
      position: absolute;
      bottom: -2px;
      right: 5px;
      font-size: 12px;
      color: rgb(226, 84, 84);
      transform: translateY(100%);
    }
  }
}
</style>
