<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <span
          v-if="item.redirect === 'noRedirect' || index === levelList.length - 1"
          class="no-redirect"
          >{{ item.meta.title }}</span
        >
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { mapState } from 'vuex'
import pathToRegexp from 'path-to-regexp'
import bus from '@/utils/bus'

export default {
  name: 'Breadcrumb',

  data() {
    return {
      levelList: null
    }
  },

  computed: {
    ...mapState(['renderRoute'])
  },

  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith('/redirect/')) {
        return
      }
      this.getBreadcrumb()
    }
  },

  created() {
    this.getBreadcrumb()
  },

  mounted() {
    bus.$on('getBreadcrumb', this.getBreadcrumb)
  },

  beforeDestroy() {
    bus.$off('getBreadcrumb', this.getBreadcrumb)
  },

  methods: {
    getMatched(route) {
      const matched = []
      let currentRoute = route
      matched.unshift(currentRoute)
      while (currentRoute.parent) {
        matched.unshift(currentRoute.parent)
        currentRoute = currentRoute.parent
      }
      return matched
    },
    getBreadcrumb() {
      console.log('进入面包屑')
      console.log(this.$route.matched)
      let matched = this.$route.matched.filter(
        item => item.meta && item.meta.title
      )
      console.log('这是面包屑', matched)
      const first = matched[0]

      if (!this.isHome(first)) {
        const route = this.renderRoute
        matched = this.getMatched(route)
      }

      const matchedList = matched.filter(
        item => item.meta && item.meta.title && item.meta.breadcrumb !== false
      )
      console.log(matchedList)
      // 加入最后一个的 history
      const lastLevelItem = matchedList[matchedList.length - 1]
      const historyList = lastLevelItem.history.map(({ url, title }) => ({
        path: `/tmp/${Math.random()}`,
        url: url,
        meta: {
          title
        }
      }))
      this.levelList = [lastLevelItem, ...historyList]
    },
    isHome(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === 'home'.toLocaleLowerCase()
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route
      const toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink({ url }) {
      // 清楚 view 历史
      bus.$emit('goToView', url)
      this.getBreadcrumb()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/var.scss";

.app-breadcrumb.el-breadcrumb {
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;

  .no-redirect {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
    cursor: text;
  }

  a {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.45);

    &:hover {
      color: $primary;
    }
  }
}
</style>
