<template>
  <div class="x-card" x-slot-component>
    <div class="x-card_header" v-show="headerVisible">
      <h5 class="x-card_title">{{genTitle}}</h5>
      <div class="x-card_actions x-card_slot" x-slot="actions" x-slot-flow="row">
        <x-placeholder
          v-if="placeholder &&
                !placeholder.targetUUID &&
                placeholder.slotParentUUID === componentData.uuid &&
                placeholder.slotName === 'actions'" />
        <template v-for="item in componentData._children.actions">
          <x-placeholder
            :key="item.uuid"
            v-if="placeholder &&
                  placeholder.targetUUID === item.uuid &&
                  placeholder.position.includes('left')" />
          <x-component
            x-slot-component
            :key="item.uuid"
            :data="item"
            :uuid="item.uuid"
            :project="project"
          >
          </x-component>
          <x-placeholder
            :key="item.uuid"
            v-if="placeholder &&
                  placeholder.targetUUID === item.uuid &&
                  placeholder.position.includes('right')" />
        </template>
      </div>
    </div>
    <div class="x-card_body">
      <div class="x-card_slot" x-slot="body">
        <x-placeholder
          v-if="placeholder &&
                !placeholder.targetUUID &&
                placeholder.slotParentUUID === componentData.uuid &&
                placeholder.slotName === 'body'" />
        <template v-for="item in componentData._children.body">
          <x-placeholder
            :key="item.uuid"
            v-if="placeholder &&
                  placeholder.targetUUID === item.uuid &&
                  placeholder.position.includes('top')" />
          <x-component
            x-slot-component
            :key="item.uuid"
            :data="item"
            :uuid="item.uuid"
            :project="project"
          >
          </x-component>
          <x-placeholder
            :key="item.uuid"
            v-if="placeholder &&
                  placeholder.targetUUID === item.uuid &&
                  placeholder.position.includes('bottom')" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import basicComponentMixin from '../mixins/basic-component.mixin'
import ModelMixin from '../mixins/model-mixin'

export default {
  name: 'XCard',

  mixins: [basicComponentMixin, ModelMixin],

  props: {
    title: Object
  },

  computed: {
    headerVisible() {
      return this._IS_EDIT_ || this.genTitle || this.$slots.actions
    },
    genTitle() {
      const { title } = this
      const { isStatic } = title
      return isStatic
        ? title.text
        : this._IS_EDIT_
          ? '$' + title.textKey
          : this.getValueByPath(title.textKey)
    }
  }
}
</script>

<style lang="scss">
.x-card {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  background: #fff;
  border-radius: 3px;
  flex-flow: columns;

  &_header {
    position: relative;
    padding: 14px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      height: 1px;
      left: 24px;
      right: 24px;
      border-bottom: 1px solid #eee;
    }
  }

  &_title {
    font-size: 18px;
    font-weight: bold;
    color: #444;
  }

  &_body {
    padding: 24px 30px;
  }

  &_slot {
    > .x-component {
      z-index: 3;
      width: auto;
    }
  }

  &_actions {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #ddd;
    font-size: 12px;
    height: 35px;
    min-height: 35px;

    .x-component + .x-component {
      margin-left: 5px;
      margin-top: 5px;
    }

    &::after {
      content: '';
      position: absolute;
      top: -20px;
      bottom: -34px;
      left: -100px;
      right: 0;
    }
  }
}

.editor {
  .x-card {
    &_slot {
      border: 1px dashed #ddd;
      min-height: 40px;
    }

    &_actions {
      border: 1px dashed #ddd;
      min-width: 100px;

      .x-placeholder {
        height: 100%;
        min-height: 35px;
        min-width: 60px;
        border: none;
      }
    }
  }
}
</style>
