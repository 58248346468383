export default {
  input: {
    component: 'el-input'
  },
  icons: {
    component: 'widget-icon'
  },
  radio: {
    component: 'el-radio-group',
    children: 'el-radio-button'
  },
  switch: {
    component: 'el-switch'
  },
  select: {
    component: 'el-select',
    children: 'el-option'
  },
  number: {
    component: 'el-input-number'
  },
  tableColumn: {
    component: 'widget-column'
  },
  tabPane: {
    component: 'widget-tab-pane'
  },
  color: {
    component: 'el-color-picker'
  },
  dynamicOrStatic: {
    component: 'widget-bind'
  },
  event: {
    component: 'widget-event'
  },
  mapping: {
    component: 'widget-mapping'
  }
}
