<template>
<span class="widget-event">
  <el-button
    class="event-add"
    v-if="!value"
    type="text"
    @click="$emit('addEventHandler', item, formKey)"
  >
    新增
  </el-button>
  <template v-else>
    <span class="handler-name">{{value}}</span>
    <el-button
      type="text"
      class="event-delete"
      @click="$emit('removeEventHandler', formKey)"
    >删除</el-button>
  </template>
</span>
</template>

<script>
export default {
  name: 'WidgetEvent',

  props: {
    value: String,
    item: Object,
    formKey: String
  },

  created() {
    this.inited = true
  },

  data() {
    return {
      inited: false
    }
  },

  computed: {},

  methods: {
    changeSetting() {
      this.$emit('input', '')
    }
  }
}
</script>

<style lang="scss">
.widget-event {
  position: relative;

  .handler-name {
    color: #aaa;
  }
}
</style>

