<template>
  <div>
    <i class="el-icon-warning"></i>
    {{ displayText }}
  </div>
</template>

<script>
export default {
  name: 'Fallback',

  props: {
    componentData: Object
  },

  computed: {
    displayText() {
      const name = this.componentData.label || '当前'
      return `${name} 组件已经不被支持，并且在运行时不会显示。你可以随时删除它。`
    }
  }
}
</script>

<style scoped>
div i {
  margin-right: 6px;
}
div {
  text-align: center;
  background: #efefef;
  font-size: 13px;
  padding: 12px 4px;
  color: rgba(247, 8, 8, 0.39);
  border: 1px dashed rgba(247, 8, 8, 0.2);
}
</style>

