<template>
  <div class="x-base-edit-table-wrapper">
    <div v-for="(column, index) in columns" :key="index">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import basicComponentMixin from '../mixins/basic-component.mixin'
import ModelMixin from '../mixins/model-mixin'
import IdMixin from '../mixins/id-mixin'

const editComponentMap = {}

export default {
  name: 'XBaseEditTable',

  mixins: [ModelMixin, IdMixin, basicComponentMixin],

  props: {
    height: {
      type: Number,
      default: 0
    },

    dataSource: Number
  },

  data() {
    return {
      columns: []
    }
  },

  computed: {
    ...mapState(['projectDataSources'])
  },

  mounted() {
    this.initBaseTable()
  },

  methods: {
    initBaseTable() {
      if (this.dataSource) {
        const choosedSource = this.projectDataSources.filter(
          dataSource => dataSource.TABLEID === this.dataSource
        )
        if (!choosedSource) {
          return
        }

        const dataSource = choosedSource[0]
        // 过滤 EDITOR 没有值的列（代表不显示）和 span 值（代表只读文本）
        this.columns = dataSource.columns.filter(column => {
          // 默认值也许是需要执行才能得到的
          if (column.DEFAULT_VALUE) {
            try {
              /* eslint-disable no-eval,no-script-url */
              column.DEFAULT_VALUE_RESULT = eval(column.DEFAULT_VALUE)
              /* eslint-enable */
            } catch (e) {
              column.DEFAULT_VALUE_RESULT = undefined
            }
          }
          return column.EDITOR && column.EDITOR !== 'span'
        })
      }
    }
  },

  watch: {
    dataSource() {
      this.initBaseTable()
    }
  }
}
</script>

<style lang="scss" scoped>
.x-base-edit-table {
}
</style>
