var render, staticRenderFns
import script from "./tab.vue?vue&type=script&lang=js&"
export * from "./tab.vue?vue&type=script&lang=js&"
import style0 from "./tab.vue?vue&type=style&index=0&id=731b5bc9&lang=scss&scoped=true&"
import style1 from "./tab.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "731b5bc9",
  null
  
)

export default component.exports