import { firstUpperCase } from '@/pages/editor/utils/component-name'
import { get } from 'lodash-es'
import { isRealNumber } from '@editor/utils'
import sourceLoader from '@/utils/source-loader'

const generator = name => {
  return {
    name: `XChart${firstUpperCase(name)}`,

    data() {
      return {
        sourceLoading: true
      }
    },

    created() {
      sourceLoader([
        '//cdn.jsdelivr.net/npm/v-charts@1.12.1/lib/style.min.css',
        '//cdn.jsdelivr.net/npm/echarts@4.3.0/dist/echarts.min.js',
        '//cdn.jsdelivr.net/npm/v-charts@1.12.1/lib/index.min.js'
      ]).then(() => {
        this.sourceLoading = false
      })
    },

    props: {
      title: String,
      height: {
        type: String,
        default: '300'
      },
      tooltipVisible: Boolean,
      legendVisible: Boolean,
      legendPosition: String,
      beforeConfigKey: String,
      afterConfigKey: String,
      afterSetOptionKey: String,
      afterSetOptionOnceKey: String,
      dataBindKey: String,
      settingBindKey: String,
      chartDemoData: Object,
      gridBindKey: String,
      colorsBindKey: String,
      loadingKey: String,
      eventMapBindKey: String,
      project: Object
    },

    computed: {
      defaultData() {
        return this.chartDemoData.defaultData || {}
      },

      defaultSettings() {
        return this.chartDemoData.defaultSettings || {}
      },

      chartData() {
        return this._IS_EDIT_
          ? this.defaultData
          : get(this.$store.state, this.dataBindKey, {})
      },

      chartSettings() {
        return this._IS_EDIT_
          ? this.defaultSettings
          : get(this.$store.state, this.settingBindKey, {})
      },

      realHeight() {
        if (isRealNumber(this.height)) {
          return `${this.height}px`
        }
        return this.height
      },

      dataEmpty() {
        if (
          this.chartData &&
          'rows' in this.chartData &&
          this.chartData.rows.length
        ) {
          return false
        }
        return true
      },

      grid() {
        return get(this.$store.state, this.gridBindKey, {})
      },

      colors() {
        return get(this.$store.state, this.colorsBindKey, null)
      },

      isLoading() {
        return get(this.$store.state, this.loadingKey, false)
      },

      eventMap() {
        return get(this.$store.state, this.eventMapBindKey, {})
      }
    },

    watch: {
      realHeight() {
        this.refresh()
      }
    },

    methods: {
      refresh() {
        this.$nextTick(() => {
          this.$refs.chart.echarts.resize()
        })
      },
      beforeConfig(data) {
        if (!this._IS_EDIT_) {
          if (this.beforeConfigKey) {
            return this.project.globalAPI[this.beforeConfigKey](data)
          } else {
            return data
          }
        }
        return data
      },
      afterConfig(options) {
        if (!this._IS_EDIT_) {
          if (this.afterConfigKey) {
            return this.project.globalAPI[this.afterConfigKey](options)
          } else {
            return options
          }
        }
        return options
      },
      afterSetOption(ec) {
        if (!this._IS_EDIT_ && this.afterSetOptionKey) {
          this.project.globalAPI[this.afterSetOptionKey](ec)
        }
      },
      afterSetOptionOnce(ec) {
        if (!this._IS_EDIT_ && this.afterSetOptionOnceKey) {
          this.project.globalAPI[this.afterSetOptionOnceKey](ec)
        }
      }
    },

    render(h) {
      if (this.sourceLoading) {
        return h('div', { class: 'component-loading' }, '')
      }
      return h(
        'div',
        {
          class: `x-chart-${name} x-chart`
        },
        [
          h(`ve-${name}`, {
            props: {
              title: { text: this.title },
              data: this.chartData,
              settings: this.chartSettings,
              width: '100%',
              height: this.realHeight,
              colors: this.colors,
              dataEmpty: this.dataEmpty,
              tooltipVisible: this.tooltipVisible,
              legendVisible: this.legendVisible,
              legendPosition: this.legendPosition,
              beforeConfig: this.beforeConfig,
              afterConfig: this.afterConfig,
              afterSetOption: this.afterSetOption,
              afterSetOptionOnce: this.afterSetOptionOnce,
              grid: this.grid,
              loading: this.isLoading,
              events: this.eventMap
            },
            ref: 'chart'
          })
        ]
      )
    }
  }
}

export default generator
