/**
 * 各值意义:
 * @type 不指定默认为 string
 * @info 用于 tooltip 显示文字
 * @widget 输入部件，不指定默认为 input
 * @values 某些输入部件如 radio 需要一个选项列表
 */
import store from '@/store'

// UI 公用模型
export const UI_SCHEMAS = {
  direction: {
    name: '排列方式',
    widget: 'radio',
    values: [{ k: '横向', v: 'row' }, { k: '竖向', v: 'column' }]
  },

  isButtonGroup: {
    name: '采用按钮样式',
    type: 'boolean',
    widget: 'switch'
  },

  text: {
    name: '文字',
    type: 'object',
    /* eslint-disable no-template-curly-in-string  */
    info:
      '绑定变量时，可以使用表达式取值，例如: `${loading?\'加载中\':\'加载完成\'}`。另外当组件位于 Table 内时，可用 row 取出行数据，例如 row.time',
    dynamicOption: {
      dynamic: { keyName: 'textKey' },
      static: { keyName: 'text', placeholder: '输入按钮文字' },
      isStatic: true
    },
    widget: 'dynamicOrStatic'
  },

  labelWidth: {
    name: '标签宽度',
    type: 'object',
    /* eslint-disable no-template-curly-in-string  */
    info:
      '绑定变量时，可以使用表达式取值，例如: `${loading?\'加载中\':\'加载完成\'}`。另外当组件位于 Table 内时，可用 row 取出行数据，例如 row.time',
    dynamicOption: {
      dynamic: { keyName: 'labelWidthKey' },
      static: { keyName: 'labelWidth', placeholder: '标签宽度，单位 px' },
      isStatic: true
    },
    widget: 'dynamicOrStatic'
  },

  size: {
    name: '尺寸',
    widget: 'radio',
    values: [{ k: '正常', v: 'medium' }, { k: '中等', v: 'small' }, { k: '小型', v: 'mini' }]
  },

  visible: {
    name: '显示/隐藏',
    type: 'object',
    info: '当组件位于 Table 内时，变量可以从行数据内取，例如：row.visible',
    dynamicOption: {
      dynamic: { keyName: 'visibleKey' },
      static: { keyName: 'visible', widget: 'switch', default: true },
      isStatic: true
    },
    widget: 'dynamicOrStatic'
  },

  align: {
    name: '内部水平对齐',
    widget: 'radio',
    values: [{ k: '左', v: 'left' }, { k: '中', v: 'center' }, { k: '右', v: 'right' }]
  },

  placeholder: {
    name: '占位符'
  },

  label: {
    name: '标签'
  },

  max: {
    name: '最大输入长度',
    type: 'number',
    widget: 'number'
  },

  min: {
    name: '最小输入长度',
    type: 'number',
    widget: 'number'
  },

  clearable: {
    name: '是否可清空',
    type: 'boolean',
    widget: 'switch'
  },

  filterable: {
    name: '是否可搜索',
    type: 'boolean',
    widget: 'switch'
  },

  // 仅用于 TimePicker 和 DatePicker
  displayFormat: {
    name: '展示格式',
    info: '展示的格式，timerPicker 时例如 HH:mm:ss，datePicker 时例如 yyyy-MM-dd'
  }
}

// 数据公用模型
export const DATA_SCHEMAS = {
  bindKey: {
    name: '绑定值'
  },

  id: {
    name: '组件 ID',
    placeholder: '输入组件 ID',
    info: '当需要调用该组件上的方法时，必须为其设置一个 ID。每个组件的 ID 应该是唯一的。'
  },

  disabled: {
    name: '禁用标志',
    type: 'object',
    info: 'Table 内，变量可从行内获取，如：row.disabled；支持操作符，如：row.status === 1',
    dynamicOption: {
      dynamic: { keyName: 'disabledKey' },
      static: { keyName: 'disabled', widget: 'switch' },
      isStatic: false
    },
    widget: 'dynamicOrStatic'
  },

  readonly: {
    name: '只读标志',
    type: 'object',
    dynamicOption: {
      dynamic: { keyName: 'readonlyKey' },
      static: { keyName: 'readonly', widget: 'switch' },
      isStatic: false
    },
    widget: 'dynamicOrStatic'
  },

  optionsKey: {
    name: '选项列表',
    info: '选项列表的数据源，例: [{label: "备选项", value: "值"}...]'
  },

  filterMethodKey: {
    name: '自定义过滤方法',
    info: '仅在 filterable 为 true 有效'
  },

  // 仅用于 TimePicker 和 DatePicker
  valueFormat: {
    name: '绑定值格式',
    info: '绑定值的格式，timerPicker 时例如 HH:mm:ss，datePicker 时例如 yyyy-MM-dd'
  },

  defaultValueKey: {
    name: '默认值'
  },

  dataSource: {
    name: '数据源',
    widget: 'select',
    values() {
      const dataSources = store.state.projectDataSources
      return dataSources.map(dataSource => {
        return {
          k: dataSource.NAME,
          v: dataSource.TABLEID
        }
      })
    }
  }
}

// 事件公用模型
const BASE_EVENT_SET = ['click', 'blur', 'change', 'focus', 'clear']
const TABLE_EVENT_SET = ['click']
const TABLE_EVENT_SCHEMAS = {
  click: {
    name: 'onClick',
    argumentsMapping: [
      {
        name: 'row',
        description: '行数据，由 { data, index } 两个字段组成'
      },
      {
        name: 'event',
        description: '鼠标点击事件的事件对象'
      }
    ]
  }
}
const DEFAULT_SCHEMAS = {
  click: {
    name: 'onClick',
    argumentsMapping: [
      {
        name: 'clickEvent',
        description: '鼠标点击事件的事件对象'
      }
    ],
    inTable: true
  },
  blur: {
    name: 'onBlur',
    argumentsMapping: [
      {
        name: 'blurEvent',
        description: '元素失去焦点事件的事件对象'
      }
    ]
  },
  focus: {
    name: 'onFocus',
    argumentsMapping: [
      {
        name: 'focusEvent',
        description: '元素获得焦点事件的事件对象'
      }
    ]
  },
  change: {
    name: 'onChange',
    argumentsMapping: [
      {
        name: 'value',
        description: '变更后的值'
      }
    ]
  }
}
const BASE_EVENT_SCHEMAS = {}

BASE_EVENT_SET.forEach(e => {
  const firstUpperCase = e.charAt(0).toUpperCase() + e.slice(1)

  BASE_EVENT_SCHEMAS[`handle${firstUpperCase}Key`] = DEFAULT_SCHEMAS[e] || {
    name: `on${firstUpperCase}`
  }
})

TABLE_EVENT_SET.forEach(e => {
  const firstUpperCase = e.charAt(0).toUpperCase() + e.slice(1)

  BASE_EVENT_SCHEMAS[`handle${firstUpperCase}InTableKey`] = TABLE_EVENT_SCHEMAS[e]
})

export const EVENT_SCHEMAS = Object.assign({}, BASE_EVENT_SCHEMAS)

export const CHART_SCHEMAS = {
  ui: {
    title: {
      name: '图表标题',
      widget: 'input',
      type: 'string'
    },
    height: {
      name: '高度',
      widget: 'input',
      type: 'string'
    },
    tooltipVisible: {
      name: '是否显示提示框',
      widget: 'switch',
      type: 'boolean'
    },
    legendVisible: {
      name: '是否显示图例',
      widget: 'switch',
      type: 'boolean'
    },
    legendPosition: {
      name: '图例显示位置',
      widget: 'radio',
      values: [
        { k: '上', v: 'top' },
        { k: '右', v: 'right' },
        { k: '下', v: 'bottom' },
        { k: '左', v: 'left' }
      ]
    },
    gridBindKey: {
      name: '绑定网格配置变量',
      info:
        '配置说明参考 <a target="_blank" href="http://echarts.baidu.com/option.html#grid">echarts 官方文档</a>'
    },
    colorsBindKey: {
      name: '绑定颜色列表变量'
    }
  },
  data: {
    loadingKey: {
      name: 'Loading 标志'
    },
    eventMapBindKey: {
      name: '事件绑定对象',
      info:
        '配置方法参考 <a target="_blank" href="https://elemefe.github.io/v-charts/#/event">使用文档</a>'
    },
    dataBindKey: {
      name: '绑定值'
    }
  },
  events: {
    beforeConfigKey: {
      name: 'beforeConfig',
      info: '对数据提前进行额外的处理',
      argumentsMapping: [
        {
          name: 'data',
          description: '图表的绑定值'
        }
      ]
    },
    afterConfigKey: {
      name: 'afterConfig',
      info: '对生成好的 echarts 配置进行额外的处理。<br/>绑定的方法最后一定要返回 echarts 配置，不然 v-charts 会抛错',
      argumentsMapping: [
        {
          name: 'options',
          description: '图表配置对象'
        }
      ]
    },
    afterSetOptionKey: {
      name: 'afterSetOption',
      info: '生成图表后获取 echarts 实例',
      argumentsMapping: [
        {
          name: 'ec',
          description: 'echarts 实例'
        }
      ]
    },
    afterSetOptionOnceKey: {
      name: 'afterSetOptionOnce',
      info: '同 afterSetOption，但是只执行一次',
      argumentsMapping: [
        {
          name: 'ec',
          description: 'echarts 实例'
        }
      ]
    }
  }
}
