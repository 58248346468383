export default {
  componentName: 'XImage',

  label: 'Image',

  beTableSlot: true,

  props: {
    src: {
      src: '',
      srcKey: '',
      isStatic: true
    },
    width: 'auto',
    height: 'auto'
  },

  configs: {
    ui: {
      label: '视觉',
      content: {
        src: {
          name: '图片链接',
          type: 'object',
          dynamicOption: {
            dynamic: { keyName: 'srcKey' },
            static: { keyName: 'src', placeholder: '输入链接地址' },
            isStatic: true
          },
          widget: 'dynamicOrStatic'
        },

        width: {
          name: '宽度',
          info: '例如: 200px 或 20vw 或 auto'
        },

        height: {
          name: '高度',
          info: '例如: 80px 或 8vh 或 auto'
        }
      }
    }
  }
}
