import { DATA_SCHEMAS } from '../../common-schemas'

const { dataSource } = DATA_SCHEMAS

export default {
  componentName: 'XBaseEditTable',

  label: '基础编辑表单',

  props: {},

  notFlex: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        height: {
          name: '高度',
          type: 'number',
          info: '当设置为 0 时，高度自适应',
          widget: 'number'
        },
      },

      data: {
        label: '数据逻辑',
        content: {
          dataSource
        }
      }
    }
  }
}
