<template>
  <div class="header-search">
    <el-select
      v-model="search"
      :remote-method="querySearch"
      filterable
      default-first-option
      remote
      prefix="el-icon-date"
      placeholder="搜索菜单"
      class="header-search-select"
      @change="change"
    >
      <div slot="prefix"><i class="el-icon-search" style="margin-left: 5px;" /></div>
      <el-option
        v-for="item in options"
        :key="item.path"
        :value="item"
        :label="item.title.join(' > ')"
      />
    </el-select>
  </div>
</template>

<script>
// fuse is a lightweight fuzzy-search module
// make search results more in line with expectations
import Fuse from 'fuse.js'
import path from 'path'

export default {
  name: 'HeaderSearch',

  data() {
    return {
      search: '',
      options: [],
      searchPool: [],
      fuse: undefined
    }
  },

  computed: {
    routes() {
      return this.$store.state.permissionRoutes
    }
  },

  watch: {
    routes() {
      this.searchPool = this.generateRoutes(this.routes)
    },
    searchPool(list) {
      this.initFuse(list)
    }
  },

  mounted() {
    this.searchPool = this.generateRoutes(this.routes)
  },

  methods: {
    change(val) {
      this.$router.push(val.path)
      this.search = ''
      this.options = []
    },
    initFuse(list) {
      this.fuse = new Fuse(list, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          {
            name: 'title',
            weight: 0.7
          },
          {
            name: 'path',
            weight: 0.3
          }
        ]
      })
    },
    // Filter out the routes that can be displayed in the sidebar
    // And generate the internationalized title
    generateRoutes(routes, basePath = '/', prefixTitle = []) {
      let res = []

      for (const router of routes) {
        // skip searchFilter router
        if (router.searchFilter) {
          continue
        }

        const data = {
          path: path.resolve(basePath, router.path),
          title: [...prefixTitle]
        }

        if (router.meta && router.meta.title) {
          data.title = [...data.title, router.meta.title]

          if (router.redirect !== 'noRedirect') {
            // only push the routes with title
            // special case: need to exclude parent router without redirect
            res.push(data)
          }
        }

        // recursive child routes
        if (router.children) {
          const tempRoutes = this.generateRoutes(
            router.children,
            data.path,
            data.title
          )
          if (tempRoutes.length >= 1) {
            res = [...res, ...tempRoutes]
          }
        }
      }
      return res
    },
    querySearch(query) {
      if (query !== '') {
        this.options = this.fuse.search(query)
      } else {
        this.options = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-search {
  cursor: default !important;

  .header-search-select {
    transition: width 0.2s;
  }

  /deep/ .el-input__inner {
    cursor: text;
  }
}
</style>
