<template>
  <div class="extra-action">
    <div class="icon-btn" @click="handleRefresh">
      <el-tooltip content="刷新" :open-delay="1000">
        <icon name="refresh" />
      </el-tooltip>
    </div>
    <div v-if="renderRoute && renderRoute.help_url && $route.name !== 'home'" class="icon-btn" @click="handleHelp">
      <el-tooltip content="帮助" :open-delay="1000">
        <icon name="help" />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NProgress from 'nprogress'
import bus from '@/pages/render-layout/bus'

export default {
  name: 'ExtraAction',

  computed: {
    ...mapState(['renderRoute'])
  },

  data() {
    return {
      timeoutHandle: null
    }
  },

  methods: {
    refreshIframe(iframe) {
      if (!iframe.reloadSrc) {
        iframe.reloadSrc = iframe.src
      }
      NProgress.start()
      clearTimeout(this.timeoutHandle)
      this.timeoutHandle = setTimeout(() => {
        iframe.src = iframe.reloadSrc
        NProgress.done()
      }, 100)
    },
    handleRefresh() {
      // 招到页面中的激活的 iframe 并刷新
      // 如果没找到，则刷新 render-page（新版）
      const currentIframe = document.querySelector('#render-container-wrapper.active iframe')
      if (currentIframe) {
        this.refreshIframe(currentIframe)
      } else if (this.$route.name === 'home') {
        bus.$emit('homeReload')
      } else {
        bus.$emit('reload')
      }
    },
    handleHelp() {
      const route = this.renderRoute
      if (!route) return
      const helpUrl = route.help_url
      window.open(helpUrl, '__blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/var.scss';

.extra-action {
  display: flex;
  align-items: center;

  .icon-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-size: 14px;
    color: #6b6b6b;

    &:hover {
      color: #2b2b2b;
    }

    svg {
      width: 16px;
      margin-right: 4px;
      fill: currentColor;
    }
  }
}
</style>
