import { UI_SCHEMAS, EVENT_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { size, label, labelWidth } = UI_SCHEMAS
const { bindKey, disabled, readonly } = DATA_SCHEMAS
const { handleChangeKey } = EVENT_SCHEMAS

export default {
  componentName: 'XSwitch',

  label: 'Switch',

  props: {
    size: 'small',
    activeColor: '#13ce66',
    inactiveColor: '#ff4949'
  },

  notFlex: true,
  validate: true,
  beTableSlot: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        size,
        label,
        labelWidth,
        activeColor: {
          name: '激活颜色',
          type: 'string',
          widget: 'color'
        },
        inactiveColor: {
          name: '关闭颜色',
          type: 'string',
          widget: 'color'
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: { bindKey, disabled, readonly }
    },

    events: {
      label: '事件绑定',
      content: { handleChangeKey }
    }
  }
}
