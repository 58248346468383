export default {
  componentName: 'XTab',

  label: 'Tab',

  props: {
    position: 'top',
    tabs: [{ label: '标题', pageId: undefined }]
  },

  _children: {
    tabs_0: [],
    tabs_1: [],
    tabs_2: [],
    tabs_3: [],
    tabs_4: [],
    tabs_5: [],
    tabs_6: [],
    tabs_7: [],
    tabs_8: [],
    tabs_9: []
  },

  notFlex: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        background: {
          name: '背景颜色',
          type: 'string',
          size: 'large',
          widget: 'color'
        },
        position: {
          name: '选项卡位置',
          widget: 'radio',
          values: [{ k: '左边', v: 'left' }, { k: '右边', v: 'right' }, { k: '头部', v: 'top' }, { k: '底部', v: 'bottom' }]
        },
        tabs: {
          name: '选项卡设置',
          type: 'array',
          widget: 'tabPane'
        }
      }
    }
  }
}
