<template>
  <p class="small-text" :small-content="content"></p>
</template>

<script>
export default {
  name: 'SmallText',

  props: ['content']
}
</script>

<style lang="scss">
.small-text {
  &::after {
    content: attr(small-content);
    display: block;
    transform: scale(0.8);
    white-space: nowrap;
  }
}
</style>
