<template>
  <div class="x-base-table-filter" v-if="visible && projectParams.length">
    <el-row :gutter="16">
      <el-col v-for="(item, i) in columns" :key="i" :span="8" class="col">
        <div class="x-form-item">
          <div class="x-form-label">{{ item.title }}</div>
          <template v-for="(col, index) in item.group">
            <div :key="index" class="x-form-body">
              <el-date-picker
                v-if="col.type === 'Date'"
                v-model="query[col.name]"
                class="x-form-value"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="timestamp"
              />
              <el-time-picker
                v-else-if="col.type === 'Time'"
                v-model="query[col.name]"
                class="x-form-value"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59'
                }"
                placeholder="选择时间"
                format="HH:mm:ss"
                value-format="timestamp"
              />
              <el-date-picker
                v-else-if="col.type === 'DateTime'"
                v-model="query[col.name]"
                class="x-form-value"
                type="datetime"
                placeholder="选择日期和时间"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
              />
              <template v-else-if="col.editor === 'span'" >
                <div class="x-form-value">
                  {{ col['default_value'] }}
                </div>
              </template>
              <template v-else-if="col.editor === 'input'">
                <el-input
                  v-model="query[col.name]"
                  class="x-form-value"
                  placeholder="请输入内容"
                />
              </template>
              <template v-else-if="col.editor === 'checkgroup'">
                <el-switch v-model="query[col.name]"></el-switch>
              </template>
              <template v-else-if="col.editor === 'checkbox'">
                <el-checkbox v-model="query[col.name]"></el-checkbox>
              </template>
              <template v-else-if="col.editor === 'radio'">
                <el-radio v-model="query[col.name]"></el-radio>
              </template>
              <template v-else-if="col.editor === 'textarea'">
                <el-input
                  v-model="query[col.name]"
                  class="x-form-value"
                  type="textarea"
                  :rows="1"
                  placeholder="请输入内容"
                />
              </template>
              <template v-else-if="col.editor === 'password'">
                <el-input
                  v-model="query[col.name]"
                  class="x-form-value"
                  placeholder="请输入密码"
                  show-password
                />
              </template>
              <template v-else-if="col.editor === 'select' || col.editor === 'multiselect'">
                <el-select
                  v-model="query[col.name]"
                  :multiple="col.editor === 'multiselect'"
                  class="x-form-value"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in getSelectOptionItems(col)"
                    :key="item.VALUE"
                    :label="item.TEXT"
                    :value="item.VALUE">
                  </el-option>
                </el-select>
              </template>
            </div>
          </template>
        </div>
      </el-col>
    </el-row>
    <div class="search-editor">
      <el-button
        type="primary"
        icon="el-icon-search"
        :loading="loading"
        @click="searchTable"
      >搜索</el-button>
      <export-excel
        :tableRef="tableRef"
        :fileName="project.projectName"
      />
      <el-button @click="resetFilter">重置</el-button>

      <!-- 显示更多 -->
      <div v-if="originColumns.length > showMoreMaxNumber" class="more-filter">
        <el-button type="text" @click="this.toggleMoreFilter">
          {{ showMoreFilter ? '折叠更多筛选' : '显示更多筛选' }}
          <i class="el-icon-d-arrow-left more-filter-icon" :class="showMoreFilter ? 'active' : ''" />
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import API from '@/api'
import { deepClone } from '@/utils'

export default {
  name: 'XBaseTableFilter',

  props: {
    visible: Boolean,
    query: Object,
    loading: Boolean,
    tableRef: {
      type: Object,
      default: () => {
        return {
          data: {}
        }
      }
    },
    project: Object
  },

  data() {
    return {
      showMoreMaxNumber: 6,
      originParams: [],
      originColumns: [],
      showMoreFilter: false
    }
  },

  computed: {
    projectQuery() {
      return this.project.query
    },
    projectParams() {
      return this.project.params
    },
    ...mapState([
      'projectDictionary'
    ]),
    projectDataSources() {
      return this.project.dataSources
    },
    columns() {
      if (!this.showMoreFilter) {
        if (this.originColumns.length > this.showMoreMaxNumber) {
          return [...this.originColumns.slice(0, this.showMoreMaxNumber)]
        }
        return this.originColumns
      }
      return this.originColumns
    }
  },

  mounted() {
    this.initBaseFilter()
    this.initDefaultValue()
    this.getQuery()
  },

  methods: {
    // 相同的 title 成组
    initGroupParams(params) {
      let groupParams = []
      params.forEach(param => {
        const matched = groupParams.filter(cloumn => cloumn.title === param.title)
        if (matched.length > 0) {
          matched[0].group.push(param)
        } else {
          groupParams.push({
            title: param.title,
            group: [param]
          })
        }
      })
      return groupParams
    },
    initDefaultValue() {
      this.originParams.forEach(column => {
        if (column && column.type) {
          if (['Date', 'Time', 'DateTime'].indexOf(column.type) > -1) {
            Vue.set(this.query, column.name, this.getDateDefaultValue(column).getTime())
          } else if (typeof column.default_value !== 'undefined') {
            Vue.set(this.query, column.name, column.default_value)
          } else {
            Vue.set(this.query, column.name, undefined)
          }
        }
      })
    },
    initBaseFilter() {
      if (!this.projectParams.length) {
        return
      }
      const paramsLength = this.projectParams.length
      this.originParams = deepClone(this.projectParams)
      this.originColumns = this.initGroupParams(this.originParams)
    },
    getParamsArray() {
      return this.projectParams.map(param => param.name)
    },
    getQuery() {
      if (this.projectQuery) {
        this.getQueryFromProject()
      } else {
        this.getQueryFromLocation()
      }
    },
    getQueryFromLocation() {
      const { query } = this.$route
      const paramsArray = this.getParamsArray()
      Object.keys(query).forEach(key => {
        if (paramsArray.indexOf(key) > -1) {
          Vue.set(this.query, key, query[key])
        }
      })
    },
    getQueryFromProject() {
      const query = this.projectQuery
      const paramsArray = this.getParamsArray()
      Object.keys(query).forEach(key => {
        if (paramsArray.indexOf(key) > -1) {
          Vue.set(this.query, key, query[key])
        }
      })
    },
    resetFilter() {
      this.initDefaultValue()
    },
    searchTable() {
      this.$emit('search', this.query)
    },
    toggleMoreFilter() {
      this.showMoreFilter = !this.showMoreFilter
    },
    setLastDate(date, delay = 0) {
      const startDate = new Date()
      startDate.setDate(1)
      startDate.setMonth(startDate.getMonth() + delay)
      const targetDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
      date.setDate(targetDate.getDate())
      return date
    },
    transformDateDefault(date, type) {
      // 1 当天 2 月初 3 月末 4 年初 5 年末
      if (type === 2) {
        date.setDate(1)
      } else if (type === 3) {
        this.setLastDate(date)
      } else if (type === 4) {
        date.setMonth(0)
        date.setDate(1)
      } else if (type === 5) {
        date.setMonth(11)
        date.setDate(31)
      }
      return date
    },
    transformTimeType(date, type) {
      // 1 开始型 00:00:00 2 结束型 23:59:59
      if (type === 1) {
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
      } else if (type === 2) {
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
      }
      return date
    },
    getDateDefaultValue(column) {
      const dateDefault = column.date_default
      const timeType = column.time_type
      const date = new Date()
      this.transformDateDefault(date, dateDefault)
      this.transformTimeType(date, timeType)

      return date
    },
    getDynamicOptionItems(dic) {
      return API.getDynamicDictionary(dic.diccode)
    },
    getSelectOptionItems(column) {
      // 临时变量缓存下拉值
      if (column.selectOptions) {
        return column.selectOptions
      }
      // 页面内数据源
      if (typeof column.p_tableid === 'number') {
        const choosedSource = this.projectDataSources.filter(
          dataSource => dataSource.TABLEID === column.p_tableid
        )
        if (!choosedSource) {
          return
        }
        const dataSource = choosedSource[0]

        return API.getTable({
          name: dataSource.NAME,
          projectId: this.project.projectId
        }).then(res => {
          const optionItems = res.data.map(value => {
            return {
              VALUE: dataSource.KEYS ? value[dataSource.KEYS] : value[Object.keys(value)[0]],
              TEXT: dataSource.TEXTCOLUMN ? value[dataSource.TEXTCOLUMN] : value[Object.keys(value)[1]]
            }
          })
          
          Vue.set(column, 'selectOptions', optionItems)
        })
      }
      const dicItem = this.projectDictionary[column.lookup_data]
      // 2 为动态字典，需要发送请求获取数据
      // todo 支持字典参数查询
      if (dicItem.dictype === 2) {
        return this.getDynamicOptionItems(dicItem).then(res => {
          const optionItems = res.data.map(value => {
            return {
              VALUE: dicItem.keycolumn ? value[dicItem.keycolumn] : value[Object.keys(value)[0]],
              TEXT: dicItem.textcolumn ? value[dicItem.textcolumn] : value[Object.keys(value)[1]]
            }
          })
          Vue.set(column, 'selectOptions', optionItems)
        })
      }
      Vue.set(column, 'selectOptions', dicItem.data)
    }
  },

  watch: {
    'projectParams'() {
      this.initBaseFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/var.scss';

.x-base-table-filter {
  padding: 12px;
  background-color: #f0f5f7;
  border-radius: 4px;
  margin-bottom: 16px;

  .col {
    margin-bottom: 16px;
  }

  .x-form-item {
    display: flex;
    align-items: center;
    min-height: 36px;
  }

  .x-form-label {
    text-align: right;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .x-form-body {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .x-form-value {
    flex: 1;
    width: 100%;
    margin-right: 8px;
  }

  .search-editor {
    position: relative;
    display: flex;
  }

  .more-filter {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    justify-content: center;
    transform: translate(-50%, -50%);
  }

  .more-filter-icon {
    margin-left: 4px;
    transform: rotate(270deg);
    transition: transform 0.2s ease;

    &.active {
      transform: rotate(90deg);
    }
  }
}
</style>
