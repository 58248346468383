<script>
import ModelMixin from '../mixins/model-mixin'
import IdMixin from '../mixins/id-mixin'
import OptionsMixin from '../mixins/options-mixin'
import DisabledMixin from '../mixins/disabled-mixin'

export default {
  name: 'XSelect',

  mixins: [ModelMixin, IdMixin, OptionsMixin, DisabledMixin],

  data() {
    return {}
  },

  computed: {
    defaultModel() {
      return this.multiple ? [] : ''
    }
  },

  methods: {
    onBlur(event) {
      this.handleBlurKey && this.project.globalAPI[this.handleBlurKey](event)
    },

    onFocus(event) {
      this.handleFocusKey && this.project.globalAPI[this.handleFocusKey](event)
    },

    filterMethod(input) {
      if (this.filterMethodKey) {
        return this.project.globalAPI[this.filterMethodKey](input)
      }
    },

    remoteFilterMethod(query) {
      this.remoteMethodKey && this.project.globalAPI[this.remoteMethodKey](query)
    }
  },

  props: {
    size: String,
    placeholder: String,
    clearable: Boolean,
    filterable: Boolean,
    multiple: Boolean,
    label: String,
    remote: Boolean,

    handleBlurKey: String,
    handleFocusKey: String,
    remoteMethodKey: String,
    project: Object
  },

  render() {
    return (
      <div class="x-select x-form-item">
        {this.label ? <div class="x-form-label">{this.label}</div> : ''}
        <el-select
          ref="elCompo"
          value={this.bindValue}
          onInput={this.updateValue}
          multiple={this.multiple}
          filterable={this.filterable}
          remote={this.remote}
          remoteMethod={this.remoteFilterMethod}
          clearable={this.clearable}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder={this.placeholder}
          onFocus={this.onFocus}
          size={this.size}
          disabled={this.isDisabled}
        >
          {this.options.map((e, i) => {
            return (
              <el-option
                key={e[this.optionsValueKey || 'value']}
                label={e[this.optionsLabelKey || 'label']}
                value={e[this.optionsValueKey || 'value']}
              />
            )
          })}
        </el-select>
        {this.error ? <div class="x-err-msg">校验有误</div> : ''}
      </div>
    )
  }
}
</script>

<style lang="scss">
.x-select {
  > .el-select {
    width: 100%;
  }
}
</style>

