<template>
  <div class="context-menu">
    <context-menu ref="ctxMenu">
      <slot></slot>
    </context-menu>
  </div>
</template>

<script>
import ContextMenu from 'vue-context-menu'

export default {
  name: 'XContextmenu',
  components: { ContextMenu },
  methods: {
    open() {
      this.$refs.ctxMenu.open()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/var.scss';

.context-menu /deep/ {
  .ctx-menu {
    padding: 3px 0;
  }

  .ctx-menu-container {
    border: 0;

    .ctx-menu {
      box-shadow: none;
      border-radius: 2px;

      li {
        padding: 5px 14px;
        font-size: 12px;
        cursor: pointer;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}
</style>
