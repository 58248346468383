import { firstUpperCase } from '@/pages/editor/utils/component-name'
import SchemaGenerator from './chartSchemaGenerator'
import charts from './charts'

const result = {}
charts.list.forEach(name => {
  result[`XChart${firstUpperCase(name)}`] = SchemaGenerator(name)
})

export default result
