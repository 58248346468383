import uuidv1 from 'uuid/v1'
import basicComponents from '@editor/components/basicComponents/schemas'
import { omit, cloneDeep } from 'lodash-es'

export default function createComponent(component) {
  const uuid = uuidv1()

  const configs = omit(
    cloneDeep(
      basicComponents.find(
        item => item.componentName === component.componentName
      )
    ),
    ['configs', 'label']
  )

  const result = {
    uuid,
    ...configs
  }

  let defaultChildren = configs.children

  if (Array.isArray(defaultChildren)) {
    result.children = defaultChildren.map(item => createComponent(item.name))
  }

  if (typeof defaultChildren === 'object') {
    result.children = cloneDeep(defaultChildren)
    Object.keys(result.children).forEach(key => {
      result.children[key] = result.children[key].map(item =>
        createComponent(item.name)
      )
    })
  }
  return result
}
