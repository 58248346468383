<template>
  <div class="widget-icon" v-if="inited">
    <div class="icon_input" @click.stop="togglePanle">
      <i v-if="currentIcon" class="icon_active" :class="currentIcon"></i>
      <p v-else class="icon_empty">点击选择图标</p>
    </div>
    <div class="icon_panel" v-show="panelShow">
      <div v-for="(icon, i) in icons" :key="i" class="icon-box" :class="{active: currentIcon === icon}" @click="e => changeIcon(icon)">
        <i class="icon_inner" :class="icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetIcon',

  props: {
    value: String,
    data: Array
  },

  watch: {
    value(val) {
      this.currentIcon = this.value
    }
  },

  created() {
    this.currentIcon = this.value
    this.inited = true
  },

  mounted() {
    window.addEventListener('click', this.hidePanel, false)
  },

  beforeDestroy() {
    window.removeEventListener('click', this.hidePanel)
  },

  data() {
    return {
      inited: false,
      panelShow: false,
      currentIcon: '',
      icons: [
        'el-icon-upload',
        'el-icon-error',
        'el-icon-success',
        'el-icon-warning',
        'el-icon-sort-down',
        'el-icon-sort-up',
        'el-icon-arrow-left',
        'el-icon-circle-plus',
        'el-icon-circle-plus-outline',
        'el-icon-arrow-down',
        'el-icon-arrow-right',
        'el-icon-arrow-up',
        'el-icon-back',
        'el-icon-circle-close',
        'el-icon-date',
        'el-icon-circle-close-outline',
        'el-icon-caret-left',
        'el-icon-caret-bottom',
        'el-icon-caret-top',
        'el-icon-caret-right',
        'el-icon-close',
        'el-icon-d-arrow-left',
        'el-icon-check',
        'el-icon-delete',
        'el-icon-d-arrow-right',
        'el-icon-document',
        'el-icon-d-caret',
        'el-icon-edit-outline',
        'el-icon-download',
        'el-icon-goods',
        'el-icon-search',
        'el-icon-info',
        'el-icon-message',
        'el-icon-edit',
        'el-icon-location',
        'el-icon-loading',
        'el-icon-location-outline',
        'el-icon-menu',
        'el-icon-minus',
        'el-icon-bell',
        'el-icon-mobile-phone',
        'el-icon-news',
        'el-icon-more',
        'el-icon-more-outline',
        'el-icon-phone',
        'el-icon-phone-outline',
        'el-icon-picture',
        'el-icon-picture-outline',
        'el-icon-plus',
        'el-icon-printer',
        'el-icon-rank',
        'el-icon-refresh',
        'el-icon-question',
        'el-icon-remove',
        'el-icon-share',
        'el-icon-star-on',
        'el-icon-setting',
        'el-icon-circle-check',
        'el-icon-service',
        'el-icon-sold-out',
        'el-icon-remove-outline',
        'el-icon-star-off',
        'el-icon-circle-check-outline',
        'el-icon-tickets',
        'el-icon-sort',
        'el-icon-zoom-in',
        'el-icon-time',
        'el-icon-view',
        'el-icon-upload2',
        'el-icon-zoom-out'
      ]
    }
  },

  methods: {
    hidePanel(e) {
      if (e.target !== this.$el.querySelector('.icon_panel')) {
        this.panelShow = false
      }
    },

    togglePanle() {
      this.panelShow = !this.panelShow
    },

    changeIcon(icon) {
      this.currentIcon = icon
      this.$emit('input', icon)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/var.scss";

.widget-icon {
  position: relative;

  .icon {
    &_input {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 28px;
      border-radius: 4px;
      background: $primary;
      cursor: pointer;
      transition: 0.12s ease-out;

      &:hover {
        background: #39aaea;
      }
    }

    &_active {
      color: #fff;
    }

    &_panel {
      box-sizing: border-box;
      position: absolute;
      top: 130%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 20;
      display: flex;
      flex-wrap: wrap;
      width: 204px;
      height: 180px;
      padding: 5px;
      border: solid 1px #E4E7ED;
      border-radius: 4px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      overflow: auto;
      background: #ffffff;

      &::-webkit-scrollbar {
        width: 2px;
      }
    }

    &_empty {
      font-size: 12px;
      color: #666;
      transform: scale(0.9);
    }

    &-box {
      display: flex;
      width: 38px;
      height: 38px;
      flex-grow: 0;
      cursor: pointer;
      color: #000000;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #777;
        color: #fff;
      }

      &.active {
        background: $primary;
        color: #ffffff;
      }
    }
  }
}
</style>

