import { UI_SCHEMAS, EVENT_SCHEMAS, DATA_SCHEMAS } from '../../common-schemas'

const { size, placeholder, displayFormat, label } = UI_SCHEMAS
const {
  bindKey,
  disabled,
  readonly,
  valueFormat,
  defaultValueKey
} = DATA_SCHEMAS
const { handleChangeKey, handleBlurKey, handleFocusKey } = EVENT_SCHEMAS

export default {
  componentName: 'XTimePicker',

  label: 'Time',

  props: {
    defaultValue: '00:00:00',
    placeholder: '请选择时间',
    size: 'small',
    label: '条目标签',
    displayFormat: 'HH:mm:ss'
  },

  notFlex: true,
  validate: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        size,
        label,
        placeholder,
        displayFormat,

        isRange: {
          name: '开启范围选择',
          widget: 'switch'
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: {
        bindKey,
        disabled,
        readonly,
        defaultValueKey,
        valueFormat,

        timeRangeKey: {
          name: '可选时间段',
          info:
            '可选时间段，例如 "18:30:00 - 20:30:00"，当时时间范围的时候，传入数组["09:30:00 - 12:00:00", "14:30:00 - 18:30:00"]'
        }
      }
    },

    events: {
      label: '事件绑定',
      content: { handleChangeKey, handleBlurKey, handleFocusKey }
    }
  }
}
