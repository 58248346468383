import exprEval from '@editor/utils/expression-eval'

export default {
  props: {
    disabled: Object
  },

  computed: {
    isDisabled() {
      const { disabled } = this
      const { isStatic } = disabled || {}
      if (disabled) {
        if (isStatic) {
          return disabled.disabled
        }
        if (this._IS_EDIT_) {
          return false
        }
        return exprEval(
          disabled.disabledKey,
          this.$store.state,
          (this.rowData || {}).data,
          this.inTableSlot,
          true
        )
      }
      return false
    }
  }
}
