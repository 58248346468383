import bus from '@/utils/bus'

/**
 * 打开外部链接页面
 * @param {*} url 外部链接
 * @param {*} title 标题
 */
function open(url, title) {
  bus.$emit('openView', { url, title })
}

/**
 * 打开新选项卡
 * @param {*} callback 回调
 * @param {*} index 索引
 */
function openView(pageId, query, callback = () => {}) {
  bus.$emit('openTabView', { pageId, query, callback })
}

/**
 * 返回页面
 * @param {*} callback 回调
 * @param {*} index 索引
 */
function back(callback = () => {}, index = 0) {
  bus.$emit('backView', { callback, index })
}

export default {
  open,
  // 兼容老版本跳转
  go: open,
  openView,
  back
}
