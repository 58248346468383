<template>
  <div class="editor-components">
    <div class="editor-components_gap">图表组件</div>

    <ul class="editor-components_box">
      <template v-for="item in componentData">
        <li
          v-if="!item.hidden"
          :key="item.label"
          class="editor-components_unit is-full"
          @mousedown="onDragstart($event, item)"
        >{{ item.label }}</li>
      </template>
    </ul>
  </div>
</template>

<script>
import { omit } from 'lodash-es'
import schemas from '@/pages/editor/components/chartComponents/schemas'
import dragMixin from '@editor/modules/componentLibrary/mixins/drag.mixin'

export default {
  name: 'ChartComponent',

  mixins: [dragMixin],

  data() {
    return {
      a: '1'
    }
  },

  computed: {
    componentData() {
      return schemas.map(component => {
        return omit(component, ['configs'])
      })
    }
  }
}
</script>

<style lang="scss">
/* 样式在 scss/components/components 里引入 */
</style>
