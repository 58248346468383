import col from './_col-schema'

export default {
  componentName: 'XRow',

  label: 'Layout',

  props: {
    gutter: 10,
    minHeight: 60,
    justify: 'start',
    alignItems: 'top',
    background: 'rgba(255,255,255,0)'
  },

  _children: [col, col],

  notFlex: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        gutter: {
          name: '列之间间隙',
          type: 'number',
          widget: 'number'
        },
        background: {
          name: '背景颜色',
          type: 'string',
          size: 'large',
          widget: 'color'
        },
        minHeight: {
          name: '最小高度',
          type: 'number',
          min: 20,
          widget: 'number'
        },
        alignItems: {
          name: '列：垂直对齐方式',
          widget: 'radio',
          values: [
            { k: '对齐顶部', v: 'top' },
            { k: '居中', v: 'middle' },
            { k: '对齐底部', v: 'bottom' }
          ]
        },
        justify: {
          name: '列：水平对齐方式',
          widget: 'select',
          values: [
            { k: '左对齐', v: 'start' },
            { k: '右对齐', v: 'end' },
            { k: '居中对齐', v: 'center' },
            { k: '均匀分布', v: 'space-around' },
            { k: '水平散列', v: 'space-between' }
          ]
        }
      }
    }
  }
}
