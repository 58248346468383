import config from '../../../../config'

const HOST = window.location.host

const API_HOST_MAP = {
  LOCAL: `//${config.baseHost}`,
  ALPHA: `//${config.baseHost}`,
  PROD: `//${config.baseHost}`
}

/**
 * ENV
 */
export const ENV = [
  { host: /local/, env: 'LOCAL' },
  { host: /dev/, env: 'ALPHA' },
  { host: /.*/, env: 'PROD' }
].find(item => item.host.test(HOST)).env

/**
 * API HOST
 */
export const API_HOST = API_HOST_MAP[ENV]
