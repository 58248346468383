<template>
  <el-row
    class="x-row"
    type="flex"
    :gutter="gutter"
    :justify="justify"
    :align="alignItems"
    :style="style"
  >
    <div class="fake-mask"></div>
    <div class="select-row no-drag" content="选中该行"></div>
    <div class="for-comfortable"></div>
    <x-component
      x-slot-component="true"
      v-for="(item, i) in componentData._children"
      :key="item.uuid"
      :data="item"
      :uuid="item.uuid"
      :project="project"
      :style="{zIndex: componentData._children.length - i + 3}"
    >
    </x-component>
    <el-tooltip v-if="_IS_EDIT_ && onlyActiveComponent.uuid" content="添加一列" placement="right" effect="dark">
      <div class="add-col no-drag" @click="addCol">+</div>
    </el-tooltip>
  </el-row>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapState, mapGetters } from 'vuex'
import * as mutationTypes from '@/store/mutation-types'
import basicComponentMixin from '../mixins/basic-component.mixin'
import AllSchemas from '@/pages/editor/components/basicComponents/schemas/all'

const uuidv1 = require('uuid/v1')

export default {
  name: 'XRow',

  mixins: [basicComponentMixin],

  props: {
    gutter: Number,
    justify: String,
    alignItems: String,
    background: String,
    minHeight: Number
  },

  computed: {
    ...mapGetters(['onlyActiveComponent']),
    style() {
      return {
        backgroundColor: this.background
      }
    }
  },

  methods: {
    addCol() {
      const uuid = uuidv1()
      const newCol = Object.assign(
        {
          uuid,
          parentUUID: this.uuid,
          path: this.componentData.path.concat([uuid])
        },
        cloneDeep(AllSchemas.ColSchema)
      )

      const children = this.onlyActiveComponent._children
      this.$store.commit(
        mutationTypes.MODIFY_COMPONENT_CHILHREN,
        children.concat([newCol])
      )

      this.$store.dispatch('historySave')
    }
  }
}
</script>

<style lang="scss">
.editor {
  .x-row {
    margin: 5px 0;

    .x-row {
      margin-top: 10px;
    }

    &.is-activited {
      > .add-col {
        display: flex;
      }
    }

    > .delete {
      top: 42px;
    }

    .for-comfortable {
      display: none;
      position: absolute;
      left: -10px;
      right: 62px;
      bottom: -5px;
      height: 22px;
      transform: translateY(100%);
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 0%);
    }

    &:not(.is-activited):hover {
      > .select-row,
      > .for-comfortable {
        display: block;
      }
    }
  }

  .select-row {
    display: none;
    position: absolute;
    z-index: 10;
    font-size: 12px;
    height: 22px;
    padding: 0 10px;
    line-height: 20px;
    background: #efefef;
    bottom: -27px;
    right: -5px;

    &::after {
      content: attr(content);
      display: block;
      transform: scale(0.8);
      color: #333;
    }
  }

  .add-col {
    display: none;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    width: 20px;
    height: 40px;
    background: #46a0fc;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 25;

    &:hover {
      background: #3086de;
    }
  }

  [x-slot-component='true'] > [x-slot] {
    position: relative;
    z-index: 3;
  }
}
</style>
