import uuidv4 from 'uuid/v4'

export default class Dialog {
  constructor(options) {
    const DEFAULT = {
      props: {
        name: '',
        title: '弹层标题',
        width: '',
        pageId: '',
        visibleKey: ''
      },
      uuid: uuidv4(),
      pageId: '',
      _children: []
    }
    Object.assign(this, DEFAULT, options)
  }
}
