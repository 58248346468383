const permissionMaps = {
  0: '不控制',
  1: '业务控制',
  2: '系统控制'
}

export const getProjectModel = ({ name, permissionType, projectId = null, projectData = null }) => {
  return [{
    name: 'page',
    total: projectId ? 1 : -1,
    keyColumns: ['PAGEID'],
    colNames: ['PAGEID', 'NAME', 'PAGETYPE', 'PAGETYPE2', 'INIT', 'SAVE_BEFORE', 'SAVE_AFTER', 'MDATE', 'CSS', 'ISAC', 'ISAC2', 'DBNAME'],
    colTypes: ['Integer', 'String', 'Integer', 'String', 'String', 'String', 'String', 'Date', 'String', 'Integer', 'String', 'String'],
    rows: [{
      v: [projectId, `${name}`, 3, '3.0查询', projectData, null, null, new Date().getTime(), null, permissionType, permissionMaps[permissionType], null],
      s: projectId ? 0 : 1
    }]
  }]
}

export const getUpdateFullProjectModel = project => {
  const { projectId, projectName, permissionType } = project
  return {
    option: 2,
    pageid: 5,
    data: [{
      name: 'page',
      total: 1,
      keyColumns: [
        'PAGEID'
      ],
      colNames: [
        'PAGEID',
        'NAME',
        'PAGETYPE',
        'PAGETYPE2',
        'INIT',
        'SAVE_BEFORE',
        'SAVE_AFTER',
        'MDATE',
        'CSS',
        'ISAC',
        'ISAC2',
        'DBNAME'
      ],
      colTypes: [
        'Integer',
        'String',
        'Integer',
        'String',
        'String',
        'String',
        'String',
        'Date',
        'String',
        'Integer',
        'String',
        'String'
      ],
      rows: [{
        v: [
          projectId,
          // 页面名称
          projectName,
          3,
          '3.0查询',
          // 存储项目配置信息
          JSON.stringify(project),
          null,
          null,
          new Date().getTime(),
          null,
          permissionType,
          permissionMaps[permissionType],
          null
        ],
        o: { 4: null },
        l: { 4: JSON.stringify(project) },
        s: 2
      }]
    }]
  }
}
