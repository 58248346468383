<template>
  <!-- 改动界面两边宽度的时候一定要改这里 -->
  <div class="editor-stage">
    <ul class="editor-stage-tabs" v-if="openedFiles.length">
      <li
        v-for="(file, index) in openedFiles"
        :key="file.hash"
        class="editor-stage-tabs_card"
        :class="{
          activated: file === currentFile,
          previewing: file === previewingFile
        }"
        @click="selectFile(file)"
        @click.middle="closeFile(file)"
        @dblclick="positiveFile(file)"
      >
        <icon :name="fileIconMap[file.type]" />
        <span class="file-name">{{ file.pageName || file.name }}</span>
        <span
          class="file-path"
          v-show="isRepeatedName(file.pageName || file.name, index)"
        >
          {{ getFilePath(file) }}
        </span>
        <i class="el-icon-close" @click.stop="closeFile(file)"></i>
      </li>
    </ul>
    <div class="editor-stage-main">
      <NoFiles v-if="!currentFile.name" />
      <template v-else>
        <keep-alive>
          <component :is="currentStage" :user="user"> </component>
        </keep-alive>
        <DialogsStage v-if="currentFile.type === 'ui' && currentDialog" />
      </template>
    </div>
  </div>
</template>

<script>
import UiStage from './ui-stage'
import CodeStage from './code-stage'
import HomeStage from './home-stage'
import DialogsStage from './dialog-stage'

import NoFiles from './no-files'
import { mapState } from 'vuex'

export default {
  name: 'EditorStage',

  props: ['user'],

  components: {
    UiStage,
    CodeStage,
    HomeStage,
    NoFiles,
    DialogsStage
  },

  data() {
    return {
      files: [],
      fileIconMap: {
        ui: 'design',
        func: 'javascript',
        const: 'constants'
      }
    }
  },
  computed: {
    ...mapState([
      'openedFiles',
      'currentFile',
      'previewingFile',
      'currentDialog',
      'pageMapping'
    ]),

    fileType() {
      return this.currentFile.type
    },

    currentStage() {
      const components = this.$options.components
      const { type } = this.currentFile

      if (~['func', 'const', 'projectConst'].indexOf(type)) {
        return components.CodeStage
      }
      if (type === 'home') {
        return components.HomeStage
      }
      if (type === 'ui') {
        return components.UiStage
      }
    }
  },

  methods: {
    isRepeatedName(name, index) {
      return this.openedFiles.some((file, i) => {
        if (index === i) return false
        return file.name === name || file.pageName === name
      })
    },

    getFilePath(file) {
      const page = this.pageMapping[file.pageId]
      if (!page || !page.path) return ''
      return `${page.path}/${page.name}`
    },

    closeFile(file = {}) {
      this.$store.dispatch('closeFile', file)
    },

    selectFile(file = {}) {
      this.$store.dispatch('selectFile', file)
    },

    positiveFile(file) {
      const { pageId } = file
      this.$store.dispatch('setFileUnsaved', pageId)
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-stage-tabs {
  display: flex;
  align-items: center;
  background-color: rgb(37, 37, 38);
  height: 34px;
  border-top: 1px solid #252526;
  z-index: 5;
  overflow: auto;

  &_card {
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding: 0 25px 0 10px;
    min-width: 150px;
    height: 32px;
    overflow: hidden;
    border-radius: 2px 2px 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    background-color: rgb(45, 45, 45);
    border-right: 1px solid rgb(37, 37, 38);
    cursor: pointer;
    position: relative;

    .file-name {
      padding-left: 6px;
    }

    .file-path {
      color: #777;
      transform: scale(0.8);
    }

    .icon-javascript,
    .icon-constants {
      color: #ffcb32;
    }

    .el-icon-s-promotion {
      color: #0eb984;
      font-size: 14px;
    }

    .icon-close {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%) scale(0.7);
      width: 20px;
      text-align: center;
      height: 14px;
      display: none;
      color: #fff;
      line-height: normal;
      transition: transform 0.15s linear;
      font-size: 12px;

      &:active {
        transform: translateY(-50%) scale(0.7);
      }
    }

    &:hover,
    &.activated {
      .icon-close {
        display: inline-block;
      }
    }

    &.previewing {
      font-style: italic;
      font-weight: normal;
    }

    &.activated {
      background-color: #171717;
    }
  }
}
</style>

<style lang="scss">
.editor-stage {
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: column;
  border-color: #1e1e1e;
  overflow: hidden;
}

.editor-stage-main {
  background-color: #ebebeb;
  height: 100%;
  overflow: auto;
  position: relative;
}
</style>
