/**
 * 加载此目录下所有 js 文件并全局注册为 filter
 */

import Vue from 'vue'

const filtersContext = require.context('@/filters', true, /\.js$/)

filtersContext.keys().forEach(path => {
  const filter = filtersContext(path)
  Vue.filter(filter.name, filter.default)
})
