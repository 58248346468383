import { DATA_SCHEMAS } from '../../common-schemas'

const { dataSource } = DATA_SCHEMAS

export default {
  componentName: 'XBaseTable',

  label: '基础表单',

  props: {
    filter: true,
    showIndex: true,
    border: true,
    stripe: true
  },

  _children: {
    buttons: []
  },

  notFlex: true,
  id: true,

  configs: {
    ui: {
      label: '视觉',
      content: {
        filter: {
          name: '是否启动搜索栏',
          type: 'boolean',
          widget: 'switch'
        },

        height: {
          name: '高度',
          type: 'number',
          info: '当设置为 0 时，高度自适应',
          widget: 'number'
        },

        showIndex: {
          name: '是否显示索引',
          type: 'boolean',
          info: '开启后显示列的索引',
          widget: 'switch'
        },

        stripe: {
          name: '是否斑马纹',
          type: 'boolean',
          widget: 'switch'
        },

        border: {
          name: '是否带有纵向边框',
          type: 'boolean',
          widget: 'switch'
        },

        thAlignCenter: {
          name: '表头文字居中',
          type: 'boolean',
          widget: 'switch'
        }
      }
    },

    data: {
      label: '数据逻辑',
      content: {
        dataSource
      }
    }
  }
}
