import { result } from 'lodash-es'
import { isOnlyOneSlot } from '@editor/utils'

const flatTree = (component, map) => {
  map[component.uuid] = component
  const children = component._children || []

  const onlyOneSlot = isOnlyOneSlot(children)

  if (onlyOneSlot && children.length) {
    children.forEach(child => {
      flatTree(child, map)
    })
  } else if (!onlyOneSlot) {
    Object.keys(children).forEach(slotName => {
      children[slotName].forEach(child => {
        flatTree(child, map)
      })
    })
  }
}

export const onlyActiveComponent = state => {
  return state.currentComponents.length === 1 ? state.currentComponents[0] : {}
}

export const currentPage = state => {
  const { pageMapping, currentFile } = state
  if (!currentFile || !currentFile.pageId) return null
  return pageMapping[currentFile.pageId]
}

export const currentTreeName = (state, getters) => {
  const { currentDialog } = state
  if (currentDialog) {
    return 'dialog'
  } else {
    return 'page'
  }
}

export const componentMapping = (state, getters) => {
  let { currentDialog } = state
  let { currentPage: currentPageGetter } = getters
  let tempMapping = {}

  if (!currentPageGetter) return tempMapping

  const componentTree = currentDialog
    ? currentDialog._children
    : currentPageGetter.componentTree || []

  componentTree.forEach(component => {
    flatTree(component, tempMapping)
  })

  return tempMapping
}

export const undoDisabled = state => {
  return (
    state.currentHistoryIndex === 0
    || !state.currentFile.hash
    || state.currentFile.type !== 'ui'
  )
}

export const redoDisabled = state => {
  return (
    state.currentHistoryIndex === state.componentTreeHistory.length - 1
    || !state.currentFile.hash
    || state.currentFile.type !== 'ui'
  )
}

export const lastSavedDisabled = state => {
  const { currentFile: { hash, unsaved }, lastSavedComponentTree } = state
  return !hash || !lastSavedComponentTree[hash] || !unsaved
}

export const currentPageUI = state => {
  const { pageMapping, currentFile } = state
  return result(pageMapping, `${currentFile.pageId}.componentTree`, {})
}

export const currentPageFuncs = state => {
  const { pageMapping, currentFile } = state
  return result(pageMapping, `${currentFile.pageId}.functions`, [])
}
