<template>
  <div class="config_box editor-element-ui">
    <h4
      class="config_box-title"
      :class="collapsed ? 'is-clpse' : ''"
      @click="collapsed = !collapsed"
    >
      <p>{{title}}</p>
      <i class="el-icon-arrow-up"></i>
    </h4>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ConfigBox',

  props: {
    title: String,
    defaultCollapsed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      collapsed: this.defaultCollapsed
    }
  }
}
</script>

<style lang="scss">
:root {
  --gray: #e4e4e4;
}

.config_box {
  margin-bottom: 8px;

  &-title {
    position: sticky;
    top: 0;
    display: flex;
    margin: 0 -10px 5px;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #000000;
    font-size: 13px;
    background: #ffffff;
    z-index: 10;
    transition: 0.15s ease-in-out;

    [class*='el-icon'] {
      transition: 0.15s ease-in-out;
    }

    &.is-clpse {
      // background: #333;
      background: #fff;
      [class*='el-icon'] {
        transform: rotate(180deg);
      }

      ~ .config_item {
        display: none;
      }
    }

    &:hover {
      // background: rgba(255, 255, 255, 0.3);
      background: #f0f0f0;
    }
  }

  &-empty {
    text-align: center;
    color: #999;
    padding-top: 10px;
    font-size: 12px;
  }

  .el-form {
    &-item {
      margin-bottom: 8px;

      &__label {
        padding-bottom: 2px;
        padding-left: 7px;
        line-height: 16px;
        color: #565656;
        font-size: 12px;
      }
    }
  }

  .config_item {
    margin-bottom: 20px;

    .tip {
      display: block;
      padding-top: 10px;
      padding-left: 7px;
      text-align: left;
      color: #555;
      font-size: 12px;
    }

    &.is-event .el-form-item__content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::before,
      &::after {
        display: none;
      }
    }

    &-label {
      padding-bottom: 2px;
      padding-left: 7px;
      line-height: 20px;
      color: #565656;
      flex-grow: 0;
      font-size: 12px;
      position: relative;

      &.info {
        cursor: help;
      }

      &.inline {
        display: inline-block;
      }

      .iconfont {
        color: #666;
      }

      > .right {
        float: right;

        .el-button {
          padding: 0 10px;
          margin: 0;
          font-size: 12px;
        }
      }

      .event {
        position: absolute;
        right: 0;
        top: 0;

        .el-button {
          padding: 0;
        }
      }
    }

    &-content {
      display: flex;
      align-items: center;
      span {
        color: #aaa;
        font-size: 14px;
      }
    }
  }

  [class*='decrease'],
  [class*='increase'] {
    // background: var(--gray);
    background: #fff;
    color: #aaa;

    &:hover {
      // color: white;
    }

    &.is-disabled {
      color: #666;
    }
  }
}
</style>
