<template>
  <el-dialog
    custom-class="x-dialog"
    :title="title"
    :width="width"
    :visible="visible"
    :modal="!_IS_EDIT_"
    :close-on-click-modal="!_IS_EDIT_"
    @update:visible="onVisibleUpdate">
    <div v-if="pageId" class="x-dialog_slot">
      <div v-if="_IS_EDIT_ " class="dialog-render-page-placeholder">页面 {{ pageId }} 渲染区域</div>
      <render-page v-else :projectId="parseInt(pageId, 10)" :query="query" />
    </div>
    <div
      v-else
      class="x-dialog_slot"
      x-slot="root"
      x-tree="dialog"
    >
      <x-placeholder
        v-if="placeholder &&
              !placeholder.targetUUID &&
              placeholder.slotName === 'root'" />
      <template v-for="item in componentData._children">
        <x-placeholder
          :key="item.uuid"
          v-if="placeholder &&
                placeholder.targetUUID === item.uuid &&
                placeholder.position.includes('top')" />
        <x-component
          x-slot-component
          :key="item.uuid"
          :data="item"
          :uuid="item.uuid"
          :project="project"
        >
        </x-component>
        <x-placeholder
          :key="item.uuid"
          v-if="placeholder &&
                placeholder.targetUUID === item.uuid &&
                placeholder.position.includes('bottom')" />
      </template>
    </div>
  </el-dialog>
</template>

<script>
import { get } from 'lodash-es'
import basicComponentMixin from '../mixins/basic-component.mixin'
import modelMixin from '../mixins/model-mixin'
import RenderPage from '@/pages/render/components/render-page'

export default {
  name: 'XDialog',

  mixins: [basicComponentMixin, modelMixin],

  components: {
    RenderPage
  },

  props: {
    visibleKey: String,
    title: String,
    pageId: String,
    width: {
      type: String,
      default: '50%'
    },
    componentData: {},
    project: Object
  },

  created() {
    this.setVisibleKeyToStore(false)
  },

  methods: {
    setVisibleKeyToStore(value) {
      this.$store.commit('SET_STATE', {
        project: this.project,
        path: this.visibleKey,
        value: value
      })
    },
    onVisibleUpdate(value) {
      if (!this._IS_EDIT_) {
        this.setVisibleKeyToStore(value)
        return
      }
      if (!value) this.$emit('close-dialog')
    },
  },

  computed: {
    visible() {
      return this._IS_EDIT_
        ? true
        : this.getValueByPath(this.visibleKey, { visible: false, query: {} }).visible
    },
    query() {
      return this._IS_EDIT_
        ? {}
        : this.getValueByPath(this.visibleKey, { visible: false, query: {} }).query
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-render-page-placeholder {
  padding: 200px 0;
  text-align: center;
  background-color: #ffffff;
}
</style>

<style lang="scss">
  .editor {
    .dialog-stage {
      .el-dialog__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }
    .x-dialog_slot {
      min-height: 50px;
      border: 1px dashed #ddd;
    }
  }
</style>
