import * as mutationTypes from '@/store/mutation-types'
import { mapState, mapGetters } from 'vuex'

const calcPosition = (targetElement, e) => {
  let position = []

  const targetElementBounding = targetElement.getBoundingClientRect()

  position[0] =
    e.clientY > targetElementBounding.top + targetElement.clientHeight / 2
      ? 'bottom'
      : 'top'

  position[1] =
    e.clientX > targetElementBounding.left + targetElement.clientWidth / 2
      ? 'right'
      : 'left'

  return position
}

export default {
  computed: {
    ...mapState([
      'currentFile',
      'placeholder',
      'draggingComponent',
      'draggingPosition',
      'readyToRemoveComponent',
      'currentDialog'
    ]),
    ...mapGetters(['componentMapping', 'currentTreeName'])
  },

  methods: {
    onDragstart(event, component, isPreset) {
      // 只有主键（通常是左键）按住才开始拖拽
      if (event.button) {
        return null
      }
      const { currentFile, $store } = this
      const target = event.target
      const targetBounding = target.getBoundingClientRect()
      const startOffsetLeft = targetBounding.left
      const startOffsetTop = targetBounding.top
      const startX = event.clientX
      const startY = event.clientY
      const startTime = new Date().getTime()
      const isNewComponent = !component.uuid
      let removed = false

      if (!currentFile) {
        this.$message.warning('请先打开页面文件再进行操作。')
        return
      }

      const onDragging = ev => {
        const currentX = ev.clientX
        const currentY = ev.clientY
        const nowTime = new Date().getTime()
        // const top = startOffsetTop + currentY - startY
        // const left = startOffsetLeft + currentX - startX
        const top = currentY
        const left = currentX

        // 防止误触发拖拽生成历史
        if (
          nowTime - startTime < 100 ||
          Math.abs(currentX - startX) + Math.abs(currentY - startY) < 10
        ) {
          return
        }

        if (isNewComponent) {
          // 保留当前字段，作为该组件显示给用户的唯一名称
          // component = omit(cloneDeep(component), ['label'])
        } else {
          if (!removed) {
            const { uuid } = component
            const { componentMapping } = this

            this.$store.commit(mutationTypes.REMOVE_COMPONENT, {
              uuid,
              componentMapping
            })
            removed = true
          }
        }

        $store.commit(mutationTypes.SET_DRAGGING_STATE, {
          component,
          position: {
            top: top + 'px',
            left: left + 'px'
          }
        })
        this.$nextTick(_ => {
          const { currentTreeName } = this
          const pageElm = document.querySelector(`div[x-tree=${currentTreeName}]`)
          const pageBounding = pageElm.getBoundingClientRect()

          if (currentX < pageBounding.left || currentX > pageBounding.right) {
            this.removePlaceholder()
          } else {
            this.showPlaceholder(ev)
          }
        })
      }

      const onDragend = ev => {
        const endTime = new Date().getTime()
        const currentX = ev.clientX
        const currentY = ev.clientY
        if (!currentFile) return

        $store.commit(mutationTypes.SET_DRAGGING_STATE, {})
        document.removeEventListener('mousemove', onDragging)
        document.removeEventListener('mouseup', onDragend)

        if (this.readyToRemoveComponent) {
          this.$store.dispatch('historySave')
          return
        }

        // 这个是防止快速点击
        if (
          endTime - startTime < 100 ||
          Math.abs(currentX - startX) + Math.abs(currentY - startY) < 10
        ) {
          return
        }

        this.replacePlaceholder(component, isPreset)
      }

      document.addEventListener('mousemove', onDragging)
      document.addEventListener('mouseup', onDragend)
    },

    removePlaceholder() {
      this.$store.commit(mutationTypes.REMOVE_PLACEHOLDER)
    },

    showPlaceholder(mouseEvent) {
      let elements = document
        .elementsFromPoint(mouseEvent.clientX, mouseEvent.clientY)
        .filter(
          element =>
            (element.getAttribute('x-slot-component') !== null &&
              element.getAttribute('x-slot-component') !== 'false') ||
            element.getAttribute('x-slot') ||
            element.classList.contains('trash')
        )

      const index = elements
        .findIndex(item => !!item.getAttribute('x-tree'))
      elements = elements.slice(0, index + 1)

      // 判定如果当前指针所在路径不是当前激活的树中，不显示 placeholder
      const pointInTree = elements
        .some(elm => elm.getAttribute('x-tree') === this.currentTreeName)
      if (!pointInTree) return

      if (!elements.length) return
      const $0 = elements[0]

      // 拖拽删除
      const isRemove = $0.classList.contains('trash')
      this.$store.commit(mutationTypes.SET_COMPONENT_REMOVING_STATE, isRemove)
      if (isRemove) {
        this.removePlaceholder()
        return
      }

      let targetElement = $0
      let isTableSlot = $0.classList.contains('table-slot')
      let presetAccess = $0.getAttribute('x-preset-access') !== null
      let tableSlotIndex = -1

      if (isTableSlot) {
        tableSlotIndex =
          Array.from(
            targetElement.parentElement.parentElement.parentElement.children
          ).indexOf(targetElement.parentElement.parentElement) + 1
      }

      let slotName = targetElement.getAttribute('x-slot')
      // 插槽内插件排列方向 column 竖向 / row 横向
      let slotFlow = targetElement.getAttribute('x-slot-flow') || 'column'
      let insertElementList
      let targetUUID = 0
      let position = ['', '']
      let slotParentUUID

      if (slotName) {
        insertElementList = Array.prototype.filter.call(
          targetElement.children,
          item => item.getAttribute('x-slot-component') !== null
        )

        insertElementList.every(item => {
          let itemVM = item.__vue__

          position = calcPosition(item, mouseEvent)

          if (
            (slotFlow === 'row' && position[1] === 'right') ||
            (slotFlow === 'column' && position[0] === 'bottom')
          ) {
            targetUUID = itemVM.uuid
            return true
          }
          targetUUID = itemVM.uuid
          return false
        })

        if (slotName !== 'root') {
          let slotParentVM = elements.filter(
            element =>
              element.getAttribute('x-slot-component') !== null &&
              element.getAttribute('x-slot-component') !== 'false'
          )[0].__vue__

          if (!slotParentVM.uuid) slotParentVM = slotParentVM.$parent
          slotParentUUID = slotParentVM.uuid
        }
      } else {
        elements.every(element => {
          let name = element.getAttribute('x-slot')
          if (name) {
            slotName = name
            return false
          }
          return true
        })

        targetUUID = targetElement.__vue__.uuid

        position = calcPosition(targetElement, mouseEvent)
      }

      this.removePlaceholder()
      this.$store.commit(mutationTypes.SET_PLACEHOLDER, {
        targetUUID,
        position,
        slotName,
        slotParentUUID,
        presetAccess,
        tableSlotIndex: isTableSlot ? tableSlotIndex : 'none'
      })
    },

    replacePlaceholder(componentOrPresetData, isPreset) {
      this.$store.dispatch('replacePlaceholder', {
        componentOrPresetData,
        isPreset
      })
    }
  }
}
