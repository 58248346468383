<template>
  <a class="icon-btn" :class="{ 'is-disabled': disabled, 'is-loading': loading }" @click="onClick">
    <i v-if="loading" class="el-icon-loading"></i>
    <i v-else class="icon" :class="icon">
      <icon v-if="!icon.match(/^el-/)" :name="icon" />
    </i>
    <small-text class="icon-info" :content="loading ? loadingText : content"></small-text>
  </a>
</template>

<script>
export default {
  name: 'ButtonCell',

  props: {
    content: String,
    icon: String,
    disabled: Boolean,
    loading: Boolean,
    loadingText: {
      type: String,
      default: '加载中...'
    }
  },

  methods: {
    onClick() {
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/var.scss';

.icon-btn {
  display: flex;
  min-width: 38px;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  text-decoration: none;

  > .icon {
    font-size: 18px;
    flex-grow: 1;
    margin-right: 0;
  }

  > .icon-info {
    font-size: 12px;
  }

  &:hover {
    color: $primary;
  }

  &.is-loading {
    color: #f76d21;
    cursor: not-allowed;
  }

  &.is-disabled {
    color: #444;
    cursor: not-allowed;
  }
}
</style>
