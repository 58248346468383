export const firstUpperCase = str => str.charAt(0).toUpperCase() + str.slice(1)

export const toUpperCamelCase = fileName => {
  return fileName
    .split('-')
    .map(e => firstUpperCase(e))
    .join('')
}

export const toLowerCamelCase = fileName => {
  return fileName
    .split('-')
    .map((e, i) => i !== 0 && firstUpperCase(e))
    .join('')
}

export const toPascal = fileName => {
  return (fileName || '').replace(/([A-Z])/g, (...$) => `${$[2] !== 0 ? '-' : ''}${$[1].toLowerCase()}`)
}
