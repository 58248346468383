export default {
  props: {
    id: String
  },

  mounted() {
    if (!this.id || this._IS_EDIT_) return
    this.$components[this.id] = this.$refs.elCompo
  }
}
