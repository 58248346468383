import uuidv4 from 'uuid/v4'
import templateRender from 'json-templater/string'
import uuidv1 from 'uuid/v1'
import compatible from './compatible'

const initFunctionTemplate = `
// 页面创建前的初始化钩子函数
// 请勿删除 main 方法或者修改方法名，否则将导致页面无法运行
function main({{{params}}}) {
  // 你的逻辑代码

  /* 数据源的设置和获取 */
  // $store.set('a[0].b.c', 4);
  // console.log($store.a[0].b.c));
  // => 4
}
`

const defaultFunctionTemplate = `
// 主方法，将会默认执行
// 请勿删除 main 方法或者修改方法名，否则将导致页面无法运行
function main({ {{params}} }) {
  // 你的逻辑代码
}
`

const constantsTemplate = `
constants = {
  // name: 'Mark'
}
`

const projectConstantsTemplate = `
projectConstants = {
  // name: 'Mark'
}
`

/**
 * @summary 页面模板
 */
export const PAGE_TEMPLATE = class {
  constructor(options) {
    const initFunction = new FUNC_TEMPLATE({
      name: 'init',
      template: initFunctionTemplate
    })
    const initConfigs = {
      padding: 10,
      background: 'rgba(255, 255, 255, 0)',
      title: ''
    }
    const initOptions = {
      configs: initConfigs,
      type: 'default',
      name: '',
      path: '',
      componentTree: [],
      id: uuidv4(),
      functions: [initFunction],
      dialogs: [],
      constants: {
        content: constantsTemplate
      }
    }

    let pageData = {}
    const optionKeys = Object.keys(initOptions)
    optionKeys.forEach(key => {
      if (key in options) {
        if (key === 'configs') {
          pageData.configs = {}
          const configKeys = Object.keys(initConfigs)
          configKeys.forEach(ckey => {
            if (ckey in options.configs) {
              pageData.configs[ckey] = options.configs[ckey]
            } else {
              pageData.configs[ckey] = initConfigs[ckey]
            }
          })
        } else {
          pageData[key] = options[key]
        }
      } else {
        pageData[key] = initOptions[key]
      }
    })
    // 设置页面的默认标题
    if (!pageData.configs.title) {
      pageData.configs.title = options.name
    }
    pageData = compatible(pageData) // 处理老数据兼容
    Object.assign(this, pageData)
  }
}

/**
 * @summary 方法模板
 */
export const FUNC_TEMPLATE = class {
  constructor(options) {
    options = JSON.parse(JSON.stringify(options))
    const { name, argumentsMapping } = options
    const params = ['$store', '$http', '$methods', '$project', '$components', '$tableRef', '$clickButtonPopover'].concat(options.params || [])
    const OPTIONS = {
      fid: uuidv1(),
      name,
      content: templateRender(options.template || defaultFunctionTemplate, {
        params: params.toString()
      }),
      params,
      argumentsMapping
    }
    Object.assign(this, OPTIONS)
  }
}

/**
 * @summary 项目 setting 模板
 */
export const SETTING_TEMPLATE = class {
  constructor(options = {}) {
    const baseContent = {
      type: 'home',
      theme: 'fusion',
      pageId: 'projectSetting',
      projectName: '',
      sso: true,
      id: uuidv4(),
      watermark: false,
      components: {
        head: {
          logo: '',
          title: ''
        },
        sideNav: []
      }
    }
    for (let key in baseContent) {
      if (key in options) {
        this[key] = options[key]
      } else {
        this[key] = baseContent[key]
      }
    }
    this.components.sideNav.forEach(folder => {
      folder.pages.forEach(page => {
        delete page.url
        page.path = `/${folder.label}/${page.label}`
      })
    })
  }
}

/**
 * @summary 项目 setting 文件夹模板
 */
export const FOLDER_NODE_TEMPLATE = class {
  constructor(options = {}) {
    Object.assign(
      this,
      {
        label: '',
        alias: '',
        hidden: false,
        flat: false,
        path: '',
        pages: []
      },
      options
    )
  }
}

/**
 * @summary 项目 setting 页面模板
 */
export const PAGE_NODE_TEMPLATE = class {
  constructor(options = {}) {
    Object.assign(
      this,
      {
        label: '',
        path: '',
        alias: '',
        hidden: false
      },
      options
    )
  }
}

/**
 * @summary 项目全局变量文件模板
 */
export const PROJECT_CONSTANTS_TEMPLATE = class {
  constructor(options = {}) {
    Object.assign(
      this,
      {
        content: projectConstantsTemplate
      },
      options
    )
  }
}

export const PROJECT_URL_MAP_TEMPLATE = class {
  constructor(options = {}) {
    Object.assign(
      this,
      {},
      options
    )
  }
}
