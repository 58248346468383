<template>
  <div class="no-files">
    <div class="text-main">点击左侧应用导航创建并打开文件进行编辑</div>
  </div>
</template>

<script>
export default {
  name: 'NoFiles'
}
</script>

<style lang="scss" scoped>
.no-files {
  height: 100%;
  // background: #1e1e1f;
  background: #F8F8F8;
  position: relative;

  .text-main {
    font-size: 20px;
    color: #999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
