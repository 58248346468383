<script>
import basicComponentMixin from '../mixins/basic-component.mixin'
import ModelMixin from '../mixins/model-mixin'
import IdMixin from '../mixins/id-mixin'
import DisabledMixin from '../mixins/disabled-mixin'
import RenderPage from '../../../render/components/render-page'

export default {
  name: 'XTab',

  mixins: [basicComponentMixin, ModelMixin, IdMixin, DisabledMixin],

  components: {
    RenderPage
  },

  data() {
    return {
      activeName: 'tab-0'
    }
  },

  methods: {
    handleClick(vm, event) {
      event.stopPropagation()
      this.activeName = vm.paneName
    },
    getStyle() {
      return this.background ? `background: ${this.background}` : ''
    }
  },

  props: {
    background: String,
    position: String,
    tabs: Array
  },

  render() {
    const renderTabChildrenPlaceholder = ({ name, position1, position2 }) => {
      const RenderButtons = this.componentData._children &&
        this.componentData._children[name] &&
        this.componentData._children[name].map(item => {
          return (
            <div class="x-base-tab_tabs_slot">
              {
                this.placeholder &&
                this.placeholder.targetUUID === item.uuid &&
                this.placeholder.position.includes(position1) &&
                <x-placeholder key={item.uuid}></x-placeholder>
              }
              <x-component
                x-slot-component
                key={item.uuid}
                data={item}
                uuid={item.uuid}
                project={this.project}
              >
              </x-component>
              {
                this.placeholder &&
                this.placeholder.targetUUID === item.uuid &&
                this.placeholder.position.includes(position2) &&
                <x-placeholder key={item.uuid}></x-placeholder>
              }
            </div>
          )
        })
      return RenderButtons
    }

    const renderTabSlot = ({ name, flow }) => {
      return (
        <div class="x-base-tab_tabs x-base-tab_slot" x-slot={name}>
          {
            this.placeholder &&
            !this.placeholder.targetUUID &&
            this.placeholder.slotParentUUID === this.componentData.uuid &&
            this.placeholder.slotName === name &&
            <x-placeholder></x-placeholder>
          }
          {  renderTabChildrenPlaceholder({ name, position1: 'top', position2: 'bottom' }) }
        </div>
      )
    }

    const renderTabPane = (tab, index) => {
      let renderTabPaneItem = renderTabSlot({ name: `tabs_${index}` })
      if (parseInt(tab.pageId, 10)) {
        renderTabPaneItem = this._IS_EDIT_ ? <div class="tab-render-page-placeholder">页面 {tab.pageId} 渲染区域</div> : <render-page projectId={tab.pageId} />
      }
      return (
        <el-tab-pane label={tab.label} name={`tab-${index}`}>
          { renderTabPaneItem }
        </el-tab-pane>
      )
    }

    return (
      <div class="x-tab" style={this.getStyle()}>
        <el-tabs
          value={this.activeName}
          tab-position={this.position}
          on-tab-click={this.handleClick}
        >
          { this.tabs && this.tabs.map((tab, index) => renderTabPane(tab, index))}
        </el-tabs>
      </div>
    )
  }
}
</script>

<style lang="scss" scoped>
.tab-render-page-placeholder {
  padding: 200px 0;
  text-align: center;
  background-color: #ffffff;
}
</style>

<style lang="scss">
.editor {
  .x-base-tab {
    &_slot {
      border: 1px dashed #ddd;
      min-height: 400px;
    }

    &_tabs {
      flex: 1;
      border: 1px dashed #ddd;

      .x-placeholder {
        height: 100%;
        min-height: 120px;
        min-width: 60px;
        border: none;
      }
    }
  }
}
</style>
