import exprEval from '@editor/utils/expression-eval'

export default {
  props: {
    label: String,
    labelWidth: Object
  },

  computed: {
    genLabelWidth() {
      const { labelWidth } = this
      if (!labelWidth) return
      const { isStatic } = labelWidth
      return isStatic
        ? labelWidth.labelWidth
        : this._IS_EDIT_
          ? `$${labelWidth.labelWidthKey}`
          : exprEval(labelWidth.labelWidthKey, this.$store.state) + 'px'
    }
  }
}
