<template>
  <el-tooltip content="编辑本页面">
    <div class="project-design" @click="goToProjectDesign">
      <i class="el-icon-s-open" />
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'projectDesign',

  props: {
    project: {
      type: Object,
      required: true
    }
  },

  methods: {
    goToProjectDesign() {
      const designUrl = this.$router.resolve({ path: `/editor/${this.project.projectId}` })
      window.open(designUrl.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.project-design {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 26px;
  z-index: 999;
  border-radius: 50%;
  color: #ffffff;
  opacity: 0.8;
  padding: 10px;
  background-color: #2db93e;
}
</style>
