<script>
import ModelMixin from '../mixins/model-mixin'
import DisabledMixin from '../mixins/disabled-mixin'
import OptionsMixin from '../mixins/options-mixin'

export default {
  name: 'XCascader',

  mixins: [ModelMixin, DisabledMixin, OptionsMixin],

  methods: {
    onActiveChange(value) {
      // TODO(slh) 这里有个 Bug，会触发两遍，还需要排查原因
      this.handleActiveItemChangeKey &&
        this.project.globalAPI[this.handleActiveItemChangeKey](value)
    },
    onBeforeFilter(input) {
      this.handleBeforeFilterKey &&
        this.project.globalAPI[this.handleBeforeFilterKey](input)
    },
    onSelectChange(val) {
      // 改变状态中的值
      this.updateValue(val)
      // 触发绑定的 change 事件
      this.onChange(val)
    }
  },

  computed: {
    defaultModel() {
      return []
    }
  },

  props: {
    size: String,
    label: String,
    placeholder: String,
    popperClass: String,
    clearable: Boolean,
    filterable: Boolean,
    expandTrigger: String,
    showAllLevels: Boolean,
    changeOnSelect: Boolean,
    debounce: Number,
    handleBeforeFilterKey: String,
    handleActiveItemChangeKey: String,
    project: Object
  },

  render() {
    return <div class="x-cascader x-form-item">
      {
        this.label ? <div class="x-form-label">{this.label}</div> : ''
      }
      <el-cascader
        size={this.size}
        onChange={this.onSelectChange}
        on-active-item-change={this.onActiveChange}
        placeholder={this.placeholder}
        filterable={this.filterable}
        options={this.options}
        value={this.bindValue}
        popperClass={this.popperClass}
        disabled={this.isDisabled}
        clearable={this.clearable}
        expandTrigger={this.expandTrigger}
        showAllLevels={this.showAllLevels}
        debounce={this.debounce}
        changeOnSelect={this.changeOnSelect}
        beforeFilter={this.onBeforeFilter}
      ></el-cascader>
    </div>
  }
}
</script>
