<template>
  <el-button @click="exportTable">导出</el-button>
</template>

<script>
import fileSaver from 'file-saver'
import xlsx from 'xlsx'

export default {
  name: 'ExportExcel',

  props: {
    fileName: {
      type: String,
      default: 'sheet'
    },
    tableRef: {
      type: Object,
      required: true
    }
  },

  methods: {
    exportTable() {
      const box = xlsx.utils.table_to_book(this.tableRef.$el)
      const out = xlsx.write(box, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        fileSaver.saveAs(
          new Blob([out], {
            type: 'application/octet-stream'
          }),
          `${this.fileName}.xlsx`
        )
      } catch (e) {
        this.$message.error('导出失败，请刷新浏览器重试~')
      }
      return out
    }
  }
}
</script>
