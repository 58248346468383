import allSchemas from './all'

// 新增组件需要在这里进行排序，否则组件不会显示
const componentListWithOrder = [
  'XRow',
  'XCard',
  'XText',
  'XImage',
  'XCol',
  'XButton',
  'XSwitch',
  'XRadio',
  'XCheckbox',
  'XInput',
  'XTextarea',
  'XNumber',
  'XSelect',
  'XTimePicker',
  'XDatePicker',
  'XDescriptionList',
  'XTable',
  'XPagination',
  'XAlert',
  'XCascader',
  'XTag',
  'XUpload',
  'XTab'
]

export default componentListWithOrder.map(
  c => allSchemas[c]
)
