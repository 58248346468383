import Vue from 'vue'
import services from './services'
import deepFreeze from '@/utils/deepFreeze'

const installGlobalAPI = (options = {}) => {
  const globalAPI = {}
  let project = options.project
  let components = options.components
  let methods = options.methods
  let constants = options.constants
  let projectConstants = options.projectConstants
  let environment = options.environment
  // 所有提供出去的第二个参数都会附加额外的值
  let store = options.store
  /* eslint-disable no-eval,no-script-url */
  try {
    eval(options.constants.content)
    eval(options.projectConstants.content)
  } catch (error) {
    console.error(error)
    services.$message.error('CONSTANTS: 请提供合法的格式数据')
  }

  constants = deepFreeze(constants)
  projectConstants = deepFreeze(projectConstants)

  methods.forEach(func => {
    let functionStr = func.content
    let params = func.params
    let argumentsMapping = func.argumentsMapping || []
    let injectedParams = {}
    let storeProxy = {...store}
    let globalServices = services
    eval(`
      globalAPI[func.name] = function() {
        const callbackArguments = arguments
        params.forEach(function(paramName) {
          if (globalServices[paramName]) {
            if (paramName === '$methods') {
              injectedParams['$methods'] = globalAPI
              return
            }
            if (paramName === '$components') {
              injectedParams['$components'] = components
              return
            }
            if (paramName === '$project') {
              injectedParams['$project'] = project
              return
            }
            if (paramName === '$constants') {
              injectedParams['$constants'] = constants
              return
            }
            if (paramName === '$projectConstants') {
              injectedParams['$projectConstants'] = projectConstants
              return
            }
            if (paramName === '$env') {
              injectedParams['$env'] = options.environment
              return
            }
            if (paramName === '$tableRef') {
              console.log(callbackArguments)
              injectedParams['$tableRef'] = typeof callbackArguments[1] === 'object' && callbackArguments[1].$tableRef
              return
            }
            if (paramName === '$clickButtonPopover') {
              injectedParams['$clickButtonPopover'] = typeof callbackArguments[1] === 'object' && callbackArguments[1].$clickButtonPopover
              return
            }
            if (paramName === '$store') {
              injectedParams['$store'] = storeProxy
              return
            }
            injectedParams[paramName] = globalServices[paramName]
          } else {
            for(var i = 0; i < argumentsMapping.length; i++) {
              if (argumentsMapping[i].name === paramName) {
                injectedParams[paramName] = callbackArguments[i]
              }
            }
          }
        })
        storeProxy.dispatch = function(name, params) {
          return store.dispatch(name, Object.assign({
            $methods: globalAPI,
            $components: components,
            $project: project,
            $constants: constants,
            $projectConstants: projectConstants,
            $env: options.environment,
            $tableRef: typeof callbackArguments[1] === 'object' && callbackArguments[1].$tableRef,
            $clickButtonPopover: typeof callbackArguments[1] === 'object' && callbackArguments[1].$clickButtonPopover,
            $store: storeProxy
          }, injectedParams, params))
        }
        ${functionStr}
        return main.call(storeProxy, injectedParams)
      }
    `)
  })
  /* eslint-enable */
  project.globalAPI = globalAPI
}

export default installGlobalAPI
