<template>
<div class="widget-theme">
    <div @click="changeTheme(theme)" class="theme" v-for="theme in themes" :key="theme.name">
      <div class="theme__preview" :class="[theme.name, {'active': theme.name === currentTheme.name}]">
        <div class="nav"></div>
        <div class="header"></div>
      </div>
      <div class="theme__name">{{theme.name}}</div>
    </div>
</div>
</template>

<script>
export default {
  name: 'WidgetTheme',

  props: {
    value: String,
    themes: Array
  },

  watch: {
    value(val) {
      this.currentTheme = this.themes.find(e => e.name === val)
    }
  },

  created() {
    this.currentTheme = this.themes.find(e => e.name === this.value)
    this.inited = true
  },

  data() {
    return {
      inited: false,
      currentTheme: {}
    }
  },

  computed: {},

  methods: {
    changeTheme(theme) {
      this.currentTheme = theme
      this.$emit('input', theme.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-theme {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .theme {
    display: flex;
    flex-flow: column;
    flex: 0 0 45%;
    cursor: pointer;

    &__preview {
      display: flex;
      width: 100px;
      height: 60px;
      margin-top: 10px;
      background: #f0f0f0;
      border: 2px solid #333;

      &.active {
        border-color: #f76d21;

        + .theme__name {
          color: #f76d21;
        }
      }

      .header,
      .nav {
        content: '';
        display: block;
      }

      &.fivex2-open {
        flex-direction: row;
        align-items: flex-start;

        .header,
        .nav {
          background: #fff;
        }

        .nav {
          width: 20%;
          height: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          padding-top: 5%;
          border-right: 1px solid #ddd;

          &::before {
            content: '';
            width: 10px;
            height: 2px;
            background: #999;
          }
        }

        .header {
          height: 15%;
          flex-grow: 1;
          border-bottom: 1px solid #ddd;
        }
      }

      &.fusion {
        flex-direction: column;
        align-items: flex-start;

        .header,
        .nav {
          background: #fff;
        }

        .nav {
          width: 20%;
          height: 100%;
          order: 2;
          border-right: 1px solid #ddd;
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          order: 1;
          height: 20%;
          padding: 0 5%;
          border-bottom: 1px solid #ddd;

          &::before {
            content: '';
            width: 10px;
            height: 2px;
            background: #2ba0f8;
          }

          &::after {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #ce3339;
          }
        }
      }

      &.chalk {
        flex-direction: row;
        align-items: flex-start;
        background: #f4f8fa;

        .nav {
          position: relative;
          display: flex;
          justify-content: center;
          width: 17%;
          height: 100%;
          background: #313131;
          border-right: 1px solid #ddd;

          &::before {
            content: '';
            position: absolute;
            display: inline-block;
            width: 5px;
            height: 5px;
            margin-top: 10%;
            background: #ffeb29;
            border-radius: 50%;
          }
        }

        .header {
          width: 8px;
          height: 1px;
          margin-top: 3px;
          margin-left: 5px;
          background: #888;
        }
      }
    }

    &__name {
      text-align: center;
      padding-top: 5px;
      font-size: 12px;
      color: #aaa;
    }
  }
}
</style>

