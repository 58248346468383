import { UI_SCHEMAS } from '../../common-schemas'

const { text, label } = UI_SCHEMAS

export default {
  componentName: 'XText',

  label: 'Text',

  beTableSlot: true,

  props: {
    text: {
      text: '基础文本',
      textKey: '',
      isStatic: true
    },
    href: {
      href: '',
      hrefKey: '',
      isStatic: true
    },
    isHref: false,
    fontSize: 12,
    bold: false,
    color: 'rgba(51, 51, 51, 1)'
  },

  configs: {
    ui: {
      label: '视觉',
      content: {
        text,
        label,

        isHref: {
          name: '是否链接',
          widget: 'switch',
          type: 'boolean'
        },

        href: {
          name: '链接',
          type: 'object',
          dynamicOption: {
            dynamic: { keyName: 'hrefKey' },
            static: { keyName: 'href', placeholder: '输入链接地址' },
            isStatic: true
          },
          widget: 'dynamicOrStatic'
        },

        fontSize: {
          name: '字号',
          widget: 'number',
          type: 'number'
        },

        color: {
          name: '字体颜色',
          type: 'string',
          size: 'large',
          widget: 'color'
        },

        bold: {
          name: '是否加粗',
          widget: 'switch',
          type: 'boolean'
        }
      }
    }
  }
}
