<template>
  <div class="widget-bind">
    <el-tooltip
      popper-class="small"
      :content="isStatic ? '切换成绑定变量' : '切换成静态写死'"
      placement="left"
    >
      <el-switch
        @input="changeSetting"
        v-model="isStatic"
        class="widget-bind_switch"
        size="mini"
      />
    </el-tooltip>

    <slot v-if="inited">
      <!-- 动态 -->
      <el-input
        v-if="!isStatic"
        v-model="dynamicValue"
        @input="changeSetting"
        :placeholder="dynamicPlaceholder"
      />
      <!-- 静态 -->
      <el-input
        v-if="isStatic && !dynamicOption.static.widget"
        @input="changeSetting"
        v-model="staticValue"
        :placeholder="staticPlaceholder"
      />
      <el-switch
        v-if="isStatic && dynamicOption.static.widget === 'switch'"
        @input="changeSetting"
        v-model="staticValue"
      />
      <el-input-number
        v-if="isStatic && dynamicOption.static.widget === 'number'"
        @input="changeSetting"
        v-model="staticValue"
      />
    </slot>
  </div>
</template>

<script>
import WidgetMap from '@editor/components/commonWidgets/map'

export default {
  name: 'WidgetBind',

  props: {
    dynamicOption: Object,
    value: Object
  },

  watch: {
    value(val) {
      this.init()
    }
  },

  created() {
    this.init()
  },

  data() {
    return {
      inited: false,

      WidgetMap,

      defaultValuesMap: {
        switch: false,
        input: ''
      },

      isStatic: false,
      staticValue: '',
      dynamicValue: ''
    }
  },

  computed: {
    dynamicPlaceholder() {
      return this.dynamicOption.dynamic.placeholder || '输入关联变量名'
    },

    staticPlaceholder() {
      return this.dynamicOption.static.placeholder || '输入配置'
    }
  },

  methods: {
    init() {
      const { dynamicOption, defaultValuesMap } = this
      const {
        static: {
          keyName: staticKeyname,
          widget: staticWidget,
          default: staticDefaultValue
        },
        dynamic: { keyName: dynamicKeyname, widget: dynamicWidget }
      } = dynamicOption

      const defaultValue = this.value || {
        isStatic: dynamicOption.isStatic,
        [staticKeyname]:
          staticDefaultValue || defaultValuesMap[staticWidget || 'input'],
        [dynamicKeyname]: defaultValuesMap[dynamicWidget || 'input']
      }

      this.isStatic = defaultValue.isStatic

      this.staticValue = defaultValue[staticKeyname]
      this.dynamicValue = defaultValue[dynamicKeyname]
      this.inited = true
    },

    changeSetting() {
      this.$emit('input', {
        [this.dynamicOption.dynamic.keyName]: this.dynamicValue,
        [this.dynamicOption.static.keyName]: this.staticValue,
        isStatic: this.isStatic
      })
    }
  }
}
</script>

<style lang="scss">
.widget-bind {
  position: relative;

  &_switch {
    position: absolute;
    right: 0;
    top: -20px;
    transform: scale(0.5);

    .el-switch__button {
      background-color: #f76d21;
    }

    .el-switch__core {
      background-color: #33332f;
      border-color: #f76d21;
    }

    &.is-checked {
      .el-switch__core {
        background-color: #f76d21;
        border-color: #252526;
      }

      .el-switch__button {
        background-color: #333;
      }
    }
  }
}

.el-tooltip__popper {
  &.small {
    border-radius: 2px;
    padding: 8px 9px;
    transform: scale(0.8);
  }
}
</style>
