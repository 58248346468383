<template>
  <div class="x-table" x-slot-component="true">
    <el-table
      ref="elCompo"
      v-loading="getValueByPath(loadingKey)"
      :data="tableData"
      :height="height === 0 ? '' : height.toString()"
      :stripe="stripe"
      :border="border"
      :class="{'is-th-center': thAlignCenter}"
       @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="selectable"
        type="selection"
        width="55"/>
      <el-table-column
        v-for="(e, i) in columns"
        :key="i"
        :label="e.label"
        :width="e.width || ''"
      >
        <template slot-scope="scope">
          <slot v-if="!e.isSlot">
            {{get(scope.row, e.prop, '缺省') || '无数据'}}
          </slot>
          <div v-else x-slot="default" x-slot-flow="row" class="table-slot">
            <x-placeholder
              v-if="
                placeholder &&
                !placeholder.targetUUID &&
                placeholder.slotParentUUID === componentData.uuid &&
                placeholder.tableSlotIndex === i + 1 + offset &&
                placeholder.slotName === 'default'
              "
            />

              <template v-for="item in componentData._children.filter(e => e.tableSlotIndex === i + 1 + offset)">

                <x-placeholder
                  :key="item.uuid"
                  v-if="
                    placeholder &&
                    placeholder.targetUUID === item.uuid &&
                    placeholder.tableSlotIndex === i + 1 + offset &&
                    placeholder.position.includes('left')
                  "
                />
                <!-- x-slot-component 指已插入插槽内的组件，值不为 false，代表是具插槽组件-->
                <x-component
                  x-slot-component="false"
                  :key="item.uuid"
                  :data="item"
                  in-table-slot
                  :row-data="{data: scope.row, index: scope.$index}"
                  :uuid="item.uuid"
                  :project="project"
                >
                </x-component>
                <x-placeholder
                  :key="item.uuid"
                  v-if="
                    placeholder &&
                    placeholder.targetUUID === item.uuid &&
                    placeholder.tableSlotIndex === i + 1 + offset &&
                    placeholder.position.includes('right')
                  "
                />
              </template>

          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import basicComponentMixin from '../mixins/basic-component.mixin'
import ModelMixin from '../mixins/model-mixin'
import IdMixin from '../mixins/id-mixin'
import { get, cloneDeep } from 'lodash-es'

export default {
  name: 'XTable',

  mixins: [ModelMixin, IdMixin, basicComponentMixin],

  props: {
    height: {
      type: Number,
      default: 0
    },
    selectable: Boolean,
    stripe: Boolean,
    thAlignCenter: Boolean,
    border: Boolean,
    columns: Array,

    dataKey: String,
    selectionKey: String,
    loadingKey: String,
    handleSelectKey: String,
    project: Object
  },

  data() {
    return {
      multipleSelection: []
    }
  },

  watch: {
    columns() {
      this._IS_EDIT_ &&
        this.$refs.elCompo.$children
          .find(e => e.$el.classList.contains('el-table__body'))
          .$forceUpdate()
    }
  },

  computed: {
    tableData() {
      return this._IS_EDIT_ ? this.fakeData : this.getValueByPath(this.dataKey)
    },

    fakeData() {
      let fakeData = {}
      this.columns.forEach(c => {
        fakeData[c.prop || 'placeholder'] = '$' + c.prop
      })

      return Array(2).fill(fakeData)
    },

    offset() {
      return this.selectable ? 1 : 0
    }
  },

  methods: {
    get(o, path, dv) {
      return get(o, path, dv)
    },

    handleSelectionChange(selection) {
      const { selectionKey, handleSelectKey, $store } = this
      this.multipleSelection = selection
      selectionKey &&
        $store.commit('SET_STATE', {
          project: this.project,
          path: selectionKey,
          value: cloneDeep(selection)
        })

      handleSelectKey && this.project.globalAPI[handleSelectKey](selection)
    }
  }
}
</script>

<style lang="scss">
.x-table {
  padding-bottom: 20px;
}

.table-slot {
  display: flex;
  flex-flow: row;
  align-items: center;
  min-height: 40px;

  > .x-component {
    z-index: 3;
    width: auto;

    &.x-row {
      margin: -5px;
      flex-grow: 1;
    }

    + .x-component {
      margin-top: 5px;
      margin-left: 10px;
    }
  }
}

.is-th-center {
  .el-table__header {
    th {
      text-align: center;
    }
  }
}

.editor {
  .table-slot {
    margin: -12px 0;
    border: 1px dashed #ddd;

    .x-placeholder {
      min-width: 60px;
      height: 100%;
      min-height: 40px;
      max-width: 40px;
      border: none;
    }
  }
}
</style>
